import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: 'AIzaSyCa1cqEsbWULi3AFskZ5wW_b-e8IdryYWI',
  authDomain: 'typetawk.firebaseapp.com',
  projectId: 'typetawk',
  storageBucket: 'typetawk.appspot.com',
  messagingSenderId: '206602395418',
  appId: '1:206602395418:web:430ab2085fb951068156a3',
  measurementId: 'G-1ZFWYXWJNW'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'BMH1O51fy5M_sRcDZTAq-4j6IjmhsDRy-cxyrtNldYFb-d0Nicuf_F6vguAsrEj8lXSX3_iF064ApGqdDNKWaNY',
    });
    if (currentToken) {
      localStorage.setItem('devicetoken', currentToken)
      return currentToken;
    } else {
    }
  } catch (err) {
  }
};


