import io from "socket.io-client";

let socketInstance = null;

const getSocketInstance = (baseUrl) => {
  if (!socketInstance) {
    socketInstance = io(baseUrl);
    socketInstance.on("connect", () => {
    });
    socketInstance.on("disconnect", () => {
    });
  }
  return socketInstance;
};

export { getSocketInstance };