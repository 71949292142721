import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import MainLayout from "../components/Layout/MainLayout";

export default function NotificationPage() {

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      // Request microphone access
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      // Call the callback function if permission is granted
      if (permission === 'granted') {
        // callback(stream);
      } else {
        // Handle if notification permission is not granted
        console.error('Notification permission denied');
      }
    } catch (error) {
      console.error('Error requesting notification permission:', error);
    }
  };

  return (
    <>
      <MainLayout>
        <Container fluid className="px-0">
          {/* typetawk chat box start */}
          <div className="form-box-group-main">
            <Container>
              <Form className="group-form">
                <Row>
                  <Col
                    xxl="3"
                    xl="4"
                    lg="4"
                    className="d-flex justify-content-end"
                  >
                    <Form.Label>Desktop notifications</Form.Label>
                  </Col>
                  <Col xxl="9" xl="8" lg="8">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        My Topics, mentions, and direct messages
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        Mentions and direct messages
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault3"
                      >
                        None
                      </label>
                    </div>
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Show message preview
                      </label>
                    </div>
                  </Col>

                  <Col
                    xxl="3"
                    xl="4"
                    lg="4"
                    className="d-flex justify-content-end mt-4"
                  >
                    <Form.Label>Sound</Form.Label>
                  </Col>
                  <Col xxl="9" xl="8" lg="8" className="mt-4">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault1"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault1"
                      >
                        Play sound for all notifications
                      </label>
                    </div>
                    <Form.Select
                      aria-label="Default select example"
                      className="mb-4"
                    >
                      <option>Xylophone</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </Col>
                  <Row className="mt-4 px-4 top-border">
                    <Col
                      xxl="3"
                      xl="4"
                      lg="4"
                      className="d-flex justify-content-end mt-4"
                    >
                      <Form.Label>Do Not Disturb</Form.Label>
                    </Col>
                    <Col xxl="9" xl="8" lg="8" className="mt-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault2"
                        />
                        <label
                          className="form-check-label mb-3"
                          for="flexCheckDefault2"
                        >
                          Pause notifications from
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4 px-4 top-border">
                    <Col xxl="9" xl="8" lg="8" className="mt-4">
                      <Form.Label>If you're using safari :</Form.Label>
                      <span> </span>
                      <Link onClick={requestNotificationPermission} to="" className="allow-notify-btn">Refresh Notification permissions</Link>
                    </Col>
                  </Row>
                </Row>
              </Form>
              <Row className="align-items-center d-flex">
                <Col lg="12" md={12}>
                  {/* <Link to="" className="allow-notify-btn">Allow Notifications</Link> */}
                </Col>
              </Row>
            </Container>
          </div>
          {/* typetawk chat box end */}
          {/* typetawk message bar start*/}
          <div className="group-bottom-btns">
            <div className="group-btns-inner">
              <button className="create-group">Update</button>
            </div>
          </div>
          {/* typetawk message bar end*/}
        </Container>
      </MainLayout>
    </>
  );
}
