import { configureStore } from "@reduxjs/toolkit";
import userMgmtReducer from "./reducers/userMgmtSlice";
import authReducer from "./reducers/authReducer";

export const store = configureStore({
  reducer: {
    userMgmtSlice: userMgmtReducer,
    auth: authReducer
  },
  
});
