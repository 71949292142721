import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import logo from "../../Assets/images/log-blue-main.webp";
import { Link, useNavigate, useParams } from "react-router-dom";
import user from "../../Assets/images/usericonmen.svg";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/actions/authAction";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import SEO from "../../components/SEO";

export default function ResetPassword() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length <= 6) {
      errors.password = "Password should be 6+ characters";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords doesn't match";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate,
    validateOnChange: validateValue,
    onSubmit: async (values) => {
      setValidateValue(true);
      const dataVal = {
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: token,
      };


      dispatch(resetPassword(dataVal)).then((data) => {
        if (data?.payload?.success) {
          toast.success(data?.payload?.message);
          navigate("/login");
        } else {
          toast.error(data?.payload?.message);
        }
      });
    },
  });

  return (
    <>
      <div className="main">
        <SEO
          title="Reset Password - Typetawk"
          description='Reset your Typetawk password smoothly and regain access to fluid communication and collaboration.'
          og_title='Typetawk - Reset Password'
          og_des='Chatting APP'
        />
        <Container fluid>
          <Row>
            {/*resetpassword left section start */}
            <Col xxl="3" xl="3" lg="4" className="pt-4">
              <div className="logo-main">
                <img src={logo} alt="Logo" />
                <p className="purp-subhdng ">
                  Be engaged with your project 24/7
                </p>
              </div>
              <img className="user-img" src={user} alt="user" />
            </Col>
            {/*reserpassword left section end */}

            {/*reserpassword right section start */}
            <Col xxl="9" xl="9" lg="8">
              <div className="blue-background justify-content-around align-items-center d-flex">
                <div className="blue_inner">
                  <Row>
                    <Col lg="12">
                      <h2 className="hdng text-center">Reset Password</h2>
                      <p className="sub-hdng text-center">
                        Reset Password with Typetawk
                      </p>

                      {/* <p className="pt-3 sub-hdng text-center">
                        Enter your Email and Instructions
                        <br /> will be sent to you!
                      </p> */}
                      {/* reserpassword form start */}
                      <Form
                        onSubmit={formik.handleSubmit}
                        className="py-4 login-form"
                      >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New Password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.password && (
                            <p className="error_valid">
                              {formik.errors.password}
                            </p>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                          />
                          {formik.errors.confirmPassword && (
                            <p className="error_valid">
                              {formik.errors.confirmPassword}
                            </p>
                          )}
                        </Form.Group>
                        <button className="submit mt-4" type="submit">
                          Reset
                        </button>
                        <Link to="/login" className="sub-14 forgot">
                          Remember it? Log in
                        </Link>
                      </Form>
                      {/* resetpassword form end */}
                    </Col>
                    <Col lg="12">
                      <div className="copyright">
                        <p>© 2024 typetawk</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*resetpassword right section end */}
          </Row>
        </Container>
      </div>
    </>
  );
}
