import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import logo from "../Assets/images/log-blue-main.webp";
import { useNavigate } from "react-router-dom";
import user from "../Assets/images/usericonmen.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updatemoreProfiledetailsAction } from "../redux/actions/authAction";

export default function Updateprofile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
      name: '',
      password: '',

    },
    validationSchema: Yup.object({
      username: Yup.string().min(4, '* Username should be at least of 4 characters')
        .required('Username is required')
        .matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain blank spaces'),
      name: Yup.string().required('Name is required').matches(/^[a-zA-Z\s]+$/, 'Name must contain only letters'),
      password: Yup.string().required('Password is required'),

    }),
    onSubmit: (values) => {
      dispatch(updatemoreProfiledetailsAction(values)).then(function (data) {
        if (data.payload != 400) navigate('/messages')
      })
    }
    // Handle step 1 submission
  });

  return (
    <div className="main">
      <Container fluid>
        <Row>
          {/*resetpassword left section start */}
          <Col xxl="3" xl="3" lg="4" className="pt-4">
            <div className="logo-main">
              <img src={logo} alt="Logo" />
              <p className="purp-subhdng ">
                Be engaged with your project 24/7
              </p>
            </div>
            <img className="user-img" src={user} alt="user" />
          </Col>
          {/*reserpassword left section end */}

          {/*reserpassword right section start */}
          <Col xxl="9" xl="9" lg="8">
            <div className="blue-background justify-content-around align-items-center d-flex">
              <div className="blue_inner">
                <Row>
                  <Col lg="12">
                    <h2 className="hdng text-center">Create Profile</h2>
                    {/* reserpassword form start */}
                    <Form className="py-4 login-form" onSubmit={formik.handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Full Name </Form.Label>
                        <Form.Control
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="name"
                          value={formik.values.name}
                        />
                        {formik.submitCount > 0 &&
                          formik.errors.name && (
                            <div className="errors">
                              {formik.errors.name}
                            </div>
                          )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username </Form.Label>
                        <Form.Control
                          type="text"

                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="username"
                          value={formik.values.username}
                        />
                        {formik.submitCount > 0 &&
                          formik.errors.username && (
                            <div className="errors">
                              {formik.errors.username}
                            </div>
                          )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Password </Form.Label>
                        <Form.Control
                          type="password"

                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          name="password"
                          value={formik.values.password}
                        />
                        {formik.submitCount > 0 &&
                          formik.errors.password && (
                            <div className="errors">
                              {formik.errors.password}
                            </div>
                          )}
                      </Form.Group>
                      <button className="submit" type="submit">
                        create profile
                      </button>
                    </Form>
                    {/* resetpassword form end */}
                  </Col>
                  <Col lg="12">
                    <div className="copyright">
                      <p>© 2024 typetawk</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          {/*resetpassword right section end */}
        </Row>
      </Container>
    </div>
  )
}
