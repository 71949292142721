import React from "react";
import ReactDOM from "react-dom/client";
import "./Assets/font/Inter/stylesheet.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/css/style.css";
import "./Assets/css/responsive.css";
import { ToastContainer } from "react-hot-toast";
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from "react-hot-toast";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import * as serviceWorkerRegistration from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById("root"));
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js')
//     .then(registration => {
//     })
//     .catch(error => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

root.render(
  <React.StrictMode>
    {/* <ToastContainer limit={1} /> */}
    <Toaster position="top-right" reverseOrder={false} />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
