import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function NewgroupHeader() {
  return (
    <header>
    <Container fluid>
      <Row>
        <Col lg="12" className="px-0">
          <div className="chat-header-left">
            <h3 className="sub-16">New Tawk</h3>
            <p className="h-12">Tawks Description</p>
          </div>
        </Col>
      </Row>
    </Container>
  </header>
  )
}
