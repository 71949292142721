import React from 'react';

const DragAndDrop = () => {
    return (
        <div className='hidden-drag'>
            <p><span>
                <svg width="32" height="32" viewBox="0 0 345 282" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M150.179 44.7793C193.079 44.7793 235.989 44.7793 278.889 44.7793C292.429 44.7793 300.219 52.5593 300.219 66.1493C300.229 130.629 300.229 195.109 300.219 259.589C300.219 273.379 292.429 281.169 278.659 281.169C192.979 281.179 107.289 281.179 21.6095 281.169C7.67945 281.169 0.00945312 273.579 0.00945312 259.729C-0.000546875 195.259 -0.000546875 130.779 0.00945312 66.2993C0.00945312 52.3193 7.54945 44.7893 21.4695 44.7793C64.3695 44.7793 107.269 44.7793 150.179 44.7793ZM25.5795 242.479C108.879 242.479 191.619 242.479 274.639 242.479C274.639 235.049 274.539 227.949 274.689 220.859C274.739 218.289 273.959 216.449 272.129 214.629C252.489 195.109 232.929 175.499 213.349 155.929C212.569 155.149 211.739 154.429 210.639 153.389C198.959 165.109 187.389 176.719 175.559 188.589C155.229 168.239 135.149 148.159 115.119 128.119C114.739 128.309 114.469 128.369 114.299 128.539C85.1795 157.629 56.0595 186.729 26.9795 215.859C26.3295 216.509 25.6695 217.509 25.6495 218.359C25.5395 226.329 25.5795 234.299 25.5795 242.479Z"
                        fill="#9e97c9ad" />
                    <path
                        d="M319.16 235.959C319.16 165.849 319.16 95.9295 319.16 25.5995C229.75 25.5995 140.64 25.5995 51.6098 25.5995C48.8098 10.6195 57.4598 0.00945312 72.1798 0.00945312C155.99 -0.000546875 239.8 -0.000546875 323.61 0.00945312C337.17 0.00945312 344.82 7.73945 344.82 21.4595C344.83 85.9395 344.65 150.419 344.97 214.889C345.03 228.249 335.42 238.749 319.16 235.959Z"
                        fill="#9e97c9ad" />
                </svg>
            </span> Drop files or Images to send</p>
            {/* {files.length > 0 && (
                    <div className="dropped-files ">
                        <p>Files Dropped:</p>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div>
                 )} */}
        </div>

    );
};

export default DragAndDrop;
