import toast from "react-hot-toast";
import PlaceholderImg from "../Assets/images/default_user_img.jpg"
import ChatPlaceholderImg from "../Assets/images/placeholder.png"


function getFileExtension(url) {
    if (!url) return
    const parts = url.split(".");
    return parts[parts.length - 1].toLowerCase();
}
function getFileNameFromUrl(url) {
    if (!url) return
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const pathSegments = pathname.split("/");
    const fileName = pathSegments[pathSegments.length - 1];
    return fileName
    // return fileName.replace(/[^a-zA-Z ]/g, " ");
}
const handleCopyAction = (messageToCopy) => {
    navigator.clipboard
        .writeText(messageToCopy)
        .then(() => {
            toast.success("Message copied to clipboard");
        })
        .catch((error) => {
            toast.error(`Copy failed: ${error.message}`);
        });
};
const handleDownAll = async (images) => {
    images.forEach(async (imageObj) => {
        let imageUrl = imageObj.imagename
        const image = await fetch(imageUrl);
        const nameSplit = imageUrl.split("/");
        const duplicateName = nameSplit.pop();

        const imageBlob = await image.blob();
        const imageURL_link = URL.createObjectURL(imageBlob);
        const link = document.createElement('a');
        link.href = imageURL_link;
        link.download = "" + duplicateName + "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

const downloadFile = async (fileUrl) => {
    try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
        const fileBlob = await response.blob();
        const fileURL = URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = getFileNameFromUrl(fileUrl);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(fileURL);
    } catch (error) {
        console.error('Download error:', error);
    }
};

const onImageError = (e) => {
    e.target.src = PlaceholderImg
}
const chatImageError = (e) => {
    e.target.src = ChatPlaceholderImg
}
const handleScrollToReplyMess = (elemRef) => {
    const element = document.getElementById(elemRef);
    if (!element) return
    element.style.transition = 'background-color 0.5s ease';
    element.style.backgroundColor = '#b8aff7'; // Change to desired highlight color
    // Add border radius and text color styles
    element.style.borderRadius = '8px'; // Adjust border radius as needed
    element.style.color = '#ffffff'; // Change text color to white or another suitable color
    // Reset styles after a delay
    setTimeout(() => {
        element.style.backgroundColor = ''; // Reset background color
        element.style.borderRadius = ''; // Reset border radius
        element.style.color = ''; // Reset text color
    }, 1200);
    // Step 2: Scroll to the element in the center
    element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center"
    });
}

function highlightUserMentions(text) {
    let regex = /@(\w+)/g;
    let highlightedText = text.replace(regex, '<span className="highlight">@$1</span>');
    return highlightedText;
}


const CommonFiles = {
    getFileNameFromUrl,
    getFileExtension,
    handleCopyAction,
    downloadFile,
    onImageError,
    chatImageError,
    handleDownAll,
    handleScrollToReplyMess
}
export default CommonFiles