import React, { useEffect, useRef } from "react";
import reply from "../Assets/images/reply.svg";
import copy from "../Assets/images/copy.svg";
import downloadImg from "../Assets/images/download-voilet.svg";
import deleteimg from "../Assets/images/deletec.svg";
import edit from "../Assets/images/edit.svg";
import { useState } from "react";
import searchicon from "../Assets/images/searchicon.svg";
import smalllogo from "../Assets/images/small-mian.svg";
import { timezone } from "../Helpers/timezone";
import whitelogo from "../Assets/images/logo-main.webp";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetgropmessagelistAction, NewgroupadddAction, getGrouplistAction, searchUserlist, uploadFileAction } from "../redux/actions/authAction";
import { getSocketInstance } from "../Helpers/WebSocket";
import TextareaAutosize from "react-textarea-autosize";
import { NavLink, useLocation } from "react-router-dom";
import ChatSidebarComponent from "../components/ChatSidebarComponent/ChatSidebarComponent";
import { checkfiletype } from "../Helpers/filetypecheck";
import toast from "react-hot-toast";
import EmojiPicker from "emoji-picker-react";
import { selectUserDetail } from "../redux/reducers/authReducer";
import moment from "moment";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { Encryptedid } from "../Util/BcruptEncyptid";
import Homesidebar from "../components/Homesidebar";
import { Suspense } from "react";
import DragAndDrop from "../components/DragAndDrop/DragAndDrop";
import CommonFiles from "../Util/CommonFiles";
import ChatImageComponent from "../components/ImgComponent/ChatImageComponent";
import SEO from "../components/SEO";

const newSocket = getSocketInstance(process.env.REACT_APP_SOCKET_BASE_URL);

export default function Chat() {
  const location = useLocation();
  const dispatch = useDispatch();

  const userDetail = useSelector(selectUserDetail);

  const messagesContainer = React.useRef(null);
  const messInput = React.useRef(null);
  const topRef = useRef(null)
  const bottomRef = React.useRef(null);
  const mentionListRef = useRef(null);
  const previousHeight = React.useRef(0);
  const isLoadingMore = React.useRef(false);
  const skipScroll = useRef(false);
  const [message, setMessage] = useState("");
  const [selectedgroup, setSelectedGroup] = useState("");
  const [show, setShow] = useState(false);
  const [arrFiles, setArrFiles] = useState([]);
  const [useEmoji, setUseEmoji] = useState(false);
  const [messagereplytext, setMessagereplytext] = useState(null);
  const [messageReplyImg, setMessageReplyImg] = useState([]);
  const [messagereplyid, setmessagereplyid] = useState(null);
  const [messagedatetime, setmessagedatetime] = useState(null);
  const [messageid, setmessageid] = useState(null);
  const [GroupMessagelist, setMessageList] = useState([]);
  const [GroupcreatorID, setGroupcreatorID] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [GroupName, setGroupName] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [members, setMembers] = useState([])
  const [handleMention, setHandleMention] = useState(false);
  const [sortedMembers, setSortedMembers] = useState([])
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0)
  const [limit] = useState(50)
  const [totalCount, setTotalCount] = useState(0)


  const sendMessage = () => {
    const mentionedUsers = message?.match(/@(\w+(?:\s+\w+)*)/g);
    const mentionedUsersNameArray = mentionedUsers?.map(item => {
      const nameParts = item?.substring(1)?.split(' ');
      return nameParts?.map(part => part?.toLowerCase())?.join(' ');
    });
    let mentionedUsersData = members?.filter(entry => mentionedUsersNameArray?.some(name => name?.toLowerCase()?.includes(entry?.name?.toLowerCase())))?.map(entry => entry);
    const mentioned_users_ids = mentionedUsersData?.map((res) => res?._id)
    let notify_all = mentionedUsers ? mentionedUsers?.findIndex((res) => (res?.toLowerCase()?.includes('@team') || res?.toLowerCase()?.includes('@all'))) : -1
    if (newSocket && newSocket.connected) {
      if (!selectedgroup) {
        return;
      }
      if (arrFiles?.length > 0) {
        handleUpload();
      }
      else {
        if (message.trim().length > 0) {
          if (messageid != null && messageid != "") {
            let chatData = {
              message,
              id: messageid,
              groupID: selectedgroup,
              userID: userDetail?._id
            }
            if (mentioned_users_ids?.length && notify_all === -1) {
              chatData['type'] = 2
              chatData['tagged_users'] = mentioned_users_ids
            }
            if (notify_all !== -1) {
              chatData['type'] = 1
            }
            newSocket.emit("groupchat", chatData);
            setmessageid("");
            setMessage("");
          } else {
            let replyChatData = {
              message,
              messageid,
              message_id: messagereplyid,
              groupID: selectedgroup,
              userID: userDetail?._id
            }
            if (mentioned_users_ids?.length && notify_all === -1) {
              replyChatData['type'] = 2
              replyChatData['tagged_users'] = mentioned_users_ids
            }
            if (notify_all !== -1) {
              replyChatData['type'] = 1
            }
            newSocket.emit("groupchat", replyChatData);
            setMessagereplytext(null)
            setmessagereplyid(null)
            setMessageReplyImg([])
            setmessageid("");
            setMessage("");
          }
        }
        else {
          toast.error("Can't send empty message")
          setMessage("")
        }
      }
      setUseEmoji(false)
      dispatch(getGrouplistAction());
      setFiles([]);
      setArrFiles([]);
    } else {
    }
  };

  const handleKeyDown = (e) => {
    if (handleMention && sortedMembers.length > 0) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedMemberIndex((prevIndex) => (prevIndex <= 0 ? sortedMembers.length - 1 : prevIndex - 1));
        scrollListIfNeeded(selectedMemberIndex - 1);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedMemberIndex((prevIndex) => (prevIndex === sortedMembers.length - 1 ? 0 : prevIndex + 1));
        scrollListIfNeeded(selectedMemberIndex + 1);
      }
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (handleMention) {
        let element = sortedMembers.filter((res, index) => index === selectedMemberIndex)
        if (element?.length) {
          handleUserSelection(element[0]?.name)
          setHandleMention(false)
          return
        }
      }
      sendMessage();
    }
  };

  const handleReplyEvents = (data) => {
    setMessagereplytext(data?.message)
    setMessageReplyImg(data?.image)
    setmessagereplyid(data?._id)
    messInput?.current?.focus()
  }

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    if (arrFiles?.length >= 5 && items[0].kind === 'file') return toast.error("Please paste less than 6")
    let selectedFiles = [];
    if (items.length > 5) {
      toast.error("Please paste less than 6");
    }
    else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const file = items[i].getAsFile();
          selectedFiles.push(file);
        }
      }
    }
    let data = arrFiles.concat(selectedFiles)
    setArrFiles(data)
  };

  const handleFileChange = async (e) => {
    if (arrFiles?.length >= 5) return toast.error("Please select less than 6")
    setUseEmoji(false)
    var file = e.target.files;
    if (file.length > 5) {
      toast.error("Please select less than 6");
    } else {
      var arr_files = []
      for (var i = 0; i < file.length; i++) {
        arr_files.push(file[i])
      }
      let data = arrFiles.concat(arr_files)
      setArrFiles(data)
    }
  };

  const handleEmojies = async (e) => {
    setUseEmoji(!useEmoji);
  };

  const onEmojiClick = (e, emojiObject) => {
    let sym = e.unified.split("-");
    let codesArray = sym.map(el => "0x" + el);
    let emoji = String.fromCodePoint(...codesArray);
    setMessage(prevMessage => prevMessage + emoji);
    messInput?.current.focus()
  };

  const handleCopyAction = (messageToCopy) => {
    navigator.clipboard.writeText(messageToCopy)
      .then(() => {
        toast.success('Message copied to clipboard');
      })
      .catch((error) => {
        toast.error(`Copy failed: ${error.message}`);
      });
  };

  const deletemessage = (chatid, createdAtTimestamp) => {
    const createdAt = new Date(createdAtTimestamp);
    const currentTimestamp = new Date();
    const differenceInMs = currentTimestamp - createdAt;
    if (differenceInMs <= 60 * 5 * 1000) {
      newSocket.emit("deletegroupmessage", {
        chatid: chatid,
        groupID: selectedgroup,
        user_id: userDetail?._id,
      });
    } else {
      toast.error("Message can only be deleted within five minute of creation.");
    }
  };

  const likemessage = (chatid) => {
    newSocket.emit("GroupLikeDislike", {
      message_id: chatid,
      groupID: selectedgroup,
      user_id: userDetail?._id,
    });
  };

  function listenChatSockets() {
    if (newSocket.connected) {
      newSocket.on("groupmessageevent" + selectedgroup,
        (data) => {
          if (data !== "") {
            setIsUploading(false);
            setMessageList((prevMessageList) => {
              const updatedList = Array.isArray(prevMessageList) ? prevMessageList : [];
              if (updatedList?.length > 0 && updatedList[0]?.groupID === data?.groupID) {
                return [...updatedList, data];
              } else if (selectedgroup?.toString() === data?.groupID?.toString() || selectedgroup === "") {
                return [data, ...updatedList];
              } else if (data?.groupID === selectedgroup) {
                return [data, ...updatedList];
              } else {
                return updatedList;
              }
            });
          }
        }
      );
      newSocket.on(selectedgroup, (data) => {
        console.log('workes selectedgroup socket');
        dispatch(getGrouplistAction());
      });
    }
  }

  function listeneditChatSockets() {
    if (newSocket.connected) {
      newSocket.on("editmessagegroupmessage" + selectedgroup, (data) => {
        if (data != "") {
          setMessageList((prevMessageList) => {
            const updatedList = Array.isArray(prevMessageList) ? [...prevMessageList] : [];
            const messageIndex = updatedList.findIndex(message => message._id.toString() === data._id.toString());
            if (messageIndex !== -1) {
              updatedList[messageIndex] = data;
            }
            return updatedList;
          });
        }
      }
      );
    }
  }

  function listendeleteChatSockets() {
    if (newSocket.connected) {
      newSocket.on("deletemessage" + selectedgroup, (data) => {
        setMessageList((prevMessageList) =>
          prevMessageList.filter((item) => item._id !== data._id)
        );
        // Handle received messages here
      }
      );
    }
  }

  function listenlikeChatSockets() {
    if (newSocket.connected) {
      newSocket.on(
        "likeGroupmessage" + selectedgroup,
        (data) => {
          if (data != "") {
            skipScroll.current = true;
            setMessageList((prevMessageList) => {
              const updatedList = Array.isArray(prevMessageList) ? [...prevMessageList] : [];
              const messageIndex = updatedList.findIndex(message => message._id.toString() === data._id.toString());
              if (messageIndex !== -1) {
                updatedList[messageIndex] = data?.data;
              }
              return updatedList;
            });
          }
        }
      );
    }
  }

  function NewgroupChatSockets() {
    if (newSocket.connected) {
      newSocket.on("newgroup" + userDetail?._id, (data) => {
        dispatch(NewgroupadddAction(data));
      }
      );
    }
  }

  const scrollToBottom = () => {
    bottomRef?.current?.scrollIntoView()
    // if (messagesContainer?.current) messagesContainer.current.scrollTop = messagesContainer?.current?.scrollHeight;
  };

  const uploadFile = async (filename) => {
    try {
      let formData = new FormData();
      formData.append('file', filename);
      const res = await dispatch(uploadFileAction(formData));
      let url = res.payload.data.url;
      return url;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  const handleUpload = async () => {
    var images = [];
    setMessage("")
    var arr_localFiles = arrFiles
    setArrFiles([])
    setFiles([])
    if (arr_localFiles.length > 0) {
      for (var i = 0; i < arr_localFiles.length; i++) {
        var filename = arr_localFiles[i];
        var size = arr_localFiles[i].size;
        setIsUploading(true);
        scrollToBottom();
        var filetype = checkfiletype(filename);
        var image = await uploadFile(filename)
        images.push({ imagename: image, filetype: filetype, size: size });
      }
      if (images.length <= 5) {
        if (newSocket && newSocket.connected) {
          if (image && filetype) {
            newSocket.emit("groupchat", {
              messagetype: 1,
              message: (message.trim().length > 0) ? message : null,
              image: images,
              groupID: selectedgroup,
              userID: userDetail?._id
            });
          }
        } else {
        }
      } else {
        toast.error("Maximum 5 files can be selected");
      }
    }
  }

  const handleDragEvents = (e, type) => {
    e.preventDefault();
    setDragging(type);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = [...e.dataTransfer.files, ...files];
    if (droppedFiles?.length > 5) {
      return toast.error("Maximum 5 files can be selected");
    }
    setFiles(droppedFiles);
    setArrFiles(droppedFiles);
  };
  // DRAG AND DROP FUNCTIONALITY END

  const removeFile = (indexToRemove) => {
    const updatedFiles = arrFiles.filter((file, index) => index !== indexToRemove);
    setFiles(updatedFiles)
    setArrFiles(updatedFiles);
  };

  const handleMess = (val, event) => {
    const caretPosition = event.target.selectionStart;
    const beforeCaret = val.substring(0, caretPosition);
    const afterCaret = val.substring(caretPosition);

    // Check if the last character is "@"
    const lastElement = beforeCaret.split(/\s+/).pop();
    const isAtSymbol = lastElement === '@' || lastElement === '\n@';

    // Define mention regex
    const mentionRegex = /@(\w+(?:\s+\w+)*)/g;

    // Default to showing all users if "@" is present
    if (isAtSymbol) {
      setHandleMention(true);
      setSortedMembers(members);
      setSelectedMemberIndex(0);
    } else {
      let beforeMatch, afterMatch;
      while ((beforeMatch = mentionRegex.exec(beforeCaret)) !== null) {
        if (mentionRegex.lastIndex >= caretPosition) break;
      }
      while ((afterMatch = mentionRegex.exec(afterCaret)) !== null) {
        if (mentionRegex.lastIndex >= afterCaret.length - caretPosition) break;
      }

      // Filter members if there's an active mention
      if (beforeMatch || afterMatch) {
        let mentionQuery = (beforeMatch ? beforeMatch[0] : afterMatch[0]).substring(1);
        let filteredMembers = members.filter(member =>
          member?.name?.toLowerCase().startsWith(mentionQuery?.toLowerCase())
        );
        setSortedMembers(filteredMembers);
        setHandleMention(true);
        setSelectedMemberIndex(0);
      } else {
        setHandleMention(false);
        setSortedMembers([]);
        setSelectedMemberIndex(-1);
      }

      // Ensure popup only appears when "@" is present in the current word
      const currentWord = beforeCaret.split(/\s+/).pop();
      if (!currentWord.includes('@')) {
        setHandleMention(false);
        setSortedMembers([]);
        setSelectedMemberIndex(-1);
      }
    }

    setMessage(val);
  };

  // const handleUserSelection = (userName) => {
  //   const inputElement = messInput.current;
  //   const caretPosition = inputElement.selectionStart;
  //   const textAreaValue = inputElement.value;

  //   // Find the last "@" position
  //   const lastAtIndex = textAreaValue.lastIndexOf('@', caretPosition - 1);
  //   const lastSpaceIndex = textAreaValue.lastIndexOf(' ', lastAtIndex);
  //   const mentionStartIndex = lastSpaceIndex + 1;

  //   // Replace the mention with the selected user's name, including the "@"
  //   const updatedText = `${textAreaValue.substring(0, mentionStartIndex)}@${userName} ${textAreaValue.substring(caretPosition)}`;

  //   inputElement.value = updatedText;
  //   inputElement.selectionStart = inputElement.selectionEnd = mentionStartIndex + userName.length + 2;

  //   // Update state if necessary
  //   setMessage(updatedText);
  //   setHandleMention(false);
  //   setSelectedMemberIndex(-1);
  //   inputElement.focus();
  // };

  const handleUserSelection = (userName) => {
    const inputElement = messInput.current;

    try {
      const caretPosition = inputElement.selectionStart;
      if (caretPosition === null || caretPosition === undefined) {
        console.error('Caret position could not be determined.');
        return;
      }

      const textAreaValue = inputElement.value;

      // Find the last "@" position before the caret
      const lastAtIndex = textAreaValue.lastIndexOf('@', caretPosition - 1);
      if (lastAtIndex === -1) {
        console.warn('No "@" found before the caret position.');
        return;
      }

      // Calculate the start index for the mention replacement (right after "@")
      const mentionStartIndex = lastAtIndex;

      // Replace the mention with the selected user's name, including the "@"
      const updatedText = `${textAreaValue.substring(0, mentionStartIndex)}@${userName} ${textAreaValue.substring(caretPosition)}`;

      // Set the updated value in the text area
      inputElement.value = updatedText;

      // Set the caret position after the inserted username
      inputElement.selectionStart = inputElement.selectionEnd = mentionStartIndex + userName.length + 2;

      // Update state if necessary
      setMessage(updatedText);
      setHandleMention(false);
      setSelectedMemberIndex(-1);

      // Refocus the input element
      inputElement.focus();

      console.log('User mention handled successfully.');
    } catch (error) {
      console.error('Error handling user mention:', error);
    }
  };

  const scrollListIfNeeded = (index) => {
    if (mentionListRef.current) {
      const list = mentionListRef.current;
      const listItem = list.querySelector(`li:nth-child(${index + 1})`);

      if (listItem) {
        const listRect = list.getBoundingClientRect();
        const itemRect = listItem.getBoundingClientRect();

        // Scroll list to ensure selected item is in view
        if (itemRect.bottom > listRect.bottom) {
          list.scrollTop += itemRect.bottom - listRect.bottom;
        } else if (itemRect.top < listRect.top) {
          list.scrollTop -= listRect.top - itemRect.top;
        }
      }
    }
  };

  const handleInfiniteScroll = (e) => {
    if (e.target.scrollTop === 0 && totalCount > 20 && totalCount > GroupMessagelist.length) {
      previousHeight.current = e.target.scrollHeight;
      isLoadingMore.current = true;
      const pagination = page + 1;
      setPage(pagination);
      getMessages(selectedgroup, pagination, true);
    }
  };

  const getMessages = (groupID, pagination, adscroll = false) => {
    try {
      if (selectedgroup != "") {
        dispatch(GetgropmessagelistAction({ id: selectedgroup, pagination, limit })).then(function (arraylist) {
          let newMessages = arraylist?.payload?.groupmessagelist ? [...arraylist.payload.groupmessagelist].reverse() : [];
          if (adscroll) {
            let x = [...GroupMessagelist, ...newMessages]
            setMessageList((prevMessages) => [...newMessages, ...prevMessages,]);
          } else {
            setMessageList(newMessages);
          }
          setTotalCount(arraylist?.payload?.count)
          let serchInput = document.getElementById('serachGroup')
          if (serchInput && document.activeElement === serchInput) {
            return
          } else {
            messInput?.current?.focus();
          }
        });
      }
    } catch (error) {
      console.log(error);
      // setChatLoad(false)
    }
    finally {
      // setLoad(false)
      dispatch(getGrouplistAction())
    }
  }

  useEffect(() => {
    setPage(0)
    getMessages(selectedgroup, 0)
  }, [selectedgroup]);

  useEffect(() => {
    if (newSocket.connected) {
      listenChatSockets();
      listendeleteChatSockets();
      listenlikeChatSockets();
      listeneditChatSockets();
      NewgroupChatSockets()
      newSocket.on("Newmessagegroup", (data) => {
        console.log('workes Newmessagegroup socket');
        dispatch(getGrouplistAction());
      });
    }
    return () => {
      newSocket.removeAllListeners();
    };
  }, [selectedgroup]);

  useEffect(() => {
    if (messagesContainer.current) {
      if (isLoadingMore.current) {
        messagesContainer.current.scrollTop =
          messagesContainer.current.scrollHeight - previousHeight.current;
        isLoadingMore.current = false;
      } else {
        scrollToBottom();
      }
    }
  }, [GroupMessagelist.length, selectedgroup]);

  useEffect(() => {
    dispatch(searchUserlist()).then((res) => {
      let data = res.payload.data
      setMembers(data)
      setSortedMembers(data)
    });
  }, [])

  return (
    <>
      <SEO
        title="Tawks - Typetawk"
        description="Enhance teamwork with Typetawk's group chat features, which are designed for project-based collaboration."
      />
      <Container fluid className="px-0">
        <div className="collab-main">
          <div className={show ? "left-side hide" : "left-side "}>
            <aside className="typetawk-cmn-sidebar">
              <div className="sidebar-left-header-cmn">
                <img className="big-logo" src={whitelogo} alt="Logo" />
                <img className="small-logo" src={smalllogo} alt="Logo" />
              </div>

              <div className="side-flex">
                {/* typetawk mini-sidebar start */}
                <Suspense fallback={<div>Loading... </div>}>
                  <Homesidebar userDetail={userDetail} location={location} />
                </Suspense>
                {/* typetawk mini-sidebar end */}

                {/* typetawk full-sidebar start */}
                <Suspense fallback={<div>Loading... </div>}>
                  <ChatSidebarComponent
                    setSelectedGroup={setSelectedGroup}
                    selectedgroup={selectedgroup}
                    setGroupcreatorID={setGroupcreatorID}
                    setGroupName={setGroupName}
                    setFiles={setFiles}
                    setArrFiles={setArrFiles}
                  />
                </Suspense>
                {/* typetawk full-sidebar end */}
                <div className="">
                  <button className="menu-close" onClick={() => setShow(!show)}>
                    <img
                      src={require("../Assets/images/menuclose.svg").default}
                    />
                  </button>
                </div>
              </div>
            </aside>
          </div>

          <div className={show ? "right-side hide" : "right-side "}>
            {/* typetawk right header start */}
            <header>
              <Container fluid>
                <Row>
                  <Col lg="5" md="3" sm="3" xs="6" className="px-0">
                    <div className="chat-header-left mt-2">
                      <h3 className="sub-16">{GroupName}</h3>
                    </div>
                  </Col>
                  <Col
                    lg="7"
                    md="9"
                    sm="9"
                    xs="6"
                    className="px-0 d-flex justify-content-end"
                  >
                    <div className="chat-header-right">
                      <Form className="search-form position-relative big">
                        <Form.Group>
                          <Form.Control type="search" placeholder="Search" />
                          {/* <img src={searchicon} alt="" className="search-ico" /> */}
                          <img src={searchicon} alt="" className="search-ico" />
                        </Form.Group>
                      </Form>
                      {
                        (GroupcreatorID?.toString() == userDetail?._id?.toString() || userDetail?.role == "2") && <div className="header-dots-select" >
                          <NavLink to={"/edit-tawk/" + Encryptedid(selectedgroup)}>
                            <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="1.71403" cy="1.70528" rx="1.71403" ry="1.70528" fill="white"></ellipse><ellipse cx="1.71403" cy="7.99989" rx="1.71403" ry="1.70528" fill="white"></ellipse><ellipse cx="1.71403" cy="14.2944" rx="1.71403" ry="1.70528" fill="white"></ellipse></svg>
                          </NavLink>
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Container>
            </header>
            {/* typetawk right header end */}

            {/* typetawk chat box start */}
            {!selectedgroup ? null :
              <div className={`draganddrop-rel chat-box-main ${dragging ? 'active' : ''}`}
                onDragOver={(e) => handleDragEvents(e, true)}
                onDragEnter={(e) => handleDragEvents(e, true)}
                onDragLeave={(e) => handleDragEvents(e, false)}
                onDrop={handleDrop}
                onScroll={handleInfiniteScroll}
                ref={messagesContainer}>
                <DragAndDrop />
                <>
                  {(GroupMessagelist.length < totalCount) ? <div ref={topRef} className="text-primary">Loading...</div> : null}
                  {GroupMessagelist?.map(function (messageobject, i) {
                    const isUser = messageobject?.userID?.toString() === userDetail?._id?.toString()
                    const userType = isUser ? 'right' : 'left'
                    return (
                      <>
                        <div className={`${userType}-user mt-4 mb-2`} key={messageobject._id} id={messageobject._id}>
                          <div className={`${userType}-user-info`}>
                            <div className={`${userType}-user-img`}>
                              {userType === 'left' && <>
                                {
                                  messageobject?.user_image != undefined ?
                                    <ChatImageComponent imageUrl={messageobject?.user_image} type="user" imgArray={messageobject?.image} />
                                    : <div className="wout-avt"><InitialsAvatar name={messageobject?.user_name || "Typetawk User"} /></div>
                                }
                                <h2 className="h-15">
                                  {messageobject?.user_name}
                                </h2>
                              </>}
                              <h4
                                className="h-12"
                              >
                                {timezone(messageobject?.createdAt)}
                                <span className="tym-tool-tip">
                                  {moment(messageobject?.createdAt).format(
                                    "DD MMM YYYY, hh:mm a"
                                  )}
                                </span>
                              </h4>
                              <div className="like-msg">
                                <button className="w-42"
                                  onClick={() => { likemessage(messageobject?._id) }}
                                >
                                  {messageobject?.like == null ? (
                                    <svg
                                      width="16"
                                      height="14"
                                      viewBox="0 0 16 14"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 4.36809C0.0529216 4.06134 0.0820285 3.74938 0.162734 3.45042C0.583461 1.87375 1.52282 0.720812 3.13031 0.222983C4.84629 -0.308641 6.34265 0.120298 7.58102 1.40321C7.72655 1.55399 7.85621 1.72037 8.00174 1.88935C8.14199 1.72687 8.27164 1.56309 8.41586 1.41101C9.09854 0.687017 9.91486 0.185288 10.923 0.0592064C12.4802 -0.135766 13.8112 0.330868 14.8366 1.5085C15.8606 2.68613 16.1596 4.07693 15.9241 5.58732C15.7746 6.54398 15.3287 7.37846 14.7466 8.14535C13.9819 9.15271 13.0518 10.0028 12.0926 10.8243C10.9614 11.7913 9.82357 12.7519 8.68708 13.7125C8.25048 14.0816 7.7808 14.0998 7.36007 13.7411C5.8743 12.4763 4.3872 11.2168 2.92127 9.9326C2.17772 9.28139 1.51091 8.55479 0.964496 7.72941C0.44851 6.94952 0.10452 6.11114 0.0224917 5.17658C0.0198456 5.14148 0.00793824 5.10769 0 5.07259C0 4.83862 0 4.60336 0 4.36809ZM7.99513 13.0691C8.03614 13.0379 8.06922 13.0145 8.09965 12.9885C9.36448 11.9148 10.6359 10.849 11.8902 9.76362C12.766 9.00453 13.5982 8.20124 14.2478 7.23678C14.8657 6.31781 15.1713 5.31826 15.0284 4.21211C14.8749 3.01888 14.355 2.02713 13.2754 1.40321C12.2699 0.822198 11.2088 0.750708 10.1398 1.25504C9.50869 1.55269 9.04165 2.03882 8.65136 2.60034C8.59579 2.68093 8.54551 2.76412 8.4873 2.84341C8.22931 3.18916 7.77154 3.18786 7.50957 2.84601C7.28201 2.54835 7.07297 2.2312 6.81365 1.96213C5.97749 1.08996 4.9442 0.753307 3.75082 1.00677C2.4635 1.27973 1.61808 2.08042 1.19074 3.28665C0.693273 4.68785 0.930097 6.00456 1.7517 7.23418C2.26107 7.99587 2.88555 8.66398 3.57088 9.26969C4.70473 10.2718 5.86107 11.2493 7.01079 12.2359C7.33493 12.5153 7.66437 12.7883 7.99513 13.0691Z"
                                        fill="#5948C8"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      width="17"
                                      height="15"
                                      viewBox="0 0 17 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.08049 13.6273C13.6616 9.65944 16.3114 7.50406 16.3956 4.47928C16.4618 2.08365 14.7003 0.928295 14.6616 0.89581C11.8542 -1.10437 9.14066 1.04258 8.50302 1.75703C7.93197 1.04393 5.42623 -1.09172 2.49159 0.787778C2.06422 1.05969 0.652447 2.10866 0.418651 4.09999C0.0209175 7.55059 3.47482 10.3772 6.95151 13.6235C7.24435 13.8994 7.62317 14.0306 8.00729 14.0327C8.3834 14.0348 8.76514 13.8998 9.08049 13.6273Z"
                                        fill="#5948C8"
                                      />
                                    </svg>
                                  )}
                                  <p className="h-10">
                                    {messageobject?.likelist?.length}
                                  </p>
                                </button>
                                <Dropdown className="cmn-dropdown">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    <img
                                      src={
                                        require("../Assets/images/voiletdots.svg")
                                          .default
                                      }
                                    />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => {
                                      handleReplyEvents(messageobject)
                                    }}
                                    >
                                      <li>
                                        {" "}
                                        <img
                                          src={reply}
                                          alt="reply"
                                          className="img-fluid"
                                        />
                                        <p className="h-12">Reply</p>
                                      </li>
                                    </Dropdown.Item>
                                    {messageobject.messagetype == "0" && userType === 'left' && (<Dropdown.Item onClick={() => handleCopyAction(messageobject?.message)} href="#/action-3">
                                      <li>
                                        {" "}
                                        <img
                                          src={copy}
                                          alt="copy"
                                          className="img-fluid"
                                        />
                                        <p className="h-12">Copy</p>
                                      </li>
                                    </Dropdown.Item>)}
                                    {userType === 'right' && <>
                                      {messageobject.messagetype == "0" && (<Dropdown.Item >
                                        <li onClick={() => {
                                          setMessage(messageobject?.message)
                                          setmessageid(messageobject?._id)
                                          setmessagedatetime(messageobject?.createdAt)
                                        }}>
                                          {" "}
                                          <img
                                            src={edit}
                                            alt="edit"
                                            className="img-fluid"
                                          />
                                          <p className="h-12" >Edit</p>
                                        </li>
                                      </Dropdown.Item>)}
                                      {messageobject.messagetype == "0" && (<Dropdown.Item onClick={() => handleCopyAction(messageobject?.message)} href="#/action-3">
                                        <li>
                                          {" "}
                                          <img
                                            src={copy}
                                            alt="copy"
                                            className="img-fluid"
                                          />
                                          <p className="h-12">Copy</p>
                                        </li>
                                      </Dropdown.Item>)}
                                      <Dropdown.Item onClick={() => {
                                        deletemessage(
                                          messageobject?._id,
                                          messageobject?.createdAt
                                        );
                                      }}>
                                        <li>
                                          {" "}
                                          <img
                                            src={deleteimg}
                                            alt="copy"
                                            className="img-fluid"
                                          />
                                          <p className="h-12">
                                            Delete
                                          </p>
                                        </li>
                                      </Dropdown.Item>
                                      {(messageobject?.image.length > 1) && <Dropdown.Item onClick={() => CommonFiles.handleDownAll(messageobject?.image)}>
                                        <li>
                                          {" "}
                                          <img
                                            src={downloadImg}
                                            alt="copy"
                                            className="img-fluid"
                                          />
                                          <p className="h-12">
                                            Download All
                                          </p>
                                        </li>
                                      </Dropdown.Item>}
                                    </>}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {/* Right Message boxes configuration start */}
                            {
                              //type - text && reply is there 
                              (messageobject?.messagetype == "0" && messageobject?.message_id != undefined) ? (
                                //reply box
                                <div className={`reply-msg-box ${userType === 'left' && 'left-reply-box'}`}>
                                  <button type="button">
                                    <img src={require("../Assets/images/reply.svg").default} />
                                    Replied
                                  </button>
                                  {/* previous message */}
                                  <div className={`${userType}-user-msg-box reply-msg previous-msg-rply`} onClick={() => CommonFiles.handleScrollToReplyMess(messageobject?.message_id?._id)}>
                                    {(messageobject?.message_id?.image.length && userType === 'right') ?
                                      <>
                                        {messageobject?.message_id?.image.map((res, index) =>
                                          (res.filetype.toString() === '7' || res.filetype.toString() === '5' || res.filetype.toString() === '2') ?
                                            <>
                                              <div className="d-flex ms-2 align-items-center" key={index}>
                                                <img
                                                  className="img-fluid pdf-set"
                                                  src={require("../Assets/images/other-file.svg").default
                                                  }
                                                  alt="File"
                                                />
                                                <div className="pdf-info ">
                                                  <h3 className="h-13">
                                                    {CommonFiles.getFileNameFromUrl(res?.imagename)}
                                                  </h3>
                                                  {/* <p className="h-12 pt-1">{res?.size == undefined ? "N/A" : (res?.size / 1024 / 1024).toFixed(2)}- MB</p> */}
                                                </div>
                                              </div>
                                            </>
                                            : <ChatImageComponent index={index} imageUrl={res.imagename} preview />
                                        )}
                                      </>
                                      :
                                      <>
                                        <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                          {messageobject?.message_id?.message?.split(/(\s+|\n)/).map((word, index) => {
                                            const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                            return isUrl ? (
                                              <a
                                                className="white-url"
                                                key={index}
                                                href={word}
                                                // target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {word}
                                              </a>
                                            ) : word === '\n' ? (
                                              <br key={index} />
                                            ) : (
                                              <span key={index}>{word} </span>
                                            );
                                          })}
                                        </p>
                                      </>
                                    }
                                  </div>

                                  <div className={`${userType}-reply-line`}>
                                    {/* need Changes of images src */}
                                    {userType === "left"
                                      ?
                                      <img src={require("../Assets/images/line_reply_left.svg").default} />
                                      :
                                      <img src={require("../Assets/images/line_reply.svg").default} />
                                    }
                                  </div>

                                  {/* current message */}

                                  <div className={`${userType}-user-msg-box crruent-msg-text position-relative`}>
                                    <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                      {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                        const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                        return isUrl ? (
                                          <a
                                            className="white-url"
                                            key={index}
                                            href={word}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {word}
                                          </a>
                                        ) : word === '\n' ? (
                                          <br key={index} />
                                        ) : (
                                          <span key={index}>{word} </span>
                                        );
                                      })}
                                    </p>
                                    {(moment(messageobject.createdAt).valueOf() !== moment(messageobject.updatedAt).valueOf()) ? <div className={`edit-icon-reply-${userType}`}>
                                      <i class="bi bi-pencil-fill"></i>
                                    </div> : null}
                                  </div>
                                </div>
                              ) :
                                //type - text only
                                messageobject.messagetype == "0" ? (
                                  <>
                                    <div className="d-flex gap-3">
                                      <div className={`${userType}-user-msg-box`}>
                                        {((userType === 'left' && messageobject?.message_id == undefined) || (userType === 'right')) &&
                                          <>
                                            <div className="edit-icon-main position-relative">
                                              <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                                {messageobject?.message.split(/(\s+|\n)/).map((word, index) => {
                                                  const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                                  return isUrl ? (
                                                    <a
                                                      className="white-url"
                                                      key={index}
                                                      href={word}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {word}
                                                    </a>
                                                  ) : word === '\n' ? (
                                                    <br key={index} />
                                                  ) : (
                                                    <span key={index}>{word} </span>
                                                  );
                                                })}
                                              </p>
                                              {(moment(messageobject.createdAt).valueOf() !== moment(messageobject.updatedAt).valueOf()) ? <div className={`edit-icon-${userType}`}>
                                                <i class="bi bi-pencil-fill"></i>
                                              </div> : null}
                                            </div>
                                          </>
                                        }
                                      </div>
                                    </div>
                                  </>
                                ) :
                                  <>
                                    <div className={`${userType === 'right' ? 'with-image-box' : ''}`}>
                                      <div className={`left-user-msg-box-images`}>
                                        {messageobject?.image?.map(function (imageobject, i) {
                                          return (
                                            <button key={i}>
                                              {imageobject.filetype === 1 && CommonFiles.getFileExtension(imageobject?.imagename) == "svg" ? (
                                                <div className="left-user-msg-box-pdf">
                                                  <div className="pdf-div">
                                                    <div className="d-flex">
                                                      <img
                                                        className="img-fluid pdf-set"
                                                        src={require("../Assets/images/other-file.svg").default
                                                        }
                                                        alt="File"
                                                      />
                                                      <div className="pdf-info">
                                                        <h3 className="h-13">
                                                          {CommonFiles.getFileNameFromUrl(imageobject?.imagename)}
                                                        </h3>
                                                        <p className="h-12 pt-1">{imageobject?.size == undefined ? "N/A" : (imageobject?.size / 1024 / 1024).toFixed(2)}- MB</p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                    >
                                                      <img
                                                        src={require("../Assets/images/download-voilet.svg").default}
                                                        alt="Download"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : imageobject.filetype === 1 ? (
                                                <ChatImageComponent index={i} imageUrl={imageobject?.imagename}
                                                  imgArray={messageobject?.image}
                                                />
                                              ) : imageobject.messagetype === "3" ? (
                                                <audio controls>
                                                  <source
                                                    src={imageobject?.imagename}
                                                    type="audio/mp3"
                                                  />
                                                  Your browser does not support the audio element.
                                                </audio>
                                              ) : (
                                                <div className="left-user-msg-box-pdf">
                                                  <div className="pdf-div">
                                                    <div className="d-flex">
                                                      <img
                                                        className="img-fluid pdf-set"
                                                        src={
                                                          imageobject.filetype === "5"
                                                            ? require("../Assets/images/pdf-red.svg").default
                                                            : imageobject.filetype === "6"
                                                              ? require("../Assets/images/zip.svg").default
                                                              : require("../Assets/images/other-file.svg").default
                                                        }
                                                        alt="File"
                                                      />
                                                      <div className="pdf-info">
                                                        <h3 className="h-13">
                                                          {CommonFiles.getFileNameFromUrl(imageobject?.imagename)}
                                                        </h3>
                                                        <p className="h-12 pt-1">{imageobject?.size == undefined ? "N/A" : (imageobject?.size / 1024 / 1024).toFixed(2)}- MB</p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                    >
                                                      <img
                                                        src={require("../Assets/images/download-voilet.svg").default}
                                                        alt="Download"
                                                      />
                                                    </div>
                                                    {/* </a> */}
                                                  </div>
                                                </div>
                                              )}
                                            </button>
                                          );
                                        })}
                                      </div>
                                      {/* if message is present along with image */}
                                      {(messageobject?.message?.length > 0 && userType === 'right') &&
                                        <div className="right-user-msg-box">
                                          {
                                            <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                              {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                                const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                                return isUrl ? (
                                                  <a
                                                    className="white-url"
                                                    key={index}
                                                    href={word}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {word}
                                                  </a>
                                                ) : word === '\n' ? (
                                                  <br key={index} />
                                                ) : (
                                                  <span key={index}>{word} </span>
                                                );
                                              })}
                                            </p>
                                          }
                                        </div>}
                                    </div>

                                    {(messageobject?.message?.length > 0 && userType === 'left') &&
                                      <div className="left-user-msg-box">
                                        {
                                          <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                            {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                              const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                              return isUrl ? (
                                                <a
                                                  className="white-url"
                                                  key={index}
                                                  href={word}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {word}
                                                </a>
                                              ) : word === '\n' ? (
                                                <br key={index} />
                                              ) : (
                                                <span key={index}>{word} </span>
                                              );
                                            })}
                                          </p>

                                        }

                                      </div>}
                                  </>
                            }
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div ref={bottomRef}></div>
                </>
                {isUploading && (
                  <div className="right-user mt-4 mb-2">
                    <div className="right-user-info">
                      <div className="left-user-msg-box">
                        {/* Replace the following div with an <img> tag for your loading GIF */}
                        <div className="loader">
                          <img
                            className="send-img send-img-size"
                            src={require("../Assets/images/loader.gif")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading && <div>Loading...</div>}
              </div>
            }
            {/* typetawk chat box end */}

            {/* typetawk message bar start*/}
            {!selectedgroup ? (
              <div className="chat-box-main" ref={messagesContainer}>
                {GroupMessagelist?.map(function (messageobject, i) {
                  if (messageobject?.userID?.toString() === userDetail?._id?.toString()) {
                    return (
                      <div className="right-user mt-4 mb-2">
                        <div className="right-user-info">
                          <div className="right-user-img">
                            <h4
                              className="h-12"
                            >
                              {timezone(messageobject?.createdAt)}
                              <span className="tym-tool-tip">
                                {moment(messageobject?.createdAt).format(
                                  "DD MMM YYYY, hh:mm a"
                                )}
                              </span>
                            </h4>
                            <div className="like-msg">
                              <button className="w-42"
                                onClick={() => {
                                  // likemessage(
                                  //   messageobject?._id,
                                  //   messageobject?.likelist
                                  // );
                                }}
                              >
                                {messageobject?.like == null ? (
                                  <svg
                                    width="16"
                                    height="14"
                                    viewBox="0 0 16 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 4.36809C0.0529216 4.06134 0.0820285 3.74938 0.162734 3.45042C0.583461 1.87375 1.52282 0.720812 3.13031 0.222983C4.84629 -0.308641 6.34265 0.120298 7.58102 1.40321C7.72655 1.55399 7.85621 1.72037 8.00174 1.88935C8.14199 1.72687 8.27164 1.56309 8.41586 1.41101C9.09854 0.687017 9.91486 0.185288 10.923 0.0592064C12.4802 -0.135766 13.8112 0.330868 14.8366 1.5085C15.8606 2.68613 16.1596 4.07693 15.9241 5.58732C15.7746 6.54398 15.3287 7.37846 14.7466 8.14535C13.9819 9.15271 13.0518 10.0028 12.0926 10.8243C10.9614 11.7913 9.82357 12.7519 8.68708 13.7125C8.25048 14.0816 7.7808 14.0998 7.36007 13.7411C5.8743 12.4763 4.3872 11.2168 2.92127 9.9326C2.17772 9.28139 1.51091 8.55479 0.964496 7.72941C0.44851 6.94952 0.10452 6.11114 0.0224917 5.17658C0.0198456 5.14148 0.00793824 5.10769 0 5.07259C0 4.83862 0 4.60336 0 4.36809ZM7.99513 13.0691C8.03614 13.0379 8.06922 13.0145 8.09965 12.9885C9.36448 11.9148 10.6359 10.849 11.8902 9.76362C12.766 9.00453 13.5982 8.20124 14.2478 7.23678C14.8657 6.31781 15.1713 5.31826 15.0284 4.21211C14.8749 3.01888 14.355 2.02713 13.2754 1.40321C12.2699 0.822198 11.2088 0.750708 10.1398 1.25504C9.50869 1.55269 9.04165 2.03882 8.65136 2.60034C8.59579 2.68093 8.54551 2.76412 8.4873 2.84341C8.22931 3.18916 7.77154 3.18786 7.50957 2.84601C7.28201 2.54835 7.07297 2.2312 6.81365 1.96213C5.97749 1.08996 4.9442 0.753307 3.75082 1.00677C2.4635 1.27973 1.61808 2.08042 1.19074 3.28665C0.693273 4.68785 0.930097 6.00456 1.7517 7.23418C2.26107 7.99587 2.88555 8.66398 3.57088 9.26969C4.70473 10.2718 5.86107 11.2493 7.01079 12.2359C7.33493 12.5153 7.66437 12.7883 7.99513 13.0691Z"
                                      fill="#5948C8"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="17"
                                    height="15"
                                    viewBox="0 0 17 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.08049 13.6273C13.6616 9.65944 16.3114 7.50406 16.3956 4.47928C16.4618 2.08365 14.7003 0.928295 14.6616 0.89581C11.8542 -1.10437 9.14066 1.04258 8.50302 1.75703C7.93197 1.04393 5.42623 -1.09172 2.49159 0.787778C2.06422 1.05969 0.652447 2.10866 0.418651 4.09999C0.0209175 7.55059 3.47482 10.3772 6.95151 13.6235C7.24435 13.8994 7.62317 14.0306 8.00729 14.0327C8.3834 14.0348 8.76514 13.8998 9.08049 13.6273Z"
                                      fill="#5948C8"
                                    />
                                  </svg>
                                )}
                                <p className="h-10">
                                  {messageobject?.likelist?.length}
                                </p>
                              </button>
                              <Dropdown className="cmn-dropdown">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={
                                      require("../Assets/images/voiletdots.svg")
                                        .default
                                    }
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  <Dropdown.Item onClick={() => {
                                    handleReplyEvents(messageobject)
                                  }}
                                  >
                                    <li>
                                      {" "}
                                      <img
                                        src={reply}
                                        alt="reply"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">Reply</p>
                                    </li>
                                  </Dropdown.Item>
                                  {/* )} */}

                                  {messageobject.messagetype == "0" && (<Dropdown.Item >
                                    <li onClick={() => {
                                      setMessage(messageobject?.message)
                                      setmessageid(messageobject?._id)
                                      setmessagedatetime(messageobject?.createdAt)
                                    }}>
                                      {" "}
                                      <img
                                        src={edit}
                                        alt="edit"
                                        className="img-fluid"
                                      />
                                      <p className="h-12" >Edit</p>
                                    </li>
                                  </Dropdown.Item>)}
                                  <Dropdown.Item onClick={() => handleCopyAction(messageobject?.message)} href="#/action-3">
                                    <li>
                                      {" "}
                                      <img
                                        src={copy}
                                        alt="copy"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">Copy</p>
                                    </li>
                                  </Dropdown.Item>
                                  {/* )} */}
                                  <Dropdown.Item onClick={() => {
                                    deletemessage(
                                      messageobject?._id,
                                      messageobject?.createdAt
                                    );
                                  }}>
                                    <li>
                                      {" "}
                                      <img
                                        src={deleteimg}
                                        alt="copy"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">
                                        Delete
                                      </p>
                                    </li>
                                  </Dropdown.Item>
                                  {(messageobject?.image.length > 1) && <Dropdown.Item onClick={() => CommonFiles.handleDownAll(messageobject?.image)}>
                                    <li>
                                      {" "}
                                      <img
                                        src={downloadImg}
                                        alt="copy"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">
                                        Download All
                                      </p>
                                    </li>
                                  </Dropdown.Item>}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          {/* Right Message boxes configuration start */}
                          {
                            //type - text && reply is there 
                            (messageobject?.messagetype == "0" && messageobject?.message_id != undefined) ? (
                              //reply box
                              <div className="reply-msg-box">
                                <button type="button">
                                  <img src={require("../Assets/images/reply.svg").default} />
                                  Replied
                                </button>
                                {/* previous message */}
                                <div className="right-user-msg-box reply-msg previous-msg-rply">
                                  <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                    {messageobject?.message_id?.message?.split(/(\s+|\n)/).map((word, index) => {
                                      const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                      return isUrl ? (
                                        <a
                                          className="white-url"
                                          key={index}
                                          href={word}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {word}
                                        </a>
                                      ) : word === '\n' ? (
                                        <br key={index} />
                                      ) : (
                                        <span key={index}>{word} </span>
                                      );
                                    })}
                                  </p>
                                </div>

                                <div className="right-reply-line">
                                  <img src={require("../Assets/images/line_reply.svg").default} />
                                </div>

                                {/* current message */}

                                <div className="right-user-msg-box crruent-msg-text">
                                  <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                    {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                      const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                      return isUrl ? (
                                        <a
                                          className="white-url"
                                          key={index}
                                          href={word}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {word}
                                        </a>
                                      ) : word === '\n' ? (
                                        <br key={index} />
                                      ) : (
                                        <span key={index}>{word} </span>
                                      );
                                    })}
                                  </p>
                                </div>
                              </div>
                            ) :
                              //type - text only
                              messageobject.messagetype == "0" ? (
                                <div className="right-user-msg-box">
                                  {
                                    <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                      {messageobject?.message.split(/(\s+|\n)/).map((word, index) => {
                                        const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                        return isUrl ? (
                                          <a
                                            className="white-url"
                                            key={index}
                                            href={word}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {word}
                                          </a>
                                        ) : word === '\n' ? (
                                          <br key={index} />
                                        ) : (
                                          <span key={index}>{word} </span>
                                        );
                                      })}
                                    </p>
                                  }
                                </div>
                              ) :
                                <div className="with-image-box">
                                  <div className="left-user-msg-box-images">
                                    {messageobject?.image?.map(function (imageobject, i) {
                                      return (
                                        <button key={i}>
                                          {imageobject.filetype === 1 && CommonFiles.getFileExtension(imageobject?.imagename) == "svg" ? (
                                            <div className="left-user-msg-box-pdf">
                                              <div className="pdf-div">
                                                <div className="d-flex">
                                                  <img
                                                    className="img-fluid pdf-set"
                                                    src={require("../Assets/images/other-file.svg").default
                                                    }
                                                    alt="File"
                                                  />
                                                  <div className="pdf-info">
                                                    <h3 className="h-13">
                                                      {CommonFiles.getFileNameFromUrl(imageobject.imagename)}
                                                    </h3>
                                                    <p className="h-12 pt-1">{imageobject?.size == undefined ? "N/A" : (imageobject?.size / 1024 / 1024).toFixed(2)}- MB</p>
                                                  </div>
                                                </div>
                                                <div
                                                  onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                >
                                                  <img
                                                    src={require("../Assets/images/download-voilet.svg").default}
                                                    alt="Download"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          ) : imageobject.filetype === 1 ? (
                                            <ChatImageComponent index={i} imageUrl={imageobject?.imagename} imgArray={messageobject?.image} />
                                          ) : imageobject.messagetype === "3" ? (
                                            <audio controls>
                                              <source
                                                src={imageobject?.imagename}
                                                type="audio/mp3"
                                              />
                                              Your browser does not support the audio element.
                                            </audio>
                                          ) : (
                                            <div className="left-user-msg-box-pdf">
                                              <div className="pdf-div">
                                                <div className="d-flex">
                                                  <img
                                                    className="img-fluid pdf-set"
                                                    src={
                                                      imageobject.filetype === "5"
                                                        ? require("../Assets/images/pdf-red.svg").default
                                                        : imageobject.filetype === "6"
                                                          ? require("../Assets/images/zip.svg").default
                                                          : require("../Assets/images/other-file.svg").default
                                                    }
                                                    alt="File"
                                                  />
                                                  <div className="pdf-info">
                                                    <h3 className="h-13">
                                                      {CommonFiles.getFileNameFromUrl(imageobject.imagename)}
                                                    </h3>
                                                    <p className="h-12 pt-1">{imageobject?.size == undefined ? "N/A" : (imageobject?.size / 1024 / 1024).toFixed(2)}- MB</p>
                                                  </div>
                                                </div>
                                                <div
                                                  onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                >
                                                  <img
                                                    src={require("../Assets/images/download-voilet.svg").default}
                                                    alt="Download"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </button>
                                      );
                                    })}
                                  </div>

                                  {/* if message is present along with image */}
                                  {messageobject?.message?.length > 0 && (

                                    <div className="right-user-msg-box">
                                      {
                                        <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                          {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                            const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                            return isUrl ? (
                                              <a
                                                className="white-url"
                                                key={index}
                                                href={word}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {word}
                                              </a>
                                            ) : word === '\n' ? (
                                              <br key={index} />
                                            ) : (
                                              <span key={index}>{word} </span>
                                            );
                                          })}
                                        </p>

                                      }

                                    </div>)}
                                </div>

                          }
                        </div>
                      </div>
                    );
                    /* Right Message boxes configuration end */
                  } else if (messageobject?.userID === userDetail?._id?.toString()) {
                    return (
                      <div className="left-user mt-4 mb-2">
                        <div className="left-user-info">
                          <div className="left-user-img">

                            {
                              messageobject?.user_image != undefined ?
                                <ChatImageComponent imageUrl={messageobject?.user_image} type="user" />
                                : <InitialsAvatar name={messageobject?.user_name || "Typetawk User"} />
                            }


                            <h2 className="h-15">
                              {messageobject?.user_name}
                            </h2>
                            <h4 className="h-12 ">
                              {timezone(messageobject?.createdAt)}
                              <span className="tym-tool-tip">
                                {moment(messageobject?.createdAt).format(
                                  "DD MMM YYYY, hh:mm a"
                                )}
                              </span>
                            </h4>
                            <div className="like-msg">
                              <button className="w-42"
                                onClick={() => {
                                  likemessage(messageobject?._id);
                                }}
                              >
                                {messageobject?.like == null ? (
                                  <svg
                                    width="16"
                                    height="14"
                                    viewBox="0 0 16 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 4.36809C0.0529216 4.06134 0.0820285 3.74938 0.162734 3.45042C0.583461 1.87375 1.52282 0.720812 3.13031 0.222983C4.84629 -0.308641 6.34265 0.120298 7.58102 1.40321C7.72655 1.55399 7.85621 1.72037 8.00174 1.88935C8.14199 1.72687 8.27164 1.56309 8.41586 1.41101C9.09854 0.687017 9.91486 0.185288 10.923 0.0592064C12.4802 -0.135766 13.8112 0.330868 14.8366 1.5085C15.8606 2.68613 16.1596 4.07693 15.9241 5.58732C15.7746 6.54398 15.3287 7.37846 14.7466 8.14535C13.9819 9.15271 13.0518 10.0028 12.0926 10.8243C10.9614 11.7913 9.82357 12.7519 8.68708 13.7125C8.25048 14.0816 7.7808 14.0998 7.36007 13.7411C5.8743 12.4763 4.3872 11.2168 2.92127 9.9326C2.17772 9.28139 1.51091 8.55479 0.964496 7.72941C0.44851 6.94952 0.10452 6.11114 0.0224917 5.17658C0.0198456 5.14148 0.00793824 5.10769 0 5.07259C0 4.83862 0 4.60336 0 4.36809ZM7.99513 13.0691C8.03614 13.0379 8.06922 13.0145 8.09965 12.9885C9.36448 11.9148 10.6359 10.849 11.8902 9.76362C12.766 9.00453 13.5982 8.20124 14.2478 7.23678C14.8657 6.31781 15.1713 5.31826 15.0284 4.21211C14.8749 3.01888 14.355 2.02713 13.2754 1.40321C12.2699 0.822198 11.2088 0.750708 10.1398 1.25504C9.50869 1.55269 9.04165 2.03882 8.65136 2.60034C8.59579 2.68093 8.54551 2.76412 8.4873 2.84341C8.22931 3.18916 7.77154 3.18786 7.50957 2.84601C7.28201 2.54835 7.07297 2.2312 6.81365 1.96213C5.97749 1.08996 4.9442 0.753307 3.75082 1.00677C2.4635 1.27973 1.61808 2.08042 1.19074 3.28665C0.693273 4.68785 0.930097 6.00456 1.7517 7.23418C2.26107 7.99587 2.88555 8.66398 3.57088 9.26969C4.70473 10.2718 5.86107 11.2493 7.01079 12.2359C7.33493 12.5153 7.66437 12.7883 7.99513 13.0691Z"
                                      fill="#5948C8"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width="17"
                                    height="15"
                                    viewBox="0 0 17 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.08049 13.6273C13.6616 9.65944 16.3114 7.50406 16.3956 4.47928C16.4618 2.08365 14.7003 0.928295 14.6616 0.89581C11.8542 -1.10437 9.14066 1.04258 8.50302 1.75703C7.93197 1.04393 5.42623 -1.09172 2.49159 0.787778C2.06422 1.05969 0.652447 2.10866 0.418651 4.09999C0.0209175 7.55059 3.47482 10.3772 6.95151 13.6235C7.24435 13.8994 7.62317 14.0306 8.00729 14.0327C8.3834 14.0348 8.76514 13.8998 9.08049 13.6273Z"
                                      fill="#5948C8"
                                    />
                                  </svg>
                                )}

                                <p className="h-10">
                                  {messageobject?.likelist?.length}
                                </p>
                              </button>
                              <Dropdown className="cmn-dropdown">
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={
                                      require("../Assets/images/voiletdots.svg")
                                        .default
                                    }
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  <Dropdown.Item onClick={() => {
                                    handleReplyEvents(messageobject)
                                  }}>
                                    <li>
                                      {" "}
                                      <img
                                        src={reply}
                                        alt="reply"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">Reply</p>
                                    </li>
                                  </Dropdown.Item>
                                  {messageobject.messagetype == "0" && (<Dropdown.Item onClick={() => handleCopyAction(messageobject?.message)} href="#/action-3">
                                    <li>
                                      {" "}
                                      <img
                                        src={copy}
                                        alt="copy"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">Copy</p>
                                    </li>
                                  </Dropdown.Item>)}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          {



                            //type - text && reply is there 
                            (messageobject.messagetype == "0" && messageobject?.message_id != undefined) ? (
                              //reply box
                              <div className="reply-msg-box left-reply-box">
                                <button type="button">
                                  <img src={require("../Assets/images/reply.svg").default} />
                                  Replied
                                </button>
                                {/* previous message */}
                                <div className="left-user-msg-box reply-msg previous-msg-rply">
                                  {messageobject?.message_id?.image.length ?
                                    <>
                                      {messageobject?.message_id?.image.map((res) =>
                                        res.filetype.toString() === '7' ?
                                          <>
                                            <div className="d-flex ms-2 align-items-center">
                                              <img
                                                className="img-fluid pdf-set"
                                                src={require("../Assets/images/other-file.svg").default
                                                }
                                                alt="File"
                                              />
                                              <div className="pdf-info ">
                                                <h3 className="h-13">
                                                  {CommonFiles.getFileNameFromUrl(res?.imagename)}
                                                </h3>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          <ChatImageComponent imageUrl={res.imagename} preview />
                                      )}
                                    </>
                                    :
                                    <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                      {messageobject?.message_id?.message?.split(/(\s+|\n)/).map((word, index) => {
                                        const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                        return isUrl ? (
                                          <a
                                            className="white-url"
                                            key={index}
                                            href={word}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {word}
                                          </a>
                                        ) : word === '\n' ? (
                                          <br key={index} />
                                        ) : (
                                          <span key={index}>{word} </span>
                                        );
                                      })}
                                    </p>}
                                </div>
                                <div className="left-reply-line">
                                  <img src={require("../Assets/images/line_reply_left.svg").default} />
                                </div>

                                {/* current message */}
                                <div className="left-user-msg-box crruent-msg-text">
                                  <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                    {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                      const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                      return isUrl ? (
                                        <a
                                          className="white-url"
                                          key={index}
                                          href={word}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {word}
                                        </a>
                                      ) : word === '\n' ? (
                                        <br key={index} />
                                      ) : (
                                        <span key={index}>{word} </span>
                                      );
                                    })}
                                  </p>
                                </div>
                              </div>
                            ) :

                              //type - text                    
                              messageobject.messagetype == "0" ? (
                                <div className="left-user-msg-box">
                                  {
                                    messageobject?.message_id == undefined ?
                                      <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                        {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                          const isUrl = /(https?:\/\/[^\s]+)/.test(word);
                                          return isUrl ? (
                                            <a
                                              className="white-url"
                                              key={index}
                                              href={word}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {word}
                                            </a>
                                          ) : word === '\n' ? (
                                            <br key={index} />
                                          ) : (
                                            <span key={index}>{word} </span>
                                          );
                                        })}
                                      </p> :
                                      (<>
                                        <div className="msg-reply-main">
                                          <button type="button">
                                            <img src={require("../Assets/images/reply.svg").default} />
                                            Replied
                                          </button>
                                          <div className="right-user-msg-box reply-msg">
                                            <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                              {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                                const isUrl = /(https?:\/\/[^\s]+)/.test(word);
                                                return isUrl ? (
                                                  <a
                                                    className="white-url"
                                                    key={index}
                                                    href={word}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {word}
                                                  </a>
                                                ) : word === '\n' ? (
                                                  <br key={index} />
                                                ) : (
                                                  <span key={index}>{word} </span>
                                                );
                                              })}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="right-user-msg-box reply-ms-bottom mt-2">
                                          <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                            {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                              const isUrl = /(https?:\/\/[^\s]+)/.test(word);
                                              return isUrl ? (
                                                <a
                                                  className="white-url"
                                                  key={index}
                                                  href={word}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {word}
                                                </a>
                                              ) : word === '\n' ? (
                                                <br key={index} />
                                              ) : (
                                                <span key={index}>{word} </span>
                                              );
                                            })}
                                          </p>
                                        </div>         </>)
                                  }
                                </div>
                              ) :
                                (
                                  <div>
                                    <div className="left-user-msg-box-images">
                                      {messageobject?.image?.map(function (imageobject, i) {
                                        return (
                                          <button key={i}>
                                            {imageobject.filetype === 1 && CommonFiles.getFileExtension(imageobject?.imagename) == "svg" ?
                                              <div className="left-user-msg-box-pdf">
                                                <div className="pdf-div">
                                                  <div className="d-flex">
                                                    <img
                                                      className="img-fluid pdf-set"
                                                      src={require("../Assets/images/other-file.svg").default
                                                      }
                                                      alt="File"
                                                    />
                                                    <div className="pdf-info">
                                                      <h3 className="h-13">
                                                        {CommonFiles.getFileNameFromUrl(imageobject.imagename)}
                                                      </h3>
                                                      <p className="h-12 pt-1">{imageobject?.size == undefined ? "N/A" : (imageobject?.size / 1024 / 1024).toFixed(2)}- MB</p>
                                                    </div>
                                                  </div>
                                                  <div
                                                    onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                  >
                                                    <img
                                                      src={require("../Assets/images/download-voilet.svg").default}
                                                      alt="Download"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              :
                                              imageobject.filetype === 1 ?
                                                <ChatImageComponent index={i} imageUrl={imageobject?.imagename} imgArray={messageobject?.image} />
                                                :
                                                imageobject.messagetype === "3" ?
                                                  <audio controls>
                                                    <source
                                                      src={imageobject?.imagename}
                                                      type="audio/mp3"
                                                    />
                                                    Your browser does not support the audio element.
                                                  </audio>
                                                  :
                                                  <div className="left-user-msg-box-pdf">
                                                    <div className="pdf-div">
                                                      <div className="d-flex">
                                                        <img
                                                          className="img-fluid pdf-set"
                                                          src={
                                                            imageobject.filetype === "5"
                                                              ? require("../Assets/images/pdf-red.svg").default
                                                              : imageobject.filetype === "6"
                                                                ? require("../Assets/images/zip.svg").default
                                                                : require("../Assets/images/other-file.svg").default
                                                          }
                                                          alt="File"
                                                        />
                                                        <div className="pdf-info">
                                                          <h3 className="h-13">
                                                            {CommonFiles.getFileNameFromUrl(imageobject.imagename)}
                                                          </h3>
                                                          <p className="h-12 pt-1"> {imageobject?.size == undefined ? "N/A" : (imageobject?.size / 1024 / 1024).toFixed(2)} - MB</p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                      >
                                                        <img
                                                          src={require("../Assets/images/download-voilet.svg").default}
                                                          alt="Download"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                            }
                                          </button>
                                        );
                                      })}
                                    </div>

                                    {/* if message is present along with image */}
                                    {messageobject?.message?.length > 0 && (

                                      <div className="left-user-msg-box">
                                        {
                                          <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                            {messageobject?.message?.split(/(\s+|\n)/).map((word, index) => {
                                              const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                              return isUrl ? (
                                                <a
                                                  className="white-url"
                                                  key={index}
                                                  href={word}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {word}
                                                </a>
                                              ) : word === '\n' ? (
                                                <br key={index} />
                                              ) : (
                                                <span key={index}>{word} </span>
                                              );
                                            })}
                                          </p>
                                        }
                                      </div>)}
                                  </div>
                                )
                          }
                        </div>
                      </div>
                    );
                  }
                  return null; // Skip rendering if the message doesn't match sender or receiver
                  /* Loading indicator for sending messages */
                })}

                {isUploading && (
                  <div className="right-user mt-4 mb-2">
                    <div className="right-user-info">
                      <div className="left-user-msg-box">
                        {/* Replace the following div with an <img> tag for your loading GIF */}
                        <div className="loader">
                          <img
                            className="send-img send-img-size"
                            src={require("../Assets/images/loader.gif")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {/* multiple files div start */}
                {arrFiles?.length > 0 && <div className="message-reply-bar">
                  <div className="message-reply-inner row">
                    {arrFiles?.map((file, iDx) =>
                      <div className="d-flex media-view-send col-md-6" key={iDx}>
                        <div className="pdf-div">
                          <div className="d-flex">
                            <img
                              className="img-fluid pdf-set"
                              src={
                                checkfiletype(file) == 5
                                  ? require("../Assets/images/pdf-red.svg")
                                    .default
                                  : checkfiletype(file) == 6
                                    ? require("../Assets/images/zip.svg")
                                      .default
                                    : require("../Assets/images/other-file.svg")
                                      .default
                              }
                            />
                            <div className="pdf-info">
                              <h3 className="h-13">{file.name}</h3>
                              <p className="h-12 pt-1">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                            </div>
                          </div>
                          <button onClick={() => removeFile(iDx)}>
                            <img
                              src={require("../Assets/images/cross.svg").default}
                            />
                          </button>
                        </div>

                      </div>
                    )}
                  </div>
                </div>}
                {/* multiple files div end */}
                <div className="message-send-bar-main">
                  <div className="message-send-bar-inner">
                    <div className="d-flex align-items-center mb-2">
                      <div className="doc-attach">
                        <img
                          src={require("../Assets/images/attach.svg").default}
                        />
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>
                      <button className="emoji-send" onClick={handleEmojies}>
                        <img
                          src={require("../Assets/images/emoji.svg").default}
                        />
                      </button>
                    </div>
                    <div className="emoji-div-up">
                      {!useEmoji ? <div></div> : <EmojiPicker onEmojiClick={onEmojiClick} />}
                    </div>

                    {/* reply div */}
                    {
                      (messagereplytext != null || messageReplyImg.length) && messagereplyid != null ? (
                        <div className="message-reply-bar chat-reply-box">
                          <div className="message-reply-inner">
                            <div className="d-flex">
                              <img className="reply-img" src={reply} />
                              {messageReplyImg.length ?
                                <>
                                  {messageReplyImg.map((res) =>
                                    (res.filetype.toString() === '7' || res.filetype.toString() === '5' || res.filetype.toString() === '2') ?
                                      <>
                                        <div className="d-flex ms-2 align-items-center">
                                          <img
                                            className="img-fluid pdf-set"
                                            src={require("../Assets/images/other-file.svg").default
                                            }
                                            alt="File"
                                          />
                                          <div className="pdf-info ">
                                            <h3 className="h-13">
                                              {CommonFiles.getFileNameFromUrl(res?.imagename)}
                                            </h3>
                                          </div>
                                        </div>
                                      </>
                                      :
                                      <ChatImageComponent imageUrl={res.imagename} preview />
                                  )}
                                </>
                                :
                                <p className="h-14" style={{ whiteSpace: "pre-line" }}>
                                  {messagereplytext?.split(/(\s+|\n)/).map((word, index) => {
                                    const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                    return isUrl ? (
                                      <a
                                        className=""
                                        key={index}
                                        href={word}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {word}
                                      </a>
                                    ) : word === '\n' ? (
                                      <br key={index} />
                                    ) : (
                                      <span key={index}>{word} </span>
                                    );
                                  })}
                                </p>}
                            </div>
                            <button>
                              <img src={require("../Assets/images/cross.svg").default} onClick={() => {
                                setMessagereplytext(null)
                                setmessagereplyid(null)
                                setMessageReplyImg([])
                              }} />
                            </button>
                          </div>
                        </div>
                      ) : null
                    }

                    {/*End reply div */}
                    {handleMention && (
                      <div
                        ref={mentionListRef}
                        className="mention-list bg-white"
                        style={{
                          height: `${Math.min(sortedMembers.length * 30, 210)}px`
                        }}
                      >
                        <ul>
                          {sortedMembers.map((res, index) => (
                            <li
                              key={res._id}
                              style={{ 'list-style-type': 'none' }}
                              className={index === selectedMemberIndex ? 'selected-user' : ''}
                              onClick={() => {
                                handleUserSelection(res.name)
                                setHandleMention(false);
                                setSelectedMemberIndex(-1);
                                messInput?.current?.focus()
                              }}
                            >
                              {res.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="form-group">
                      {/* improvement */}
                      <TextareaAutosize
                        ref={messInput}
                        maxRows="5"
                        className="form-control message-field"
                        placeholder="Type a message"
                        value={message}
                        onChange={(e) => {
                          handleMess(e.target.value, e.nativeEvent)
                        }}
                        onPaste={handlePaste}
                        onKeyDown={handleKeyDown} />
                    </div>

                    <button className="message-send" onClick={sendMessage}>
                      <img
                        src={require("../Assets/images/sendmsg.svg").default}
                      />
                      <p> Send</p>
                    </button>
                  </div>
                </div>
              </>
            )}
            {/* typetawk message bar end*/}
          </div>
        </div>
      </Container >
    </>
  );
}
