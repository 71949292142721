// export const registerServiceWorker = () => {
//   if ('firebase-messaging-sw.js' in navigator) {
//     navigator.serviceWorker
//       .register('firebase-messaging-sw.js')
//       .then(function (registration) {
//         // eslint-disable-next-line no-console
//         return registration.scope;
//       })
//       .catch(function (err) {
//       });
//   }
// };


export function register() {
  if ('serviceWorker' in navigator) {

    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
}