import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/api_service";
import { toast } from "react-hot-toast";
import API_UPLOAD_FILE from "../../services/api_upload_file";
//login
const login = createAsyncThunk("login", async (userObj, { dispatch, getState, rejectWithValue }) => {
  try {
    const { data } = await API.post("/auth/login", userObj);
    return data;
  } catch (error) {
    // You can handle specific errors here and dispatch accordingly
    return rejectWithValue(error.response.data);
  }
}
);

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (data, thunkAPI) => {
    const response = await API.post("/auth/forgotPassword", data);
    return response.data;
  }
);

export const setRoomId = createAsyncThunk(
  "setRoomId",
  async (roomId) => {
    return roomId;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, thunkApi) => {
    const response = await API.put(`/auth/resetPass/${data.token}`, data);
    return response.data;
  }
);
//get my profile
const getMyProfile = createAsyncThunk("getMyProfile", async (id) => {
  const { data } = await API.get("/auth/profiledetails");
  return data;
}
);

//update profile
const updateProfile = createAsyncThunk("updateProfile", async (userObj) => {
  const { data } = await API.post("/auth/updateProfile", userObj);
  return data;
}
);

//jitendra invite users
const createinvitedusersAction = createAsyncThunk("createinvitedusers", async (userObj) => {
  const { data } = await API.post("/auth/createinvitedusers", userObj);
  return data;
}
);

const updatemoreProfiledetailsAction = createAsyncThunk("updatemoreProfiledetails", async (userObj) => {
  const { data } = await API.post("/auth/updatemoreProfiledetails", userObj);
  if (data.status == 400) {
    toast.error(data.message)
    return data.status
  } else {
    return data;
  }
}
);

const userlistAction = createAsyncThunk("userlist", async (userObj) => {
  const { data } = await API.post("/auth/userlist", userObj);
  return data.UserlistData;
}
);

const searchUserlist = createAsyncThunk("searchUserlist", async (userObj) => {
  const { data } = await API.get(`/auth/searchUserlist?search=${userObj || ''}`, userObj);
  return data;
}
);

const GetRoomAction = createAsyncThunk("getRoom", async () => {
  const { data } = await API.get("/auth/getRoom?limit=50");
  return data.roomdata;
}
);

const GetmessageAction = createAsyncThunk("Getmessage", async ({ id, pagination, limit }) => {
  const data = await API.get(`/auth/Getmessage?receiver_id=${id}&pagination=${pagination}&limit=${limit}`);
  return data;
}
);

const newinvitationAction = createAsyncThunk("newinvitation", async (userObj) => {
  const { data } = await API.post("/auth/newinvitation", userObj);
  if (data.status == 201) {
    toast.success("Invitation resent successfully!")
    return data;
  }
}
);

const creategroupAction = createAsyncThunk("creategroup", async (userObj) => {
  const { data } = await API.post(`/auth/creategroup`, userObj);
  return data.data;
}
);
const editGroupAction = createAsyncThunk("editgroup", async (userObj) => {
  const { data } = await API.put(`/auth/ediotgroup`, userObj);
  return data.data;
}
);

const getGrouplistAction = createAsyncThunk("getGrouplist", async () => {
  const { data } = await API.get(`/auth/getGrouplist`);
  return data.grouplist;
}
);

const usertokenupdateAction = createAsyncThunk("usertokenupdate", async (userObj) => {
  const { data } = await API.post(`/auth/usertokenupdate`, userObj);
  return data.user;
}
);

const updateunreadmessageAction = createAsyncThunk("updateunreadmessage", async (userObj) => {
  const { data } = await API.post(`/auth/updateunreadmessage`, userObj);
  return data;
}
);

const accountsuspendAction = createAsyncThunk("accountsuspend", async (userObj) => {
  const { data } = await API.post(`/auth/accountsuspend`, userObj);
  return data.user;
}
);

const updatconversationwithAction = createAsyncThunk("updatconversationwith", async (userObj) => {
  const { data } = await API.post(`/auth/updatconversationwith`, userObj);
  return data.user;
}
);
const updatconversationwithgroupAction = createAsyncThunk("updatconversationwithgroup", async (userObj) => {
  const { data } = await API.post(`/auth/updatconversationwithgroup`, userObj);
  return data.user;
}
);

//group 
const GetgropmessagelistAction = createAsyncThunk("Getgropmessagelist", async ({ id, pagination, limit }) => {
  const { data } = await API.get(`/auth/Getgropmessagelist/${id}?pagination=${pagination}&limit=${limit}`);
  return data.messageresponse;
}
);

const NewgroupadddAction = createAsyncThunk("Newgroupaddd", async (userObj) => {
  return userObj
}
);

const groupdetailsAction = createAsyncThunk("group-details", async (id) => {
  const { data } = await API.get(`/auth/group/${id}`);
  return data.groupdetails;
}
);

const grouplistdataAction = createAsyncThunk("group-list-data", async (userObj) => {
  const { data } = await API.post(`/auth/group-list-data`, userObj);

  return data.grouplist;
}
);

const groupdeleteAction = createAsyncThunk("group-delete", async (userObj) => {
  const { data } = await API.post(`/auth/group-delete`, userObj);

  return data;
}
);
const uploadFileAction = createAsyncThunk("upload-file-action", async (formData) => {
  const { data } = await API.post(`/auth/upload`, formData);
  return data;
}
);
const uploadFileActionHippo = createAsyncThunk("upload-file-act", async (formData) => {
  const { data } = await API_UPLOAD_FILE.post(`/upload`, formData);
  return data;
}
);

const logoutuserAction = createAsyncThunk("logoutuser", async (userObj) => {
  const { data } = await API.get(`/auth/logoutuser`, userObj);
  return data;
}
);


export {
  login,
  accountsuspendAction,
  groupdetailsAction,
  getMyProfile,
  updateProfile,
  createinvitedusersAction,
  userlistAction,
  updatemoreProfiledetailsAction,
  searchUserlist,
  uploadFileActionHippo,
  GetRoomAction, GetmessageAction,
  newinvitationAction,
  creategroupAction,
  editGroupAction,
  getGrouplistAction,
  usertokenupdateAction,
  updateunreadmessageAction,
  updatconversationwithAction,
  GetgropmessagelistAction,
  NewgroupadddAction,
  grouplistdataAction,
  groupdeleteAction,
  updatconversationwithgroupAction,
  logoutuserAction,
  uploadFileAction
};
