import axios from "axios";

const API_UPLOAD_FILE = axios.create({
    baseURL: process.env.REACT_APP_UPLOAD_IMG_BASE_URL,
    headers: {
        api_key: process.env.REACT_APP_UPLOAD_IMG_KEY,
    },
});

API_UPLOAD_FILE.interceptors.response.use(
    (response) => {
        if (response.data.status == 401) {
            localStorage.clear();
            window.location.href = "/";
        }
        return response;
    },
    (error) => {
        return error.response;
    }
);

export default API_UPLOAD_FILE;
