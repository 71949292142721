import moment from "moment-timezone"

export const timezone = (time) => {
    const nowUtc = moment(time).utc();

    const nowIndia = nowUtc.clone().tz('Asia/Kolkata');
    const formattedIndiaTime = nowIndia.format('DD MMM YYYY, hh:mm a');
    
    const timeDifference = moment.duration(nowUtc.diff(moment()));
    const relativeTime = timeDifference.humanize(true);

    return relativeTime;
}

