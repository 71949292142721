import React, { useState, useMemo, useCallback } from "react";
import ChatPlaceholderImg from "../../Assets/images/placeholder.png";
import PlaceholderImg from "../../Assets/images/default_user_img.jpg";

const ChatImageComponent = ({
  imageUrl,
  type,
  index,
  className,
  imgArray,
  preview,
}) => {

  const download = async (url) => {
    const image = await fetch(url);
    const nameSplit = url.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlob = await image.blob();
    const imageURL_link = URL.createObjectURL(imageBlob);
    const link = document.createElement("a");
    link.href = imageURL_link;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const chatImageError = (e) => {
    e.target.src = ChatPlaceholderImg;
  };

  const onImageError = (e) => {
    e.target.src = PlaceholderImg;
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handlePrevImage = () => {
    setCurrentImage(Math.max(currentImage - 1, 0));
  };

  const handleNextImage = () => {
    setCurrentImage(Math.min(currentImage + 1, imgArray.length - 1));
  };

  const memoizedComponent = useMemo(
    () =>
      preview ? (
        <>
          <span className="img-dwn-hover">
            <img
              className="send-img send-img-size"
              width={5}
              height={5}
              src={imageUrl}
              alt="Image"
              onError={chatImageError}
            />
          </span>
        </>
      ) : type === "user" ? (
        <img
          className={`${className ? className : "user-ic-img"}`}
          src={imageUrl ? imageUrl : PlaceholderImg}
          onError={onImageError}
        />
      ) : (
        <span className="image-card">
          <img
            className="send-img send-img-size"
            src={imageUrl ? imageUrl : ChatPlaceholderImg}
            alt="Image"
            onClick={() => openImageViewer(index || 0)}
            onError={chatImageError}
          />
          <div className="d-flex gap-3 justify-content-center align-items-center image-action">
            <i className="bi bi-arrow-down-circle-fill" onClick={() => download(imageUrl)}></i>
          </div>

          {
            <div
              id="myModal"
              className={`modal-preview ${isViewerOpen ? "d-block" : "d-none"}`}
            >
              <div className="modal-content-preview">
                <span className="close-preview" onClick={closeImageViewer}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M0.3 13.7C0.5 13.9 0.7 14 1 14C1.3 14 1.5 13.9 1.7 13.7L7 8.4L12.3 13.7C12.5 13.9 12.8 14 13 14C13.2 14 13.5 13.9 13.7 13.7C14.1 13.3 14.1 12.7 13.7 12.3L8.4 7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L5.6 7L0.3 12.3C-0.1 12.7 -0.1 13.3 0.3 13.7Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <button className="commn-img-dwnld" onClick={() => download(imgArray[currentImage].imagename)}>
                  <p>Download</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="17"
                    viewBox="0 0 14 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 6H10V0H4V6H0L7 13L14 6ZM0 15V17H14V15H0Z"
                      fill="white"
                    />
                  </svg>
                </button>
                {/* </div> */}
                <div className="modal-img-show">
                  <img
                    className=""
                    src={
                      imgArray.length
                        ? imgArray[currentImage].imagename
                        : ChatPlaceholderImg
                    }
                  />
                  <div className="img-dwnld">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="17"
                      viewBox="0 0 14 17"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 6H10V0H4V6H0L7 13L14 6ZM0 15V17H14V15H0Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                {imgArray?.length > 1 ? <div className="type-modal-nxt-btns">
                  {currentImage !== 0 && <button
                    className="btn-primary prev-btn"
                    onClick={() => handlePrevImage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="496"
                      height="496"
                      viewBox="0 0 496 496"
                      fill="none"
                    >
                      <path
                        d="M248.001 496C111.001 496 0.000976562 385 0.000976563 248C0.000976563 111 111.001 0 248.001 0C385.001 0 496.001 111 496.001 248C496.001 385 385.001 496 248.001 496ZM364.001 204L248.001 204L248.001 133.1C248.001 122.4 235.001 117 227.501 124.6L113.201 239.5C108.501 244.2 108.501 251.7 113.201 256.4L227.501 371.4C235.101 379 248.001 373.6 248.001 362.9V292H364.001C370.601 292 376.001 286.6 376.001 280L376.001 216C376.001 209.4 370.601 204 364.001 204Z"
                        fill="white"
                      />
                    </svg>
                  </button>}
                  {(currentImage !== (imgArray?.length - 1)) && <button
                    className="btn-primary next-btn"
                    onClick={() => handleNextImage()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="496"
                      height="496"
                      viewBox="0 0 496 496"
                      fill="none"
                    >
                      <path
                        d="M248 0C385 0 496 111 496 248C496 385 385 496 248 496C111 496 0 385 0 248C0 111 111 0 248 0ZM132 292H248V362.9C248 373.6 261 379 268.5 371.4L382.8 256.5C387.5 251.8 387.5 244.3 382.8 239.6L268.5 124.6C260.9 117 248 122.4 248 133.1V204H132C125.4 204 120 209.4 120 216V280C120 286.6 125.4 292 132 292Z"
                        fill="white"
                      />
                    </svg>
                  </button>}
                </div> : null}
              </div>
              <div id="caption"></div>
            </div>
          }
        </span>
      ),
    [imageUrl, type, className, isViewerOpen, currentImage]
  );

  return memoizedComponent;
};

export default ChatImageComponent;
