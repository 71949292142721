
import React, { useState } from 'react'
import TypetawkMembersInvite from './TypetawkMembersInvite';
import TypetawkMembersInviteTwo from './TypetawkMembersInvitetwo';
export default function Settingmembers() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});

  const nextStep = () => setStep(step + 1);

  const prevStep = () => setStep(step - 1);

  switch (step) {
    case 1:
      return (
        <TypetawkMembersInvite formData={formData} setFormData={setFormData} nextStep={nextStep} />
      )
    case 2:
      return (
        <TypetawkMembersInviteTwo formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
      )
    case 3:
    default:
      return null;
  }
}