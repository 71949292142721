import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, image, og_title, og_des, twitter_title, twitter_des, type = 'website' }) => {
    return (
        <>
            <Helmet>
                { /* Standard metadata tags */}
                <title>{title}</title>
                <meta name='title' content={title} />
                <meta name='description' content={description} />
                <meta name="keywords" content="login, chat" />
                <link rel="canonical" href="https://www.typetawk.com/" />
                { /* End standard metadata tags */}

                { /* Facebook tags */}
                <meta property="og:type" content={type} />
                <meta property="og:title" content={og_title || title} />
                <meta property="og:description" content={og_des || description} />
                <meta property="og:image" content="https://www.typetawk.com/socials/social-icon.jpg" />
                <meta property="og:url" content="https://www.typetawk.com/" />
                <meta property="og:site_name" content="typetawk" />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="315" /> 
                { /* End Facebook tags */}

                { /* Twitter tags */}
                {/* <meta name="twitter:creator" content={name} /> */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={twitter_title || title} />
                <meta name="twitter:description" content={twitter_des || description} />
                <meta name="twitter:image:src" content="https://www.typetawk.com/socials/social-icon.jpg" />
                <meta name="twitter:image" content={image} />
                { /* End Twitter tags */}
            </Helmet>
        </>
    )
}
export default SEO