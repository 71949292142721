import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import MainLayout from "../components/Layout/MainLayout";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { useDispatch } from "react-redux";
import { createinvitedusersAction } from "../redux/actions/authAction";
import { toast } from "react-hot-toast";

export default function TypetawkMembersInviteTwo({ formData, prevStep }) {

  const dispatch = useDispatch();
  const [submitbutton, setSubmitbutton] = useState(0);
  const navigate = useNavigate();
  const [emails, setEmails] = useState(formData.emails || []);

  const formik = useFormik({
    initialValues: { emails: formData.emails || [], message: formData.message || "", },
    validationSchema: Yup.object({
      emails: Yup.array()
        .of(Yup.string().email('Invalid email format'))
        .required('Emails are required')
        .min(1, 'At least one email is required'),
    }),
    onSubmit: async (values) => {
      setSubmitbutton(1)
      try {
        // Dispatch the action to create invited users
        const response = await dispatch(createinvitedusersAction({
          emails: values.emails,
          role: formData.role,
          message: values.message
        }));
        if (response && response?.payload?.status == 201) {
          navigate('/typetawk-settings');
        } else {
          toast.error(response?.payload?.message);
          console.error('Error creating invited users:', response.error);
        }
      } catch (error) {
        console.error('Error creating invited users:', error);
      }
    },
  });


  return (
    <>
      <MainLayout>
        <Form onSubmit={formik.handleSubmit}>
          <Container fluid className="px-0">
            {/* typetawk chat box start */}
            <div className="typetawk-group-main">
              <Container>
                <div className="typetawk-members-invite">
                  <div className="collab-top">
                    <img src={require("../Assets/images/Groups.svg").default} />
                    <h2 className="h-40">Invite members to Typetawk</h2>
                  </div>
                </div>
                <Row>
                  <Col
                    lg="3"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Enter their email address(es).</Form.Label>
                  </Col>
                  <Col lg="9">
                    <div className="add-member-show">
                      <ReactMultiEmail
                        placeholder='Input your email'
                        className="member-show-box invite"
                        emails={emails}
                        onChange={(e) => {
                          setEmails(e)
                          formik.setFieldValue('emails', e, { strict: false })
                        }
                        }
                        autoFocus={true}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              <div data-tag-item>{email}</div>
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    lg="3"
                    className="d-flex justify-content-end align-items-center"
                  ></Col>
                  <Col lg="9">
                    <div className="add-member-show">
                      <div className="member-show-box">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Message (Optional)"
                            onChange={formik.handleChange}
                            value={formik.message}
                          />
                          {formik.errors.message && (
                            <div className="error">{formik.errors.message}</div>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="group-bottom-btns">
              <div className="group-btns-inner">
                <button onClick={() => {
                  prevStep()
                }} className="cancel-group">
                  Back
                </button>
              </div>
              <div className="group-btns-inner">
                <button type="submit" className={`create-group ${submitbutton == 0 ? (emails.length === 0 ? 'disabled-button' : '') : 'disabled-button'}`} >
                  Invite {emails.length} Member
                </button>
                {/* {
                  submitbutton == 0 ?
                    <button type="submit" className={`create-group ${emails.length === 0 ? 'disabled-button' : ''}`} >
                      Invite {emails.length} Member
                    </button>
                    : <button type="submit" className={`create-group disabled-button`} >
                      Invite {emails.length} Member
                    </button>
                } */}
              </div>
            </div>
            {/* typetawk message bar end*/}
          </Container>
        </Form>
      </MainLayout>
    </>
  );
}
