import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

const initialState = {
  users: null,
  totalUsers: null,
  userDetails: null,
};

export const userMgmtSlice = createSlice({
  name: "userMgmtSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder
    //   .addCase(getUsers.fulfilled, (state, { payload }) => {
    //     if (payload?.success) {
    //       state.users = payload.data.users;
    //       state.totalUsers = payload.data.totalUsers;
    //     } else if (!payload?.success) {
    //       toast.error(payload?.message);
    //     }
    //   })
  },
});

export default userMgmtSlice.reducer;
