export const checkfiletype = (file) => {
  // Check audio file type
  if (file.type.startsWith("audio")) {
    return 3;
  }
  // Check image file type
  else if (file.type.startsWith("image")) {
    return 1;
  }
  // Check video file type
  else if (file.type.startsWith("video")) {
    return 2;
  }
  // Check JSON file type
  else if (file.type === "application/json") {
    return 4;
  }
  // Check PDF file type
  else if (file.type === "application/pdf") {
    return 5;
  }
  else if (file.type === "application/zip") {
    return 6;
  }
  else {
    return 7;
  }
};




