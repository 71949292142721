import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import usericon from "../Assets/images/usericonlight.svg";
import DeleteIcon from "../Assets/images/deleteicon.svg";
import { Modal, Button } from "react-bootstrap";
import MainLayout from "../components/Layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { groupdeleteAction, grouplistdataAction } from "../redux/actions/authAction";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import moment from "moment";
import ChatImageComponent from "../components/ImgComponent/ChatImageComponent";
import CommonFiles from "../Util/CommonFiles";

const TableBody = (props) => {
  const { tawkgroup, handleShow, setId, setTitle } = props
  return <>
    {tawkgroup?.map((groupobject) =>
      <tr>
        <td>{groupobject?.groupName}</td>
        <td>
          <div className="members-list">
            <ul>
              {

                groupobject?.memberDetails?.map(function (user_object, i) {
                  if (i < 9) {

                    return (
                      <li>
                        {user_object?.profile_pic == undefined ? (
                          <InitialsAvatar
                            name={user_object?.name || "Typetawk User"}
                          />
                        ) :
                          <img
                            src={user_object?.profile_pic}
                            onError={CommonFiles.onImageError}
                          />
                          // <ChatImageComponent imageUrl={user_object?.profile_pic} type="user" />
                        }
                      </li>
                    )
                  }
                })

              }


              {
                groupobject?.memberlist.length > 10 ? <li className="total-members">+10</li> : ""
              }
            </ul>
          </div>
        </td>
        <td>{moment(groupobject?.latestMessage?.createdAt).format('YYYY-MM-DD H:m')}</td>
        <td>
          <div className="creator">
            <img src={usericon} />
            <p>{groupobject?.creatordetails?.name}</p>
          </div>
        </td>
        <td style={{ textAlign: "center" }}>
          <button className="delete-icon" onClick={() => {
            setId(groupobject?._id)
            setTitle(groupobject?.groupName)
            handleShow()
          }}>
            <img src={DeleteIcon} />
          </button>
        </td>
      </tr>
    )}
  </>
}

export default function TypetawkGroup() {
  const [mshow, msetShow] = useState(false);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [fiterthegroup, setfiterthegroup] = useState("");
  const [filter, setfilter] = useState("2");
  const handleClose = () => msetShow(false);
  const handleShow = () => msetShow(true);
  const dispatch = useDispatch();
  const [data, setData] = useState([])

  useEffect(() => {
    dispatch(grouplistdataAction()).then((res) => {
      let array = [...res.payload]
      let x = array.sort((x, y) => {
        return new Date(x.createdAt) < new Date(y.createdAt) ? 1 : -1
      })
      
      setData(x)
    })
  }, []);

  const tawkgroup = useSelector((state) => state.auth.tawkgroup);

  useEffect(() => {
    let filteredData = [...data]
    if (!filteredData) return
    if (filter == '1') {
      filteredData.sort(function (a, b) {
        if (a?.groupName < b?.groupName) {
          return -1;
        }
        if (a?.groupName > b?.groupName) {
          return 1;
        }
        return 0;
      });
      setData(filteredData)
    }
    else if (filter == '2') {
      filteredData.sort((x, y) => {
        return new Date(x.createdAt) < new Date(y.createdAt) ? 1 : -1
      })
      setData(filteredData)
    }
  }, [filter])

  useEffect(() => {
    let filteredData = [...tawkgroup]
    if (!filteredData) return
    let x = filteredData.filter((object) => {
      if (object?.memberlist.length == parseInt(fiterthegroup) && fiterthegroup != "All" && fiterthegroup != "") {
        return object
      } else if (fiterthegroup == "All" || fiterthegroup == "") {
        return object
      }
    })
    if (filter == '1') {
      x.sort(function (a, b) {
        if (a?.groupName < b?.groupName) {
          return -1;
        }
        if (a?.groupName > b?.groupName) {
          return 1;
        }
        return 0;
      });
      setData(x)
    }
    else if (filter == '2') {
      x.sort((x, y) => {
        return new Date(x.createdAt) < new Date(y.createdAt) ? 1 : -1
      })
      setData(x)
    } else {
      setData(x)
    }
  }, [fiterthegroup])

  const handleDelete = () => {
    dispatch(groupdeleteAction({ id: id })).then(function () {
      dispatch(grouplistdataAction())
      msetShow(false)
    })
  }
  return (
    <>
      <MainLayout>
        <Container fluid className="px-0">
          {/* typetawk chat box start */}
          <div className="typetawk-group-main">
            <Container>
              <div className="typetawk-group">
                <Row>
                  <Col
                    xxl="4"
                    xl="5"
                    lg="5"
                    className="d-flex justify-content-end align-items-center mt-4"
                  >
                    <Form.Label className="m-0chat-header-left mt-2">Filter topics by member</Form.Label>
                  </Col>
                  <Col xxl="5" xl="5" lg="5" className="d-flex gap-3">
                    <div>
                      <Form.Label>There are {data?.length} Tawks in Collarbry</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className=""
                        onChange={(e) => {
                          setfiterthegroup(e.target.value)
                        }}
                      >
                        <option>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                    <div className="w-40">
                      <Form.Label>Filter Tawks</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className=""
                        value={filter}
                        onChange={(e) => {
                          setfilter(e.target.value)
                        }}
                      >
                        <option value="1">Alphabetic</option>
                        <option value="2">Latest Tawk</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>

                <Table className="data-table" responsive>
                  <thead>
                    <tr>
                      <th>Tawks Name ({data?.length})</th>
                      <th>Members</th>
                      <th>Updated at</th>
                      <th>Created by</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableBody tawkgroup={data} setId={setId} setTitle={setTitle} handleShow={handleShow} />
                  </tbody>
                </Table>
              </div>
            </Container>
            <Modal
              className="group-popup-main"
              show={mshow}
              onHide={handleClose}
              centered
            >
              <Modal.Body>
                <div className="group-delete-popup">
                  <div className="popup-upper">
                    <img src={DeleteIcon} />
                    <h4>
                      Permanently Delete
                      <br /> {title} Tawk?
                    </h4>
                  </div>
                  <Button className="cancel-group" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="delete-group" onClick={handleDelete}>
                    Delete
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          {/* typetawk chat box end */}
          {/* typetawk message bar start*/}
          <div className="group-bottom-btns">
            <div className="group-btns-inner">
              <button className="create-group">Update</button>
            </div>
          </div>
          {/* typetawk message bar end*/}
        </Container >
      </MainLayout >
    </>
  );
}
