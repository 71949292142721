import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import Table from "react-bootstrap/Table";
import usericon from "../Assets/images/usericonlight.svg";
import MainLayout from "../components/Layout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { accountsuspendAction, newinvitationAction, userlistAction } from "../redux/actions/authAction";
import RoleConfig from "../Helpers/roles";
import { timezone } from "../Helpers/timezone";

export default function TypetawkSettingMembers() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userlistAction());
  }, []);
  const userlist = useSelector((state) => state.auth?.userlists);

  return (
    <>
      <MainLayout>
        <Container fluid className="px-0">
          {/* typetawk chat box start */}
          <div className="typetawk-group-main">
            <Container>
              <div className="typetawk-group">
                <Row>
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    xs="12"
                    className="d-flex justify-content-start align-items-center mt-4"
                  >
                    <div className="setting-members">
                      <h5>
                        Memory Size :{" "}
                        <span style={{ color: "#5948C8" }}>30 GB</span>
                      </h5>
                      <h5>
                        Number of users :{" "}
                        <span style={{ color: "#5948C8" }}>
                          {userlist?.length} Users
                        </span>
                      </h5>
                    </div>
                  </Col>
                  <Col
                    xxl="6"
                    xl="6"
                    lg="6"
                    md="6"
                    sm="6"
                    xs="12"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <NavLink to="/member-invite">
                      <button className="add-member-btn">Add Member</button>
                    </NavLink>
                  </Col>
                </Row>

                <Table className="data-table mt-4" responsive>
                  <thead>
                    <tr>
                      <th>Member</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Joined Groups</th>
                      <th>Last Login</th>
                      <th style={{ textAlign: "end" }}>Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userlist?.map(function (objectuser, i) {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="creator">
                              <img src={usericon} />
                              <p>{objectuser?.username}</p>
                            </div>
                          </td>
                          <td>
                            {" "}
                            {(() => {
                              if (objectuser?.role === RoleConfig.admin) {
                                return "Admin";
                              } else if (
                                objectuser?.role === RoleConfig.manager
                              ) {
                                return "Manager";
                              } else if (
                                objectuser?.role === RoleConfig.member
                              ) {
                                return "Member";
                              } else {
                                return "Guest";
                              }
                            })()}{" "}
                          </td>
                          <td>
                            {objectuser?.email}
                            {objectuser?.completeprofile == 0 &&
                              <>
                                <span>(Invited)</span>
                                <img height="20" src={require("../Assets/images/refresh.png")} onClick={() => {
                                  dispatch(newinvitationAction({
                                    email: objectuser.email
                                  }))
                                }} />
                              </>
                            }
                          </td>
                          <td>---</td>
                          <td>{timezone(objectuser?.createdAt)}</td>
                          <td style={{ textAlign: "end" }}>
                            <div className="form-switch">
                              <input
                                className="form-check-input table-inputs"
                                type="checkbox"
                                role="switch"
                                checked={objectuser?.status == 1}
                                onChange={() => {
                                  dispatch(accountsuspendAction({
                                    user_id: objectuser?._id,
                                    status: objectuser?.status == 1 ? 0 : 1
                                  }))
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Container>
          </div>
          <div className="group-bottom-btns">
            <div className="group-btns-inner">
              <button className="create-group">Update</button>
            </div>
          </div>
        </Container>
      </MainLayout>
    </>
  ); 
}
