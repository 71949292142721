import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import logo from "../../Assets/images/log-blue-main.webp";
import { Link, useNavigate } from "react-router-dom";
import user from "../../Assets/images/usericonmen.svg";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/actions/authAction";
import toast from "react-hot-toast";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validateValue, setValidateValue] = useState(false);
  return (
    <>
      <div className="main">
        <Container fluid>
          <Row>
            {/*ForgotPassword left section start */}
            <Col xxl="3" xl="3" lg="4" className="pt-4">
              <div className="logo-main">
                <img src={logo} alt="Logo" />
                <p className="purp-subhdng ">
                  Be engaged with your project 24/7
                </p>
              </div>
              <img className="user-img" src={user} alt="user" />
            </Col>
            {/*reserpassword left section end */}

            {/*reserpassword right section start */}
            <Col xxl="9" xl="9" lg="8">
              <div className="blue-background justify-content-around align-items-center d-flex">
                <div className="blue_inner">
                  <Row>
                    <Col lg="12">
                      <h2 className="hdng text-center">Forgot Password</h2>
                      <p className="sub-hdng text-center">
                        Forgot Password with Typetawk
                      </p>

                      <p className="pt-3 sub-hdng text-center">
                        Enter your Email and Instructions
                        <br /> will be sent to you!
                      </p>
                      {/* reserpassword form start */}
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validate={(values) => {
                          const errors = {};

                          if (!values.email) {
                            errors.email = "Required";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = "Invalid email address";
                          }

                          return errors;
                        }}
                        validateOnChange={validateValue}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          setValidateValue(true);
                          dispatch(forgotPassword(values)).then((data) => {
                            if (data?.payload?.success) {
                              resetForm();
                              toast.success(data?.payload?.message);
                              navigate("/signin");
                            } else {
                              toast.error(data?.payload?.message);
                            }
                          });
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          errors,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            className="py-4 login-form"
                          >
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="name@domain.com"
                                name="email"
                                onChange={handleChange}
                                value={values.email}
                              />
                              {errors.email && (
                                <span className="error_valid">
                                  {errors.email}
                                </span>
                              )}
                            </Form.Group>
                            <button to="#" className="submit" type="submit">
                              Forgot
                            </button>
                            <Link to="/" className="sub-14 forgot">
                              Remember it? Log in
                            </Link>
                          </Form>
                        )}
                      </Formik>
                      {/* Forgotpassword form end */}
                    </Col>
                    <Col lg="12">
                      <div className="copyright">
                        <p>© 2024 typetawk</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*ForgotPassword right section end */}
          </Row>
        </Container>
      </div>
    </>
  );
}
