import { createSlice } from "@reduxjs/toolkit";
import toast, { Toaster } from 'react-hot-toast';
import { GetRoomAction, GetmessageAction, getGrouplistAction, login, searchUserlist, updateProfile, userlistAction, setRoomId, accountsuspendAction, NewgroupadddAction, grouplistdataAction, GetgropmessagelistAction } from "../actions/authAction";
import { HttpStatusCode } from "axios";
import { getMyProfile } from "../actions/authAction";


const initialState = {
  userDetail: localStorage.getItem('userDetail') ? JSON.parse(localStorage.getItem('userDetail')) : null,
  userlists: [],
  loading: false,
  searchList: [],
  roomlist: [],
  messagelist: [],
  grouplist: [],
  currentRoomId: null,
  tawkgroup: []
};

export const userAuthSlice = createSlice({
  name: "userAuthSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //login cases 
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        if (payload?.status == HttpStatusCode.Ok) {
          state.userDetail = payload?.userdetails;
          localStorage.setItem('token', payload?.userdetails?.token?.access?.token);
          localStorage.setItem('completeprofile', payload?.userdetails?.completeprofile);
          if (payload?.userdetails?.completeprofile == 0 || payload?.userdetails?.completeprofile == undefined) {
            window.location.href = "/complete-profile";
          } else {
            window.location.href = "/messages";
            toast.success("Logged in Successfully");
          }
          state.loading = false;
        } else {
          state.loading = false;
          toast.error(payload?.message);
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        toast.error(action.payload?.message || "Login failed");
      })

      //get my profile
      .addCase(getMyProfile.fulfilled, (state, { payload }) => {
        if (payload?.status == HttpStatusCode.Ok) {
          state.userDetail = payload?.userdetails;
          localStorage.setItem('userDetail', JSON.stringify(payload?.userdetails));
          //  toast.success("Profile data saved");
        }
        else {
          toast.error(payload?.message);
        }
      })
      .addCase(searchUserlist.fulfilled, (state, { payload }) => {
        if (payload?.status == HttpStatusCode.Ok) {
          state.searchList = payload?.data;
          //toast.success("Profile updated successfully");
        } else {
          toast.error(payload?.message);
        }
      })
      .addCase(GetRoomAction.fulfilled, (state, { payload }) => {
        state.roomlist = payload;
        //toast.success("Profile updated successfully");
      })
      // Get Messages cases
      .addCase(GetmessageAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetmessageAction.fulfilled, (state, { payload }) => {
        state.messagelist = payload?.data;
        state.roomlist = state.roomlist.map((roomobject) => {
          if (roomobject?._id == payload?.data?.room_id) {
            var roomobject = {
              ...roomobject,
              unread_message: payload?.unread_message, // Increment unread count
            };
          }
          return roomobject
        });
        state.loading = false;
      })
      .addCase(GetmessageAction.rejected, (state) => {
        state.loading = false;
      })
      //Group list cases
      .addCase(getGrouplistAction.fulfilled, (state, { payload }) => {
        state.grouplist = payload;
      })
      .addCase(GetgropmessagelistAction.fulfilled, (state, { payload }) => {
        state.grouplist = state.grouplist.map((roomobject) => {
          if (roomobject?.groupID?._id == payload?.groupID) {
            var roomobject = {
              ...roomobject,
              totalunreadmessage: payload?.totalunreadmessage, // Increment unread count
            };
          }
          return roomobject
        });
      })
      .addCase(grouplistdataAction.fulfilled, (state, { payload }) => {
        state.tawkgroup = payload;
      })
      //jitendra
      .addCase(userlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.userlists = action.payload;
      })
      .addCase(NewgroupadddAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.grouplist.push(action.payload);
        // state.userlists = action.payload;
      })
      .addCase(accountsuspendAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        // Assuming action.payload contains the user ID(s) to be suspended
        const suspendedUserIds = action.payload;
        // Filter out the suspended users from the userlists array
        state.userlists = state.userlists.map((userobject) => {
          if (userobject?._id == suspendedUserIds?._id) {
            return suspendedUserIds
          }
          return userobject
        });
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        if (payload?.status == HttpStatusCode.Ok) {
          toast.success("Profile updated successfully");
        } else {
          toast.error(payload?.message);
        }
      })
      //set current room id
      .addCase(setRoomId.fulfilled, (state, { payload }) => {
        state.currentRoomId = payload;
      })
  },
  //case for setting room id
});

export const selectUserDetail = (state) => state.auth.userDetail;
export default userAuthSlice.reducer;
