import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import logo from "../../Assets/images/log-blue-main.webp";
import { Link } from "react-router-dom";
import eye from "../../Assets/images/eye.svg";
import Hideeye from "../../Assets/images/hide_eye.svg";
import user from "../../Assets/images/usericonmen.svg";
import { useState } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/authAction";
import SEO from "../../components/SEO";

export default function Login() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  const [validateValue, setValidateValue] = useState(false);
  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <div className="main">
        <SEO
          title="Login - Typetawk"
          description='Log in securely to Typetawk, the premier platform for streamlined communication and collaboration.'
          og_title='Typetawk - Login'
          og_des='Chatting APP'
        />
        <Container fluid>
          <Row>
            {/*login left section start */}
            <Col xxl="3" xl="3" lg="4" className="pt-4">
              <div className="logo-main">
                <img src={logo} alt="Logo" />
                <p className="purp-subhdng ">
                  Be engaged with your project 24/7
                </p>
              </div>
              <img className="user-img" src={user} alt="user" />
            </Col>
            {/*login left section end */}

            {/*login right section start */}
            <Col xxl="9" xl="9" lg="8">
              <div className="blue-background justify-content-around align-items-center d-flex">
                <div className="blue_inner">
                  <Row>
                    <Col lg="12">
                      <h2 className="hdng text-center">Welcome Back !</h2>
                      <p className="sub-hdng text-center">
                        Log in continue to Typetawk
                      </p>
                      {/* login form start */}
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                        }}
                        validate={(values) => {
                          const errors = {};

                          if (!values.email) {
                            errors.email = "Required";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = "Invalid email address";
                          }
                          if (!values.password) {
                            errors.password = "Required";
                          }
                          return errors;
                        }}
                        validateOnChange={validateValue}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          setValidateValue(true);
                          values.device_token =
                            localStorage.getItem("devicetoken");
                          dispatch(login(values)).then((data) => {

                            // alert("logged in>>")
                            // if (data?.payload?.user?.success) {
                            //   resetForm();
                            //   if (data?.payload?.user?.data?.salonname == undefined) {
                            //     navigate(`/signup2/${data?.payload?.user?.data?._id}`);
                            //     toast.success("You’re almost there!");
                            //   } else if (data?.payload?.user?.data?.hear_about_us == undefined) {
                            //     navigate(`/signup3/${data?.payload?.user?.data?._id}`)
                            //     toast.success("You’re almost there!");
                            //   } else if (data?.payload?.user?.data?.subscription == undefined) {
                            //     navigate(`/subscription/${data?.payload?.user?.data?._id}`);
                            //     toast.success("Logged in Successfully");
                            //   } else {
                            //     localStorage.setItem("token", data?.payload?.user?.data?.token);
                            //     localStorage.setItem("userId", data?.payload?.user?.data?._id);
                            //     localStorage.setItem("firstname", data?.payload?.user?.data?.firstname);
                            //     localStorage.setItem("lastename", data?.payload?.user?.data?.lastname);
                            //     window.location.href = "/search";
                            //     toast.success("Logged in Successfully");
                            //   }
                            // } else {
                            //   toast.error(data?.payload?.user?.message);
                            // }
                          });
                          setSubmitting(false);
                        }}
                      >
                        {({
                          values,
                          errors,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form
                            onSubmit={handleSubmit}
                            className="py-4 login-form"
                          >
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email </Form.Label>
                              <Form.Control
                                name="email"
                                type="email"
                                placeholder="name@domain.com"
                                onChange={handleChange}
                                value={values.email}
                              />
                              {errors.email && (
                                <span className="error_valid">
                                  {errors.email}
                                </span>
                              )}
                            </Form.Group>
                            <Form.Group
                              className="mb-4 position-relative"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Password</Form.Label>
                              <div className="d-flex">
                                <Form.Control
                                  name="password"
                                  type={showPass ? "text" : "password"}
                                  placeholder="******************"
                                  onChange={handleChange}
                                  value={values.password}
                                />
                                <span
                                  onClick={() => setShowPass(!showPass)}
                                  className="passshow"
                                >
                                  {showPass ? (
                                    <img src={eye} />
                                  ) : (
                                    <img src={Hideeye} />
                                  )}
                                </span>
                              </div>
                              {errors.password && (
                                <span className="error_valid">
                                  {errors.password}
                                </span>
                              )}
                            </Form.Group>
                            {/* <Form.Group
                              className="mb-3"
                              controlId="formBasicCheckbox"
                            >
                              <Form.Check type="checkbox" label="Remember me" />
                            </Form.Group> */}
                            <button disabled={loading} className="submit" type="submit">
                              {loading ? 'Logging in...' : 'Log in'}
                            </button>
                            <Link
                              to="/forgot-password"
                              className="sub-14 forgot"
                            >
                              Forgot Password?
                            </Link>
                          </Form>
                        )}
                      </Formik>
                      {/* login form end */}
                    </Col>
                    <Col lg="12">
                      <div className="copyright">
                        <p>© 2024 typetawk</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/*login right section end */}
          </Row>
        </Container>
      </div>
    </>
  );
}
