import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import MainLayout from "../components/Layout/MainLayout";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import RoleConfig from "../Helpers/roles";

export default function TypetawkMembersInvite({ formData, setFormData, nextStep }) {

  // const [selectedRole, setSelectedRole] = useState(formData.role || 0);

  const formik = useFormik({
    initialValues: { role: formData.role || 0, },
    validationSchema: Yup.object({ role: Yup.string().required('Role is required'), }), onSubmit: values => {
      setFormData({
        ...formData,
        ...values,
      });
      localStorage.setItem("selectedRole", values.role);
      nextStep();
    }
    // Handle step 1 submission
  });

  // useEffect(() => {
  //   const savedRole = localStorage.getItem("selectedRole");
  //   if (savedRole) setSelectedRole(savedRole)
  // }, []);

  const handleFormChange = (e) => {
    formik.handleChange(e);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <MainLayout>
        <Form onSubmit={formik.handleSubmit}>
          <Container fluid className="px-0">
            {/* typetawk chat box start */}
            <div className="typetawk-group-main">
              <Container>
                <div className="typetawk-members-invite">
                  <div className="collab-top">
                    <img src={require("../Assets/images/Groups.svg").default} alt="Groups" />
                    <h2 className="h-40">Invite members to Typetawk</h2>
                    <p className="sub-18">
                      Select a role for this invitee within your organization.
                    </p>
                  </div>
                  <div className="typetawk-select-boxes">
                    <label className="form-check-label invite-select" htmlFor="flexRadioDefault1">
                      <Container>
                        <Row className="d-flex align-items-center">
                          <Col lg="1" className="px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              id="flexRadioDefault1"
                              value={RoleConfig.manager}
                              checked={formData.role == RoleConfig.manager}
                              onChange={handleFormChange}
                            />
                          </Col>
                          <Col lg="11" className="px-0">
                            <h3>Administrator</h3>
                            <p>
                              Can manage Space operations such as user settings,
                              Space settings, and teams.
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </label>
                    <label className="form-check-label invite-select" htmlFor="flexRadioDefault2">
                      <Container>
                        <Row className="d-flex align-items-center">
                          <Col lg="1" className="px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              id="flexRadioDefault2"
                              value={RoleConfig.member}
                              checked={formData.role == RoleConfig.member}
                              onChange={handleFormChange}
                            />
                          </Col>
                          <Col lg="11" className="px-0">
                            <h3>Member</h3>
                            <p>
                              Basic access for collaborating within projects. Can
                              also create and manage teams within the Space.
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </label>
                    <label className="form-check-label invite-select" htmlFor="flexRadioDefault3">
                      <Container>
                        <Row className="d-flex align-items-center">
                          <Col lg="1" className="px-0">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="role"
                              id="flexRadioDefault3"
                              value={RoleConfig.guest}
                              checked={formData.role == RoleConfig.guest}
                              onChange={handleFormChange}
                            />
                          </Col>
                          <Col lg="11" className="px-0">
                            <h3>Guest</h3>
                            <p>
                              Limited access within the Space including viewing
                              their own account details and viewing the team that
                              they belong to.
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </label>
                  </div>
                </div>
              </Container>
            </div>
            {/* typetawk chat box end */}
            {/* typetawk message bar start*/}
            <div className="group-bottom-btns">
              <div className="group-btns-inner">
                <Link to="/typetawk-settings" className="cancel-group">
                  Cancel
                </Link>
              </div>
              <div className="group-btns-inner">
                <button type="submit" className={`create-group ${!formData.role ? 'disabled-button' : ''}`}>
                  Next
                </button>
              </div>
            </div>
            {/* typetawk message bar end*/}
          </Container>
        </Form>

      </MainLayout>
    </>
  );
}
