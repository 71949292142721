import React, { lazy, useCallback, useMemo, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import whitelogo from "../Assets/images/logo-main.webp";
import { useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import searchicon from "../Assets/images/searchicon.svg";
import reply from "../Assets/images/reply.svg";
import copy from "../Assets/images/copy.svg";
import deleteimg from "../Assets/images/deletec.svg";
import downloadImg from "../Assets/images/download-voilet.svg";
import edit from "../Assets/images/edit.svg";
import smalllogo from "../Assets/images/small-mian.svg";
import { useState } from "react";
import InitialsAvatar from "react-initials-avatar";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectUserDetail } from "../redux/reducers/authReducer";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import {
  GetRoomAction,
  GetmessageAction,
  searchUserlist,
  uploadFileAction,
  usertokenupdateAction,
} from "../redux/actions/authAction";
import moment from "moment/moment";
import { timezone } from "../Helpers/timezone";
import { checkfiletype } from "../Helpers/filetypecheck";
import TextareaAutosize from "react-textarea-autosize";
import toast from "react-hot-toast";
import EmojiPicker from "emoji-picker-react";
import { getSocketInstance } from "../Helpers/WebSocket";
import "react-loading-skeleton/dist/skeleton.css";
import { requestForToken } from "../Helpers/firebase";
import { Suspense } from "react";
import Homesidebar from "../components/Homesidebar";
import DragAndDrop from "../components/DragAndDrop/DragAndDrop";
import CommonFiles from "../Util/CommonFiles";
import ChatImageComponent from "../components/ImgComponent/ChatImageComponent";
import SEO from "../components/SEO";

const ChatTwoSidebarComponent = lazy(() => import("../components/ChatSidebarComponent/ChatTwoSidebarComponent"));

export default function ChatTwo() {

  const location = useLocation();
  const newSocket = getSocketInstance(process.env.REACT_APP_SOCKET_BASE_URL);
  const userDetail = useSelector(selectUserDetail);
  const dispatch = useDispatch();

  const messInput = React.useRef(null);
  const topRef = useRef(null)
  const bottomRef = React.useRef(null);
  const messagesContainer = React.useRef(null);
  const previousHeight = React.useRef(0);
  const isLoadingMore = React.useRef(false);
  const skipScroll = useRef(false);
  const mentionListRef = useRef(null);
  const [show, setShow] = useState(false);
  const [socket, setSocket] = useState(null);
  const [messagereplytext, setMessagereplytext] = useState(null);
  const [messagereplyid, setmessagereplyid] = useState(null);
  const [messageReplyImg, setMessageReplyImg] = useState([]);
  const [messageid, setmessageid] = useState(null);
  const [selecteduser, setSlecteduser] = useState("");
  const [roomid, setRoomid] = useState("");
  const [username, setUsername] = useState("");
  const [messageListData, setMessageList] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [useEmoji, setUseEmoji] = useState(false);
  const [arrFiles, setArrFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [members, setMembers] = useState([])
  const [handleMention, setHandleMention] = useState(false);
  const [sortedMembers, setSortedMembers] = useState([])
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
  const [load, setLoad] = useState(true)
  const [page, setPage] = useState(0)
  const [limit] = useState(50)
  const [totalCount, setTotalCount] = useState(0)

  const setselecteduser = useCallback((id) => {
    setSlecteduser(id);
  }, [selecteduser]);

  const onEmojiClick = (e) => {
    let sym = e.unified.split("-");
    let codesArray = sym.map((el) => "0x" + el);
    let emoji = String.fromCodePoint(...codesArray);
    setMessage((prevMessage) => prevMessage + emoji);
    messInput?.current.focus()
  };


  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    if (arrFiles?.length >= 5 && items[0].kind === 'file') return toast.error("Please paste less than 6")
    let selectedFiles = [];
    if (items.length > 5) {
      toast.error("Please paste less than 6");
    }
    else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const file = items[i].getAsFile();
          selectedFiles.push(file);
        }
      }
    }
    let data = arrFiles.concat(selectedFiles)
    setArrFiles(data)
  };

  function listenChatSockets() {
    if (newSocket.connected) {
      newSocket.on(
        selecteduser == userDetail?._id
          ? "message" + userDetail?._id + selecteduser
          : "message" + selecteduser + userDetail?._id,
        (data) => {
          socket.emit("readmessage", {
            room_id: data?.room_id,
            socket_client_id: socket?.id,
            receiver_id: data?.receiver_id,
          });

          if (data !== "") {
            // Check if data is not empty
            setIsUploading(false);
            setMessageList((prevMessageList) => {
              const updatedList = Array.isArray(prevMessageList)
                ? prevMessageList
                : [];

              if (
                updatedList?.length > 0 &&
                updatedList[0]?.room_id === data?.room_id
              ) {
                // If messages exist and belong to the same room, append the new message
                return [...updatedList, data];
              } else if (
                roomid?.toString() === data?.room_id?.toString() ||
                roomid === ""
              ) {
                // If the room ID matches the current room or if no room is selected, prepend the new message
                return [data, ...updatedList];
              } else if (data?.receiver_id === selecteduser) {
                // If the message is intended for the selected user, prepend it
                return [data, ...updatedList];
              } else {
                // Otherwise, return the existing message list without modification
                return updatedList;
              }
            });
            setRoomid(data?._id);
          }
          // Handle received messages here
        }
      );

      newSocket.on(userDetail?._id, (data) => {
        dispatch(GetRoomAction());
      });
    }
  }

  function listendeleteChatSockets() {
    if (newSocket.connected) {
      newSocket.on(selecteduser == userDetail?._id ? "delete" + userDetail?._id + selecteduser : "delete" + selecteduser + userDetail?._id,
        (data) => {
          setMessageList((prevMessageList) =>
            prevMessageList.filter((item) => item._id !== data._id)
          );
          // Handle received messages here
        }
      );
    }
  }

  function listeneditChatSockets() {
    if (newSocket.connected) {
      newSocket.on(
        selecteduser == userDetail?._id
          ? "editmessage" + userDetail?._id + selecteduser
          : "editmessage" + selecteduser + userDetail?._id,
        (data) => {
          if (data != "") {
            setMessageList((prevMessageList) => {
              const updatedList = Array.isArray(prevMessageList) ? [...prevMessageList] : [];
              const messageIndex = updatedList.findIndex(message => message._id.toString() === data._id.toString());
              if (messageIndex !== -1) {
                updatedList[messageIndex] = data;
              }
              return updatedList;
            });
          }
        }
      );
    }
  }

  function listenlikeChatSockets() {
    if (newSocket.connected) {
      newSocket.on(
        selecteduser == userDetail?._id
          ? "likemessage" + userDetail?._id + selecteduser
          : "likemessage" + selecteduser + userDetail?._id,
        (data) => {
          if (data != "") {
            skipScroll.current = true;
            setMessageList((prevMessageList) => {
              const updatedList = Array.isArray(prevMessageList) ? [...prevMessageList] : [];
              const messageIndex = updatedList.findIndex(message => message._id.toString() === data._id.toString());
              if (messageIndex !== -1) {
                updatedList[messageIndex] = data?.data;
              }
              return updatedList;
            });
          }
        }
      );
    }
  }

  const sendMessage = () => {
    if (socket && socket.connected) {
      if (!selecteduser) return
      if (arrFiles?.length > 0) {
        handleUpload();
      } else {
        if (message.trim().length > 0) {
          if (messageid != null && messageid != "") {
            socket.emit("message", {
              message,
              messageid,
              receiver_id: selecteduser,
              sender_id: userDetail?._id,
            });
            setmessageid("");
            setMessage("");
          } else {
            socket.emit("message", {
              message,
              messageid,
              messagereplyid,
              receiver_id: selecteduser,
              sender_id: userDetail?._id,
            });
            setMessagereplytext(null);
            setmessagereplyid(null);
            setMessageReplyImg([])
            setmessageid("");
            setMessage("");
          }
        } else {
          toast.error("Can't send empty message");
          setMessage("");
        }
      }
      setUseEmoji(false);
      dispatch(GetRoomAction());
      setFiles([]);
      setArrFiles([]);
    } else {
    }
  };

  const handleReplyEvents = (data) => {
    messInput?.current?.focus()
    setMessagereplytext(data?.message)
    setMessageReplyImg(data?.image)
    setmessagereplyid(data?._id)
  }

  const scrollToBottom = () => {
    bottomRef?.current?.scrollIntoView()
    // if (messagesContainer.current) {
    //   messagesContainer.current.scrollTop =
    //     messagesContainer.current.scrollHeight;
    // }
  };

  const scrollListIfNeeded = (index) => {
    if (mentionListRef.current) {
      const list = mentionListRef.current;
      const listItem = list.querySelector(`li:nth-child(${index + 1})`);

      if (listItem) {
        const listRect = list.getBoundingClientRect();
        const itemRect = listItem.getBoundingClientRect();

        // Scroll list to ensure selected item is in view
        if (itemRect.bottom > listRect.bottom) {
          list.scrollTop += itemRect.bottom - listRect.bottom;
        } else if (itemRect.top < listRect.top) {
          list.scrollTop -= listRect.top - itemRect.top;
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (handleMention && sortedMembers.length > 0) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedMemberIndex((prevIndex) => (prevIndex <= 0 ? sortedMembers.length - 1 : prevIndex - 1));
        scrollListIfNeeded(selectedMemberIndex - 1);
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedMemberIndex((prevIndex) => (prevIndex === sortedMembers.length - 1 ? 0 : prevIndex + 1));
        scrollListIfNeeded(selectedMemberIndex + 1);
      }
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (handleMention) {
        let element = sortedMembers.filter((res, index) => index === selectedMemberIndex)
        if (element?.length) {
          // setMessage(message.replace(/@\S*$/, `@${element[0]?.name} `));
          handleUserSelection(element[0]?.name)
          setHandleMention(false)
          return
        }
      }
      sendMessage();
    }
  };

  const handleMess = (val, event) => {
    const caretPosition = event?.target?.selectionStart;
    const beforeCaret = val?.substring(0, caretPosition);
    const afterCaret = val?.substring(caretPosition);

    // Check if the last character is "@"
    const lastElement = beforeCaret?.split(/\s+/).pop();
    const isAtSymbol = lastElement === '@' || lastElement === '\n@';

    // Define mention regex
    const mentionRegex = /@(\w+(?:\s+\w+)*)/g;

    // Default to showing all users if "@" is present
    if (isAtSymbol) {
      setHandleMention(true);
      setSortedMembers(members);
      setSelectedMemberIndex(0);
    } else {
      let beforeMatch, afterMatch;
      while ((beforeMatch = mentionRegex.exec(beforeCaret)) !== null) {
        if (mentionRegex.lastIndex >= caretPosition) break;
      }
      while ((afterMatch = mentionRegex.exec(afterCaret)) !== null) {
        if (mentionRegex.lastIndex >= afterCaret.length - caretPosition) break;
      }

      // Filter members if there's an active mention
      if (beforeMatch || afterMatch) {
        let mentionQuery = (beforeMatch ? beforeMatch[0] : afterMatch[0]).substring(1);
        let filteredMembers = members?.filter(member =>
          member?.name?.toLowerCase()?.startsWith(mentionQuery?.toLowerCase())
        );
        setSortedMembers(filteredMembers);
        setHandleMention(true);
        setSelectedMemberIndex(0);
      } else {
        setHandleMention(false);
        setSortedMembers([]);
        setSelectedMemberIndex(-1);
      }

      // Ensure popup only appears when "@" is present in the current word
      const currentWord = beforeCaret.split(/\s+/).pop();
      if (!currentWord.includes('@')) {
        setHandleMention(false);
        setSortedMembers([]);
        setSelectedMemberIndex(-1);
      }
    }

    setMessage(val);
  };

  // const handleUserSelection = (userName) => {
  //   const inputElement = messInput.current; // Assuming messInput is a ref to your input field
  //   const caretPosition = inputElement.selectionStart;
  //   const textAreaValue = inputElement.value;

  //   // Find the last "@" position
  //   const lastAtIndex = textAreaValue.lastIndexOf('@', caretPosition - 1);
  //   const lastSpaceIndex = textAreaValue.lastIndexOf(' ', lastAtIndex);
  //   const mentionStartIndex = lastSpaceIndex + 1;

  //   // Replace the mention with the selected user's name, including the "@"
  //   const updatedText = `${textAreaValue.substring(0, mentionStartIndex)}@${userName} ${textAreaValue.substring(caretPosition)}`;

  //   inputElement.value = updatedText;
  //   inputElement.selectionStart = inputElement.selectionEnd = mentionStartIndex + userName.length + 2; // Move cursor to end of inserted name

  //   // Update state if necessary
  //   setMessage(updatedText);
  //   setHandleMention(false);
  //   setSelectedMemberIndex(-1);
  //   inputElement.focus();
  // };

  const handleUserSelection = (userName) => {
    const inputElement = messInput.current;
  
    try {
      const caretPosition = inputElement.selectionStart;
      if (caretPosition === null || caretPosition === undefined) {
        console.error('Caret position could not be determined.');
        return;
      }
  
      const textAreaValue = inputElement.value;
  
      // Find the last "@" position before the caret
      const lastAtIndex = textAreaValue.lastIndexOf('@', caretPosition - 1);
      if (lastAtIndex === -1) {
        console.warn('No "@" found before the caret position.');
        return;
      }
  
      // Calculate the start index for the mention replacement (right after "@")
      const mentionStartIndex = lastAtIndex;
  
      // Replace the mention with the selected user's name, including the "@"
      const updatedText = `${textAreaValue.substring(0, mentionStartIndex)}@${userName} ${textAreaValue.substring(caretPosition)}`;
  
      // Set the updated value in the text area
      inputElement.value = updatedText;
  
      // Set the caret position after the inserted username
      inputElement.selectionStart = inputElement.selectionEnd = mentionStartIndex + userName.length + 2;
  
      // Update state if necessary
      setMessage(updatedText);
      setHandleMention(false);
      setSelectedMemberIndex(-1);
  
      // Refocus the input element
      inputElement.focus();
  
      console.log('User mention handled successfully.');
    } catch (error) {
      console.error('Error handling user mention:', error);
    }
  };
  
  const handleEmojies = async (e) => {
    setUseEmoji(!useEmoji);
  };

  const handleFileChange = async (e) => {
    if (arrFiles?.length >= 5) return toast.error("Please select less than 6")
    setUseEmoji(false);
    var file = e.target.files;
    if (file.length > 5) {
      toast.error("Please select less than 6");
    } else {
      var arr_files = [];
      for (var i = 0; i < file.length; i++) {
        arr_files.push(file[i]);
      }
      const data = arrFiles.concat(arr_files)
      setArrFiles(data)
      messInput?.current?.focus()
    }
  };

  const uploadFile = async (filename) => {
    try {
      let formData = new FormData();
      formData.append('file', filename);
      const res = await dispatch(uploadFileAction(formData));
      let url = res.payload.data.url;
      return url;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  const handleUpload = async () => {
    var images = [];
    setMessage("");
    var arr_localFiles = arrFiles;
    setArrFiles([]);
    if (arr_localFiles.length > 0) {
      for (var i = 0; i < arr_localFiles.length; i++) {
        var filename = arr_localFiles[i];
        var size = arr_localFiles[i].size;

        setIsUploading(true);

        scrollToBottom();

        var filetype = checkfiletype(filename);
        var image = await uploadFile(filename)
        images.push({
          imagename: image,
          filetype,
          size,
        });
      }

      if (images.length <= 5) {
        if (socket && socket.connected) {
          if (image && filetype) {
            socket.emit("message", {
              messagetype: 1,
              message: message.trim().length > 0 ? message : null,
              image: images,
              receiver_id: selecteduser,
              sender_id: userDetail?._id,
            });
          }
        } else {
        }
      } else {
        toast.error("Maximum 5 files can be selected");
      }
    }
  }

  const deletemessage = (chatid, createdAtTimestamp) => {
    const createdAt = new Date(createdAtTimestamp);
    const currentTimestamp = new Date();
    const differenceInMs = currentTimestamp - createdAt;
    if (differenceInMs <= 60 * 5 * 1000) {
      socket.emit("deletemessage", {
        chatid: chatid,
        receiver_id: selecteduser,
        sender_id: userDetail?._id,
      });
    } else {
      toast.error(
        "Message can only be deleted within five minute of creation."
      );
    }
  };

  const likemessage = (chatid) => {
    socket.emit("likemessage", {
      message_id: chatid,
      receiver_id: selecteduser,
      sender_id: userDetail?._id,
    });
  };

  const handleDragEvents = (e, type) => {
    e.preventDefault();
    setDragging(type);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = [...e.dataTransfer.files, ...files];
    if (droppedFiles?.length > 5) return toast.error("Maximum 5 files can be selected")
    setFiles(droppedFiles);
    setArrFiles(droppedFiles);
  };

  const getMessages = (id, pagination, adscroll = false) => {
    try {
      if (selecteduser != "") {
        dispatch(GetmessageAction({ id, pagination, limit })).then(function (arraylist) {
          let newMessages = arraylist?.payload?.data?.data ? [...arraylist.payload.data.data].reverse() : [];
          if (adscroll) {
            let x = [...newMessages, ...messageListData]
            setMessageList((prevMessages) => [...newMessages, ...prevMessages,]);
          } else {
            setMessageList(newMessages);
          }
          setTotalCount(arraylist?.payload?.data?.count)
          let serchInput = document.getElementById('serachUser')
          if (serchInput && document.activeElement === serchInput) {
            return
          } else {
            messInput?.current?.focus();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoad(false)
    }
  }

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0 && totalCount > 20 && totalCount > messageListData.length) {
      previousHeight.current = e.target.scrollHeight;
      isLoadingMore.current = true;
      const pagination = page + 1;
      setPage(pagination);
      getMessages(selecteduser, pagination, true);
    }
  };

  useEffect(() => {
    if (!messagesContainer.current) return;
    if (isLoadingMore.current) {
      messagesContainer.current.scrollTop =
        messagesContainer.current.scrollHeight - previousHeight.current;
      isLoadingMore.current = false;
    } else if (!skipScroll.current) {
      scrollToBottom();
    }
    skipScroll.current = false;
  }, [messageListData.length, isUploading]);

  useEffect(() => {
    setPage(0);
    getMessages(selecteduser, 0);
  }, [selecteduser]);

  useEffect(() => {
    if (newSocket.connected) {
      listenChatSockets();
      listendeleteChatSockets();
      listenlikeChatSockets();
      listeneditChatSockets();
    }

    setSocket(newSocket);

    return () => {
      newSocket.removeAllListeners();
    };
  }, [username]);

  useEffect(() => {
    const device_token = localStorage.getItem("devicetoken");
    if (device_token) {
      dispatch(usertokenupdateAction({ device_token }));
    } else {
      const isUserLoggedIn = localStorage.getItem("token");
      if (isUserLoggedIn) {
        requestForToken().then((data) => {
          dispatch(
            usertokenupdateAction({
              _id: userDetail?._id,
              device_token: data,
            })
          );
        });
      }
    }

    dispatch(searchUserlist()).then((res) => {
      const data = res.payload.data;
      setMembers(data);
      setSortedMembers(data);
    });
  }, []);

  return (
    <>
      <SEO
        title="Messaging and Team Collaboration - Typetawk"
        description='Keep the team connected, organized, and productive with secure, real-time chat features.'
      />
      <Container fluid className="px-0">
        <div className="collab-main">
          <div className={show ? "left-side hide" : "left-side "}>
            <aside className="typetawk-cmn-sidebar">
              <div className="sidebar-left-header-cmn">
                <img className="big-logo" src={whitelogo} alt="Logo" />
                <img className="small-logo" src={smalllogo} alt="Logo" />
              </div>
              <div className="side-flex">
                {/* typetawk mini-sidebar start */}
                <Suspense fallback={<div>Loading... </div>}>
                  <Homesidebar userDetail={userDetail} location={location} />
                </Suspense>
                <Suspense fallback={<div>Loading... </div>}>
                  <ChatTwoSidebarComponent
                    selecteduser={selecteduser}
                    setselecteduser={setselecteduser}
                    setUsername={setUsername}
                    userDetail={userDetail}
                    setRoomid={setRoomid}
                    setMessage={setMessage}
                    setIsUploading={setIsUploading}
                    setFiles={setFiles}
                    setArrFiles={setArrFiles}
                  />
                </Suspense>
                {/* typetawk full-sidebar end */}
                <div className="">
                  <button className="menu-close" onClick={() => setShow(!show)}>
                    <img
                      src={require("../Assets/images/menuclose.svg").default}
                    />
                  </button>
                </div>
              </div>
            </aside>
          </div>
          <div className={show ? "right-side hide" : "right-side "}>
            {/* <DragAndDrop /> */}
            {/* typetawk right header start */}
            <header>
              <Container fluid>
                <Row>
                  <Col lg="5" md="3" sm="3" xs="6" className="px-0">
                    <div className="chat-header-left mt-2">
                      <h3 className="sub-16">{username}</h3>
                      {/* <p className="h-12">{username}</p> */}
                    </div>
                  </Col>
                  <Col
                    lg="7"
                    md="9"
                    sm="9"
                    xs="6"
                    className="px-0 d-flex justify-content-end"
                  >
                    <div className="chat-header-right">
                      <Form className="search-form position-relative big ">
                        <Form.Group>
                          <Form.Control type="search" placeholder="Search" />
                          <img src={searchicon} alt="" className="search-ico" />
                        </Form.Group>
                      </Form>

                      <div className="header-dots-select">
                        {/* <img src={media} alt="" className="search-ico" /> */}
                        <svg
                          width="4"
                          height="16"
                          viewBox="0 0 4 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <ellipse
                            cx="1.71403"
                            cy="1.70528"
                            rx="1.71403"
                            ry="1.70528"
                            fill="white"
                          />
                          <ellipse
                            cx="1.71403"
                            cy="7.99989"
                            rx="1.71403"
                            ry="1.70528"
                            fill="white"
                          />
                          <ellipse
                            cx="1.71403"
                            cy="14.2944"
                            rx="1.71403"
                            ry="1.70528"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </header>
            {!selecteduser ? null : (
              <>
                <div
                  className={`draganddrop-rel chat-box-main ${dragging ? "active" : ""
                    }`}
                  onDragOver={(e) => handleDragEvents(e, true)}
                  onDragEnter={(e) => handleDragEvents(e, true)}
                  onDragLeave={(e) => handleDragEvents(e, false)}
                  onDrop={handleDrop}
                  onScroll={handleScroll}
                  ref={messagesContainer}
                >
                  <DragAndDrop />
                  {load ?
                    <>
                      <div>
                        <p className="text-primary">Loading...</p>
                      </div>
                    </>
                    :
                    <>
                      {(messageListData?.length < totalCount) ? <div ref={topRef} className="text-primary">Loading...</div> : null}
                      {messageListData?.map(function (messageobject, i) {
                        if (
                          messageobject?.sender_id?.toString() ===
                          userDetail?._id?.toString()
                        ) {
                          return (
                            <>
                              <div className="right-user mt-4 mb-2" key={messageobject._id} id={messageobject._id}>
                                <div className="right-user-info">
                                  <div className="right-user-img">
                                    <h4 className="h-12">
                                      {timezone(messageobject?.createdAt)}
                                      <span className="tym-tool-tip">
                                        {moment(messageobject?.createdAt).format(
                                          "DD MMM YYYY, hh:mm a"
                                        )}
                                      </span>
                                    </h4>
                                    <div className="like-msg">
                                      <button
                                        className="w-42"
                                        onClick={() => {
                                          likemessage(
                                            messageobject?._id,
                                            messageobject?.likelist
                                          );
                                        }}
                                      >
                                        {messageobject?.like == null ? (
                                          <svg
                                            width="16"
                                            height="14"
                                            viewBox="0 0 16 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0 4.36809C0.0529216 4.06134 0.0820285 3.74938 0.162734 3.45042C0.583461 1.87375 1.52282 0.720812 3.13031 0.222983C4.84629 -0.308641 6.34265 0.120298 7.58102 1.40321C7.72655 1.55399 7.85621 1.72037 8.00174 1.88935C8.14199 1.72687 8.27164 1.56309 8.41586 1.41101C9.09854 0.687017 9.91486 0.185288 10.923 0.0592064C12.4802 -0.135766 13.8112 0.330868 14.8366 1.5085C15.8606 2.68613 16.1596 4.07693 15.9241 5.58732C15.7746 6.54398 15.3287 7.37846 14.7466 8.14535C13.9819 9.15271 13.0518 10.0028 12.0926 10.8243C10.9614 11.7913 9.82357 12.7519 8.68708 13.7125C8.25048 14.0816 7.7808 14.0998 7.36007 13.7411C5.8743 12.4763 4.3872 11.2168 2.92127 9.9326C2.17772 9.28139 1.51091 8.55479 0.964496 7.72941C0.44851 6.94952 0.10452 6.11114 0.0224917 5.17658C0.0198456 5.14148 0.00793824 5.10769 0 5.07259C0 4.83862 0 4.60336 0 4.36809ZM7.99513 13.0691C8.03614 13.0379 8.06922 13.0145 8.09965 12.9885C9.36448 11.9148 10.6359 10.849 11.8902 9.76362C12.766 9.00453 13.5982 8.20124 14.2478 7.23678C14.8657 6.31781 15.1713 5.31826 15.0284 4.21211C14.8749 3.01888 14.355 2.02713 13.2754 1.40321C12.2699 0.822198 11.2088 0.750708 10.1398 1.25504C9.50869 1.55269 9.04165 2.03882 8.65136 2.60034C8.59579 2.68093 8.54551 2.76412 8.4873 2.84341C8.22931 3.18916 7.77154 3.18786 7.50957 2.84601C7.28201 2.54835 7.07297 2.2312 6.81365 1.96213C5.97749 1.08996 4.9442 0.753307 3.75082 1.00677C2.4635 1.27973 1.61808 2.08042 1.19074 3.28665C0.693273 4.68785 0.930097 6.00456 1.7517 7.23418C2.26107 7.99587 2.88555 8.66398 3.57088 9.26969C4.70473 10.2718 5.86107 11.2493 7.01079 12.2359C7.33493 12.5153 7.66437 12.7883 7.99513 13.0691Z"
                                              fill="#5948C8"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            width="17"
                                            height="15"
                                            viewBox="0 0 17 15"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M9.08049 13.6273C13.6616 9.65944 16.3114 7.50406 16.3956 4.47928C16.4618 2.08365 14.7003 0.928295 14.6616 0.89581C11.8542 -1.10437 9.14066 1.04258 8.50302 1.75703C7.93197 1.04393 5.42623 -1.09172 2.49159 0.787778C2.06422 1.05969 0.652447 2.10866 0.418651 4.09999C0.0209175 7.55059 3.47482 10.3772 6.95151 13.6235C7.24435 13.8994 7.62317 14.0306 8.00729 14.0327C8.3834 14.0348 8.76514 13.8998 9.08049 13.6273Z"
                                              fill="#5948C8"
                                            />
                                          </svg>
                                        )}
                                        <p className="h-10">
                                          {messageobject?.likelist?.length}
                                        </p>
                                      </button>
                                      <Dropdown className="cmn-dropdown">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                        >
                                          <img
                                            src={
                                              require("../Assets/images/voiletdots.svg")
                                                .default
                                            }
                                          />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {/* {messageobject.messagetype == "0" && ( */}
                                          <Dropdown.Item
                                            onClick={() => {
                                              handleReplyEvents(messageobject)
                                              // setMessagereplytext(
                                              //   messageobject?.message
                                              // );
                                              // setMessageReplyImg(messageobject?.image)
                                              // setmessagereplyid(messageobject?._id);
                                            }}
                                          >
                                            <li>
                                              {" "}
                                              <img
                                                src={reply}
                                                alt="reply"
                                                className="img-fluid"
                                              />
                                              <p className="h-12">Reply</p>
                                            </li>
                                          </Dropdown.Item>
                                          {/* )} */}

                                          {messageobject?.messagetype == "0" && (
                                            <Dropdown.Item>
                                              <li
                                                onClick={() => {
                                                  setMessage(messageobject?.message);
                                                  setmessageid(messageobject?._id);
                                                  // setmessagedatetime(
                                                  //   messageobject?.createdAt
                                                  // );
                                                  messInput?.current?.focus()
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src={edit}
                                                  alt="edit"
                                                  className="img-fluid"
                                                />
                                                <p className="h-12">Edit</p>
                                              </li>
                                            </Dropdown.Item>
                                          )}
                                          {messageobject?.messagetype == "0" && (
                                            <Dropdown.Item
                                              onClick={() =>
                                                CommonFiles.handleCopyAction(messageobject?.message)
                                              }
                                              href="#/action-3"
                                            >
                                              <li>
                                                {" "}
                                                <img
                                                  src={copy}
                                                  alt="copy"
                                                  className="img-fluid"
                                                />
                                                <p className="h-12">Copy</p>
                                              </li>
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            onClick={() => {
                                              deletemessage(
                                                messageobject?._id,
                                                messageobject?.createdAt
                                              );
                                            }}
                                          >
                                            <li>
                                              {" "}
                                              <img
                                                src={deleteimg}
                                                alt="copy"
                                                className="img-fluid"
                                              />
                                              <p className="h-12">Delete</p>
                                            </li>
                                          </Dropdown.Item>
                                          {(messageobject?.image?.length > 1) && <Dropdown.Item onClick={() => CommonFiles.handleDownAll(messageobject?.image)}>
                                            <li>
                                              {" "}
                                              <img
                                                src={downloadImg}
                                                alt="copy"
                                                className="img-fluid"
                                              />
                                              <p className="h-12">
                                                Download All
                                              </p>
                                            </li>
                                          </Dropdown.Item>}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  {/* Right Message boxes configuration start */}
                                  {
                                    //type - text && reply is there
                                    messageobject?.messagetype == "0" &&
                                      messageobject?.message_id != undefined ? (
                                      //reply box
                                      <div className="reply-msg-box">
                                        <button type="button">
                                          <img
                                            src={
                                              require("../Assets/images/reply.svg")
                                                .default
                                            }
                                          />
                                          Replied
                                        </button>
                                        {/* previous message */}
                                        <div className="right-user-msg-box reply-msg previous-msg-rply" onClick={() => CommonFiles.handleScrollToReplyMess(messageobject?.message_id?._id)}>
                                          {messageobject?.message_id?.image.length ?
                                            <>
                                              {messageobject?.message_id?.image.map((res, index) =>
                                                (res.filetype.toString() === '7' || res.filetype.toString() === '5' || res.filetype.toString() === '2') ?
                                                  <>
                                                    <div className="d-flex ms-2 align-items-center">
                                                      <img
                                                        className="img-fluid pdf-set"
                                                        src={require("../Assets/images/other-file.svg").default
                                                        }
                                                        alt="File"
                                                      />
                                                      <div className="pdf-info ">
                                                        <h3 className="h-13">
                                                          {CommonFiles.getFileNameFromUrl(res?.imagename)}
                                                        </h3>
                                                        {/* <p className="h-12 pt-1">{res?.size == undefined ? "N/A" : (res?.size / 1024 / 1024).toFixed(2)}- MB</p> */}
                                                      </div>
                                                    </div>
                                                  </>
                                                  :
                                                  <ChatImageComponent imageUrl={res.imagename} preview />
                                              )}
                                            </>
                                            :
                                            <p
                                              className="h-14"
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {messageobject?.message_id?.message
                                                ?.split(/(\s+|\n)/)
                                                .map((word, index) => {
                                                  const isUrl = /(https?:\/\/[^\s]+)/.test(
                                                    word
                                                  );

                                                  return isUrl ? (
                                                    <a
                                                      className="white-url"
                                                      key={index}
                                                      href={word}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {word}
                                                    </a>
                                                  ) : word === "\n" ? (
                                                    <br key={index} />
                                                  ) : (
                                                    <span key={index}>{word} </span>
                                                  );
                                                })}
                                            </p>}
                                        </div>

                                        <div className="right-reply-line">
                                          <img
                                            src={
                                              require("../Assets/images/line_reply.svg")
                                                .default
                                            }
                                          />
                                        </div>

                                        {/* current message */}

                                        <div className="right-user-msg-box crruent-msg-text">
                                          <div className="position-relative">
                                            <p
                                              className="h-14"
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {messageobject?.message
                                                ?.split(/(\s+|\n)/)
                                                .map((word, index) => {
                                                  const isUrl = /(https?:\/\/[^\s]+)/.test(
                                                    word
                                                  );

                                                  return isUrl ? (
                                                    <a
                                                      className="white-url"
                                                      key={index}
                                                      href={word}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {word}
                                                    </a>
                                                  ) : word === "\n" ? (
                                                    <br key={index} />
                                                  ) : (
                                                    <span key={index}>{word} </span>
                                                  );
                                                })}
                                            </p>
                                            {(moment(messageobject.createdAt).valueOf() !== moment(messageobject.updatedAt).valueOf()) ? <div className={`edit-icon-right`}>
                                              <i class="bi bi-pencil-fill"></i>
                                            </div> : null}
                                          </div>
                                        </div>
                                      </div>
                                    ) : //type - text only
                                      messageobject.messagetype == "0" ? (
                                        <div className="right-user-msg-box">
                                          <div className="position-relative">
                                            <p
                                              className="h-14"
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {messageobject?.message
                                                .split(/(\s+|\n)/)
                                                .map((word, index) => {
                                                  const isUrl = /(https?:\/\/[^\s]+)/.test(
                                                    word
                                                  );

                                                  return isUrl ? (
                                                    <a
                                                      className="white-url"
                                                      key={index}
                                                      href={word}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {word}
                                                    </a>
                                                  ) : word === "\n" ? (
                                                    <br key={index} />
                                                  ) : (
                                                    <span key={index}>{word} </span>
                                                  );
                                                })}
                                              {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edied)'} */}
                                            </p>
                                            {(moment(messageobject.createdAt).valueOf() !== moment(messageobject.updatedAt).valueOf()) ? <div className={`edit-icon-right`}>
                                              <i class="bi bi-pencil-fill"></i>
                                            </div> : null}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="with-image-box">
                                          <div className="left-user-msg-box-images">
                                            {messageobject.image.map(function (
                                              imageobject,
                                              i
                                            ) {
                                              return (
                                                <button key={i}>
                                                  {imageobject.filetype === 1 &&
                                                    CommonFiles.getFileExtension(imageobject?.imagename) == "svg" ? (
                                                    <div className="left-user-msg-box-pdf">
                                                      <div className="pdf-div">
                                                        <div className="d-flex">
                                                          <img
                                                            className="img-fluid pdf-set"
                                                            src={
                                                              require("../Assets/images/other-file.svg")
                                                                .default
                                                            }
                                                            alt="File"
                                                          />
                                                          <div className="pdf-info">
                                                            <h3 className="h-13">
                                                              {CommonFiles.getFileNameFromUrl(
                                                                imageobject.imagename
                                                              )}
                                                            </h3>
                                                            <p className="h-12 pt-1">
                                                              {imageobject?.size ==
                                                                undefined
                                                                ? "N/A"
                                                                : (
                                                                  imageobject?.size /
                                                                  1024 /
                                                                  1024
                                                                ).toFixed(2)}
                                                              - MB
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div
                                                          onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                        >
                                                          <img
                                                            src={
                                                              require("../Assets/images/download-voilet.svg")
                                                                .default
                                                            }
                                                            alt="Download"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : imageobject.filetype === 1 ? (
                                                    // <a
                                                    //   className="img-dwn-hover"
                                                    //   href={imageobject?.imagename}
                                                    //   download={CommonFiles.getFileNameFromUrl(imageobject?.imagename)}
                                                    //   target="_blank"
                                                    // >
                                                    //   <img
                                                    //     className="send-img send-img-size"
                                                    //     src={imageobject?.imagename}
                                                    //     alt="Image"
                                                    //     onError={CommonFiles.chatImageError}
                                                    //   />
                                                    // </a>
                                                    <ChatImageComponent index={i} imageUrl={imageobject?.imagename} imgArray={messageobject?.image} />
                                                  ) : imageobject.messagetype === "3" ? (
                                                    <audio controls>
                                                      <source
                                                        src={imageobject?.imagename}
                                                        type="audio/mp3"
                                                      />
                                                      Your browser does not support the
                                                      audio element.
                                                    </audio>
                                                  ) : (
                                                    <div className="left-user-msg-box-pdf">
                                                      <div className="pdf-div">
                                                        <div className="d-flex">
                                                          <img
                                                            className="img-fluid pdf-set"
                                                            src={
                                                              imageobject.filetype === "5"
                                                                ? require("../Assets/images/pdf-red.svg")
                                                                  .default
                                                                : imageobject.filetype ===
                                                                  "6"
                                                                  ? require("../Assets/images/zip.svg")
                                                                    .default
                                                                  : require("../Assets/images/other-file.svg")
                                                                    .default
                                                            }
                                                            alt="File"
                                                          />
                                                          <div className="pdf-info">
                                                            <h3 className="h-13">
                                                              {CommonFiles.getFileNameFromUrl(
                                                                imageobject.imagename
                                                              )}
                                                            </h3>
                                                            <p className="h-12 pt-1">
                                                              {imageobject?.size ==
                                                                undefined
                                                                ? "N/A"
                                                                : (
                                                                  imageobject?.size /
                                                                  1024 /
                                                                  1024
                                                                ).toFixed(2)}
                                                              - MB
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div
                                                          onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                        >
                                                          <img
                                                            src={
                                                              require("../Assets/images/download-voilet.svg")
                                                                .default
                                                            }
                                                            alt="Download"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </button>
                                              );
                                            })}
                                          </div>

                                          {/* if message is present along with image */}
                                          {messageobject?.message?.length > 0 && (
                                            <div className="right-user-msg-box">
                                              {
                                                <p
                                                  className="h-14"
                                                  style={{ whiteSpace: "pre-line" }}
                                                >
                                                  {messageobject?.message
                                                    ?.split(/(\s+|\n)/)
                                                    .map((word, index) => {
                                                      const isUrl =
                                                        /(https?:\/\/[^\s]+)/.test(word);

                                                      return isUrl ? (
                                                        <a
                                                          className="white-url"
                                                          key={index}
                                                          href={word}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          {word}
                                                        </a>
                                                      ) : word === "\n" ? (
                                                        <br key={index} />
                                                      ) : (
                                                        <span key={index}>{word} </span>
                                                      );
                                                    })}
                                                  {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                                </p>
                                              }
                                            </div>
                                          )}
                                        </div>
                                      )
                                  }
                                </div>
                              </div>
                            </>
                          );
                        } else if (
                          messageobject?.receiver_id === userDetail?._id?.toString()
                        ) {
                          return (
                            <div className="left-user mt-4 mb-2" key={messageobject._id} id={messageobject._id}>
                              <div className="left-user-info">
                                <div className="left-user-img">
                                  {messageobject?.sender_image ? (
                                    // <img
                                    //   className="user-ic-img"
                                    //   src={messageobject?.sender_image}
                                    //   onError={CommonFiles.onImageError}
                                    // />
                                    <ChatImageComponent imageUrl={messageobject?.sender_image} type="user" />
                                  ) : (
                                    <InitialsAvatar
                                      name={
                                        messageobject?.sender_name || "Typetawk User"
                                      }
                                    />
                                  )}
                                  <h2 className="h-15">{messageobject?.sender_name}</h2>
                                  <h4 className="h-12 ">
                                    {timezone(messageobject?.createdAt)}
                                    <span className="tym-tool-tip">
                                      {moment(messageobject?.createdAt).format(
                                        "DD MMM YYYY, hh:mm a"
                                      )}
                                    </span>
                                  </h4>
                                  <div className="like-msg">
                                    <button
                                      className="w-42"
                                      onClick={() => {
                                        likemessage(messageobject?._id);
                                      }}
                                    >
                                      {messageobject?.like == null ? (
                                        <svg
                                          width="16"
                                          height="14"
                                          viewBox="0 0 16 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0 4.36809C0.0529216 4.06134 0.0820285 3.74938 0.162734 3.45042C0.583461 1.87375 1.52282 0.720812 3.13031 0.222983C4.84629 -0.308641 6.34265 0.120298 7.58102 1.40321C7.72655 1.55399 7.85621 1.72037 8.00174 1.88935C8.14199 1.72687 8.27164 1.56309 8.41586 1.41101C9.09854 0.687017 9.91486 0.185288 10.923 0.0592064C12.4802 -0.135766 13.8112 0.330868 14.8366 1.5085C15.8606 2.68613 16.1596 4.07693 15.9241 5.58732C15.7746 6.54398 15.3287 7.37846 14.7466 8.14535C13.9819 9.15271 13.0518 10.0028 12.0926 10.8243C10.9614 11.7913 9.82357 12.7519 8.68708 13.7125C8.25048 14.0816 7.7808 14.0998 7.36007 13.7411C5.8743 12.4763 4.3872 11.2168 2.92127 9.9326C2.17772 9.28139 1.51091 8.55479 0.964496 7.72941C0.44851 6.94952 0.10452 6.11114 0.0224917 5.17658C0.0198456 5.14148 0.00793824 5.10769 0 5.07259C0 4.83862 0 4.60336 0 4.36809ZM7.99513 13.0691C8.03614 13.0379 8.06922 13.0145 8.09965 12.9885C9.36448 11.9148 10.6359 10.849 11.8902 9.76362C12.766 9.00453 13.5982 8.20124 14.2478 7.23678C14.8657 6.31781 15.1713 5.31826 15.0284 4.21211C14.8749 3.01888 14.355 2.02713 13.2754 1.40321C12.2699 0.822198 11.2088 0.750708 10.1398 1.25504C9.50869 1.55269 9.04165 2.03882 8.65136 2.60034C8.59579 2.68093 8.54551 2.76412 8.4873 2.84341C8.22931 3.18916 7.77154 3.18786 7.50957 2.84601C7.28201 2.54835 7.07297 2.2312 6.81365 1.96213C5.97749 1.08996 4.9442 0.753307 3.75082 1.00677C2.4635 1.27973 1.61808 2.08042 1.19074 3.28665C0.693273 4.68785 0.930097 6.00456 1.7517 7.23418C2.26107 7.99587 2.88555 8.66398 3.57088 9.26969C4.70473 10.2718 5.86107 11.2493 7.01079 12.2359C7.33493 12.5153 7.66437 12.7883 7.99513 13.0691Z"
                                            fill="#5948C8"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="17"
                                          height="15"
                                          viewBox="0 0 17 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.08049 13.6273C13.6616 9.65944 16.3114 7.50406 16.3956 4.47928C16.4618 2.08365 14.7003 0.928295 14.6616 0.89581C11.8542 -1.10437 9.14066 1.04258 8.50302 1.75703C7.93197 1.04393 5.42623 -1.09172 2.49159 0.787778C2.06422 1.05969 0.652447 2.10866 0.418651 4.09999C0.0209175 7.55059 3.47482 10.3772 6.95151 13.6235C7.24435 13.8994 7.62317 14.0306 8.00729 14.0327C8.3834 14.0348 8.76514 13.8998 9.08049 13.6273Z"
                                            fill="#5948C8"
                                          />
                                        </svg>
                                      )}

                                      <p className="h-10">
                                        {messageobject?.likelist?.length}
                                      </p>
                                    </button>
                                    <Dropdown className="cmn-dropdown">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <img
                                          src={
                                            require("../Assets/images/voiletdots.svg")
                                              .default
                                          }
                                        />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {/* {messageobject.messagetype == "0" && ( */}
                                        <Dropdown.Item
                                          onClick={() => {
                                            handleReplyEvents(messageobject)
                                            // setMessagereplytext(
                                            //   messageobject?.message
                                            // );
                                            // setMessageReplyImg(messageobject?.image)
                                            // setmessagereplyid(messageobject?._id);
                                          }}
                                        >
                                          <li>
                                            {" "}
                                            <img
                                              src={reply}
                                              alt="reply"
                                              className="img-fluid"
                                            />
                                            <p className="h-12">Reply</p>
                                          </li>
                                        </Dropdown.Item>
                                        {/* )} */}

                                        {/* <Dropdown.Item href="#/action-2">
                                    <li>
                                      {" "}
                                      <img
                                        src={forward}
                                        alt="forward"
                                        className="img-fluid"
                                      />
                                      <p className="h-12">Forward</p>
                                    </li>
                                  </Dropdown.Item> */}
                                        {messageobject.messagetype == "0" && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              CommonFiles.handleCopyAction(messageobject?.message)
                                            }
                                            href="#/action-3"
                                          >
                                            <li>
                                              {" "}
                                              <img
                                                src={copy}
                                                alt="copy"
                                                className="img-fluid"
                                              />
                                              <p className="h-12">Copy</p>
                                            </li>
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                {
                                  //type - text && reply is there
                                  messageobject.messagetype == "0" &&
                                    messageobject?.message_id != undefined ? (
                                    //reply box
                                    <div className="reply-msg-box left-reply-box">
                                      <button type="button">
                                        <img
                                          src={
                                            require("../Assets/images/reply.svg")
                                              .default
                                          }
                                        />
                                        Replied
                                      </button>
                                      {/* previous message */}
                                      <div className="left-user-msg-box reply-msg previous-msg-rply" onClick={() => CommonFiles.handleScrollToReplyMess(messageobject?.message_id?._id)}>
                                        {messageobject?.message_id?.image.length ?
                                          <>
                                            {messageobject?.message_id?.image.map((res) =>
                                              (res.filetype.toString() === '7' || res.filetype.toString() === '5' || res.filetype.toString() === '2') ?
                                                <>
                                                  <div className="d-flex ms-2 align-items-center">
                                                    <img
                                                      className="img-fluid pdf-set"
                                                      src={require("../Assets/images/other-file.svg").default
                                                      }
                                                      alt="File"
                                                    />
                                                    <div className="pdf-info ">
                                                      <h3 className="h-13">
                                                        {CommonFiles.getFileNameFromUrl(res?.imagename)}
                                                      </h3>
                                                      {/* <p className="h-12 pt-1">{res?.size == undefined ? "N/A" : (res?.size / 1024 / 1024).toFixed(2)}- MB</p> */}
                                                    </div>
                                                  </div>
                                                </>
                                                :
                                                <ChatImageComponent imageUrl={res.imagename} preview />
                                            )}
                                          </>
                                          :
                                          <p
                                            className="h-14"
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {messageobject?.message_id?.message
                                              ?.split(/(\s+|\n)/)
                                              .map((word, index) => {
                                                const isUrl = /(https?:\/\/[^\s]+)/.test(
                                                  word
                                                );

                                                return isUrl ? (
                                                  <a
                                                    className="white-url"
                                                    key={index}
                                                    href={word}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {word}
                                                  </a>
                                                ) : word === "\n" ? (
                                                  <br key={index} />
                                                ) : (
                                                  <span key={index}>{word} </span>
                                                );
                                              })}
                                            {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                          </p>
                                        }
                                      </div>
                                      <div className="left-reply-line">
                                        <img
                                          src={
                                            require("../Assets/images/line_reply_left.svg")
                                              .default
                                          }
                                        />
                                      </div>

                                      {/* current message */}
                                      <div className="left-user-msg-box crruent-msg-text">
                                        <div className="position-relative">
                                          <p
                                            className="h-14"
                                            style={{ whiteSpace: "pre-line" }}
                                          >
                                            {messageobject?.message
                                              ?.split(/(\s+|\n)/)
                                              .map((word, index) => {
                                                const isUrl = /(https?:\/\/[^\s]+)/.test(
                                                  word
                                                );

                                                return isUrl ? (
                                                  <a
                                                    className="white-url"
                                                    key={index}
                                                    href={word}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    {word}
                                                  </a>
                                                ) : word === "\n" ? (
                                                  <br key={index} />
                                                ) : (
                                                  <span key={index}>{word} </span>
                                                );
                                              })}
                                            {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                          </p>
                                          {(moment(messageobject.createdAt).valueOf() !== moment(messageobject.updatedAt).valueOf()) ? <div className={`edit-icon-left`}>
                                            <i class="bi bi-pencil-fill"></i>
                                          </div> : null}
                                        </div>
                                      </div>
                                    </div>
                                  ) : //type - text
                                    messageobject.messagetype == "0" ? (
                                      <div className="left-user-msg-box">
                                        {messageobject?.message_id == undefined ? (
                                          <div className="position-relative">
                                            <p
                                              className="h-14"
                                              style={{ whiteSpace: "pre-line" }}
                                            >
                                              {messageobject?.message
                                                ?.split(/(\s+|\n)/)
                                                .map((word, index) => {
                                                  const isUrl = /(https?:\/\/[^\s]+)/.test(
                                                    word
                                                  );

                                                  return isUrl ? (
                                                    <a
                                                      className="white-url"
                                                      key={index}
                                                      href={word}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {word}
                                                    </a>
                                                  ) : word === "\n" ? (
                                                    <br key={index} />
                                                  ) : (
                                                    <span key={index}>{word} </span>
                                                  );
                                                })}
                                              {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                            </p>
                                            {(moment(messageobject.createdAt).valueOf() !== moment(messageobject.updatedAt).valueOf()) ? <div className={`edit-icon-left`}>
                                              <i class="bi bi-pencil-fill"></i>
                                            </div> : null}
                                          </div>
                                        ) : (
                                          <>
                                            <div className="msg-reply-main">
                                              <button type="button">
                                                <img
                                                  src={
                                                    require("../Assets/images/reply.svg")
                                                      .default
                                                  }
                                                />
                                                Replied
                                              </button>
                                              <div className="right-user-msg-box reply-msg">
                                                <p
                                                  className="h-14"
                                                  style={{ whiteSpace: "pre-line" }}
                                                >
                                                  {messageobject?.message
                                                    ?.split(/(\s+|\n)/)
                                                    .map((word, index) => {
                                                      const isUrl =
                                                        /(https?:\/\/[^\s]+)/.test(word);

                                                      return isUrl ? (
                                                        <a
                                                          className="white-url"
                                                          key={index}
                                                          href={word}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          {word}
                                                        </a>
                                                      ) : word === "\n" ? (
                                                        <br key={index} />
                                                      ) : (
                                                        <span key={index}>{word} </span>
                                                      );
                                                    })}
                                                  {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="right-user-msg-box reply-ms-bottom mt-2">
                                              <p
                                                className="h-14"
                                                style={{ whiteSpace: "pre-line" }}
                                              >
                                                {messageobject?.message
                                                  ?.split(/(\s+|\n)/)
                                                  .map((word, index) => {
                                                    const isUrl =
                                                      /(https?:\/\/[^\s]+)/.test(word);

                                                    return isUrl ? (
                                                      <a
                                                        className="white-url"
                                                        key={index}
                                                        href={word}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {word}
                                                      </a>
                                                    ) : word === "\n" ? (
                                                      <br key={index} />
                                                    ) : (
                                                      <span key={index}>{word} </span>
                                                    );
                                                  })}
                                                {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                              </p>
                                            </div>{" "}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="left-user-msg-box-images">
                                          {messageobject.image.map(function (
                                            imageobject,
                                            i
                                          ) {
                                            return (
                                              <button key={i}>
                                                {imageobject.filetype === 1 &&
                                                  CommonFiles.getFileExtension(
                                                    imageobject?.imagename
                                                  ) == "svg" ? (
                                                  <div className="left-user-msg-box-pdf">
                                                    <div className="pdf-div">
                                                      <div className="d-flex">
                                                        <img
                                                          className="img-fluid pdf-set"
                                                          src={
                                                            require("../Assets/images/other-file.svg")
                                                              .default
                                                          }
                                                          alt="File"
                                                        />
                                                        <div className="pdf-info">
                                                          <h3 className="h-13">
                                                            {CommonFiles.getFileNameFromUrl(
                                                              imageobject.imagename
                                                            )}
                                                          </h3>
                                                          <p className="h-12 pt-1">
                                                            {imageobject?.size ==
                                                              undefined
                                                              ? "N/A"
                                                              : (
                                                                imageobject?.size /
                                                                1024 /
                                                                1024
                                                              ).toFixed(2)}
                                                            - MB
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                      >
                                                        <img
                                                          src={
                                                            require("../Assets/images/download-voilet.svg")
                                                              .default
                                                          }
                                                          alt="Download"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : imageobject.filetype === 1 ? (
                                                  // <a
                                                  //   className="img-dwn-hover"
                                                  //   href={imageobject?.imagename}
                                                  //   download={CommonFiles.getFileNameFromUrl(
                                                  //     imageobject?.imagename
                                                  //   )}
                                                  //   target="_blank"
                                                  // >
                                                  //   <img
                                                  //     className="send-img send-img-size"
                                                  //     src={imageobject?.imagename}
                                                  //     onError={CommonFiles.chatImageError}
                                                  //     alt="Image"
                                                  //   />
                                                  // </a>
                                                  <ChatImageComponent index={i} imageUrl={imageobject?.imagename} imgArray={messageobject?.image} />
                                                ) : imageobject.messagetype === "3" ? (
                                                  <audio controls>
                                                    <source
                                                      src={imageobject?.imagename}
                                                      type="audio/mp3"
                                                    />
                                                    Your browser does not support the
                                                    audio element.
                                                  </audio>
                                                ) : (
                                                  <div className="left-user-msg-box-pdf">
                                                    <div className="pdf-div">
                                                      <div className="d-flex">
                                                        <img
                                                          className="img-fluid pdf-set"
                                                          src={
                                                            imageobject.filetype === "5"
                                                              ? require("../Assets/images/pdf-red.svg")
                                                                .default
                                                              : imageobject.filetype ===
                                                                "6"
                                                                ? require("../Assets/images/zip.svg")
                                                                  .default
                                                                : require("../Assets/images/other-file.svg")
                                                                  .default
                                                          }
                                                          alt="File"
                                                        />
                                                        <div className="pdf-info">
                                                          <h3 className="h-13">
                                                            {CommonFiles.getFileNameFromUrl(
                                                              imageobject.imagename
                                                            )}
                                                          </h3>
                                                          <p className="h-12 pt-1">
                                                            {" "}
                                                            {imageobject?.size ==
                                                              undefined
                                                              ? "N/A"
                                                              : (
                                                                imageobject?.size /
                                                                1024 /
                                                                1024
                                                              ).toFixed(2)}{" "}
                                                            - MB
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div
                                                        onClick={() => CommonFiles.downloadFile(imageobject?.imagename)}
                                                      >
                                                        <img
                                                          src={
                                                            require("../Assets/images/download-voilet.svg")
                                                              .default
                                                          }
                                                          alt="Download"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </button>
                                            );
                                          })}
                                        </div>

                                        {/* if message is present along with image */}
                                        {messageobject?.message?.length > 0 && (
                                          <div className="left-user-msg-box">
                                            {
                                              <p
                                                className="h-14"
                                                style={{ whiteSpace: "pre-line" }}
                                              >
                                                {messageobject?.message
                                                  ?.split(/(\s+|\n)/)
                                                  .map((word, index) => {
                                                    const isUrl =
                                                      /(https?:\/\/[^\s]+)/.test(word);

                                                    return isUrl ? (
                                                      <a
                                                        className="white-url"
                                                        key={index}
                                                        href={word}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        {word}
                                                      </a>
                                                    ) : word === "\n" ? (
                                                      <br key={index} />
                                                    ) : (
                                                      <span key={index}>{word} </span>
                                                    );
                                                  })}
                                                {/* {(moment(messageobject.createdAt).valueOf() === moment(messageobject.updatedAt).valueOf()) ? '' : '(edited)'} */}
                                              </p>
                                            }
                                          </div>
                                        )}
                                      </div>
                                    )
                                }
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <div ref={bottomRef}></div>
                    </>
                  }

                  {isUploading && (
                    <div className="right-user mt-4 mb-2">
                      <div className="right-user-info">
                        <div className="left-user-msg-box">
                          {/* Replace the following div with an <img> tag for your loading GIF */}
                          <div className="loader">
                            <img
                              className="send-img send-img-size"
                              src={require("../Assets/images/loader.gif")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="defiult-text-notif">
                <p>
                  Please select a user or Tawk to start communicating with other
                  members. Feel free to reach out and connect!
                  <span className="mt-3">
                    <svg
                      width="51"
                      height="45"
                      viewBox="0 0 51 45"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.6025 1.05701L0.282496 41.447C0.0969841 41.7703 -0.000425494 42.1367 1.39709e-06 42.5095C0.000428288 42.8822 0.0986764 43.2484 0.284928 43.5713C0.47118 43.8942 0.738913 44.1625 1.06137 44.3496C1.38383 44.5366 1.74973 44.6357 2.1225 44.637H48.7625C49.1353 44.6357 49.5012 44.5366 49.8236 44.3496C50.1461 44.1625 50.4138 43.8942 50.6001 43.5713C50.7863 43.2484 50.8846 42.8822 50.885 42.5095C50.8854 42.1367 50.788 41.7703 50.6025 41.447L27.2825 1.05701C27.0949 0.735378 26.8263 0.468526 26.5035 0.283062C26.1806 0.0975975 25.8148 0 25.4425 0C25.0702 0 24.7043 0.0975975 24.3815 0.283062C24.0586 0.468526 23.7901 0.735378 23.6025 1.05701Z"
                        fill="#5948C8"
                      />
                      <path
                        d="M22.4426 35.5467C22.4426 34.9534 22.6185 34.3734 22.9481 33.88C23.2778 33.3867 23.7463 33.0021 24.2945 32.7751C24.8427 32.548 25.4459 32.4886 26.0278 32.6044C26.6098 32.7201 27.1443 33.0058 27.5639 33.4254C27.9834 33.845 28.2692 34.3795 28.3849 34.9614C28.5007 35.5434 28.4413 36.1466 28.2142 36.6948C27.9871 37.2429 27.6026 37.7115 27.1093 38.0411C26.6159 38.3708 26.0359 38.5467 25.4426 38.5467C25.0441 38.5637 24.6464 38.4977 24.2747 38.353C23.9031 38.2082 23.5655 37.9878 23.2835 37.7058C23.0014 37.4238 22.7811 37.0862 22.6363 36.7146C22.4915 36.3429 22.4256 35.9452 22.4426 35.5467ZM23.5326 30.8867L22.7726 15.8867H28.0326L27.3026 30.8867H23.5326Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </p>
              </div> */}
                </div>
              </>
            )}
            {!selecteduser ? null : (
              <>
                {/* multiple files div start */}
                {arrFiles?.length > 0 && (
                  <div className="message-reply-bar">
                    <div className="message-reply-inner row">
                      {arrFiles?.map(function (file, iDx) {
                        return (
                          <div className="d-flex media-view-send col-md-6">
                            <div className="pdf-div">
                              <div className="d-flex">
                                <img
                                  className="img-fluid pdf-set"
                                  src={
                                    checkfiletype(file) == 5
                                      ? require("../Assets/images/pdf-red.svg")
                                        .default
                                      : checkfiletype(file) == 6
                                        ? require("../Assets/images/zip.svg")
                                          .default
                                        : require("../Assets/images/other-file.svg")
                                          .default
                                  }
                                />
                                <div className="pdf-info">
                                  <h3 className="h-13">{file.name}</h3>
                                  <p className="h-12 pt-1">
                                    {(file.size / 1024 / 1024).toFixed(2)} MB
                                  </p>
                                </div>
                              </div>
                              <button
                                onClick={() => {
                                  arrFiles.splice(iDx, 1);

                                  setArrFiles(arrFiles);
                                }}
                              >
                                <img
                                  src={
                                    require("../Assets/images/cross.svg").default
                                  }
                                />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* multiple files div end */}

                <div className="message-send-bar-main a-popup-rel">
                  <div className="message-send-bar-inner ">
                    <div className="d-flex align-items-center mb-2">
                      <div className="doc-attach">
                        <img
                          src={require("../Assets/images/attach.svg").default}
                        />
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleFileChange}
                          multiple
                        />
                      </div>
                      <button className="emoji-send" onClick={handleEmojies}>
                        <img
                          src={require("../Assets/images/emoji.svg").default}
                        />
                      </button>
                    </div>
                    <div className="emoji-div-up">
                      {!useEmoji ? (
                        <div></div>
                      ) : (
                        <EmojiPicker onEmojiClick={onEmojiClick} />
                      )}
                    </div>

                    {/* reply div */}
                    {(messagereplytext != null || messageReplyImg.length) && messagereplyid != null ? (
                      <div className="message-reply-bar chat-reply-box">
                        <div className="message-reply-inner">
                          <div className="d-flex">
                            <img className="reply-img" src={reply} />
                            {messageReplyImg.length ?
                              <>
                                {messageReplyImg.map((res, index) =>
                                  (res.filetype.toString() === '7' || res.filetype.toString() === '5' || res.filetype.toString() === '2') ?
                                    <>
                                      <div className="d-flex ms-2 align-items-center">
                                        <img
                                          className="img-fluid pdf-set"
                                          src={require("../Assets/images/other-file.svg").default
                                          }
                                          alt="File"
                                        />
                                        <div className="pdf-info ">
                                          <h3 className="h-13">
                                            {CommonFiles.getFileNameFromUrl(res?.imagename)}
                                          </h3>
                                          {/* <p className="h-12 pt-1">{res?.size == undefined ? "N/A" : (res?.size / 1024 / 1024).toFixed(2)}- MB</p> */}
                                        </div>
                                      </div>
                                    </>
                                    :
                                    <ChatImageComponent index={index} imageUrl={res.imagename} preview />
                                )}
                              </>
                              :
                              <p
                                className="h-14"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {messagereplytext
                                  ?.split(/(\s+|\n)/)
                                  .map((word, index) => {
                                    const isUrl = /(https?:\/\/[^\s]+)/.test(word);

                                    return isUrl ? (
                                      <a
                                        className=""
                                        key={index}
                                        href={word}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {word}
                                      </a>
                                    ) : word === "\n" ? (
                                      <br key={index} />
                                    ) : (
                                      <span key={index}>{word} </span>
                                    );
                                  })}
                              </p>}
                          </div>
                          <button>
                            <img
                              src={require("../Assets/images/cross.svg").default}
                              onClick={() => {
                                setMessagereplytext(null);
                                setmessagereplyid(null);
                                setMessageReplyImg([])
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {/*End reply div */}

                    {handleMention && (
                      <div
                        ref={mentionListRef}
                        className="mention-list bg-white"
                        style={{
                          height: `${Math.min(sortedMembers.length * 30, 210)}px`
                        }}
                      >
                        <ul>
                          {sortedMembers.map((res, index) => (
                            <li
                              key={res._id}
                              style={{ 'list-style-type': 'none' }}
                              className={index === selectedMemberIndex ? 'selected-user' : ''}
                              onClick={() => {
                                // setMessage(message.replace(/@\S*$/, `@${res.name} `));
                                handleUserSelection(res.name)
                                setHandleMention(false);
                                setSelectedMemberIndex(-1);
                                messInput?.current?.focus()
                              }}
                            >
                              {res.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="form-group">
                      <TextareaAutosize
                        //rowsMax={5}
                        maxRows="5"
                        // maxLength={2000}
                        ref={messInput}
                        className="form-control message-field"
                        placeholder="Type a message"
                        value={message}
                        onChange={(e) => {
                          handleMess(e.target.value, e.nativeEvent)
                        }}
                        onPaste={handlePaste}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <button className="message-send" onClick={sendMessage}>
                      <img
                        src={require("../Assets/images/sendmsg.svg").default}
                      />
                      <p> Send</p>
                    </button>
                  </div>
                </div>
              </>
            )}
            {/* typetawk message bar end*/}
          </div>
        </div>
      </Container>
    </>
  );
} 
