import React from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import searchicon from "../../Assets/images/searchicon.svg";
import media from "../../Assets/images/media.svg";
import setting from "../../Assets/images/setting.svg";
import unpin from "../../Assets/images/unpin.svg";

const Header = ({ isActive }) => {
  return (
    <>
      {/* typetawk right header start */}
      <header>
        <Container fluid>
          <Row>
            <Col lg="5" md="3" sm="3" xs="3" className="px-0">
              <div className="chat-header-left">
                <h3 className="sub-16">Yellodesk</h3>
                <p className="h-12">www.yellodesk.com</p>
              </div>
            </Col>
            <Col
              lg="7"
              md="9"
              sm="9"
              xs="9"
              className="px-0 d-flex justify-content-end"
            >
              <div className="chat-header-right">
                <Form className="search-form position-relative big">
                  <Form.Group>
                    <Form.Control type="search" placeholder="Search" />
                    <img src={searchicon} alt="" className="search-ico" />
                  </Form.Group>
                </Form>
                <div className="collab-select">
                  <img
                    src={require("../../Assets/images/userico.svg").default}
                  />
                  <select>
                    <option>5</option>
                    <option>4</option>
                    <option>3</option>
                  </select>
                  <img
                    src={require("../../Assets/images/downarrow.svg").default}
                  />

                  <div className="users-dropdown">
                    <Form className="search-form position-relative">
                      <Form.Group>
                        <Form.Control type="search" placeholder="Search" />
                        <img src={searchicon} alt="" className="search-ico" />
                      </Form.Group>
                    </Form>
                    <div className="add-member">
                      <button className="h-12">Add Member</button>
                      <span className="h-13">to this group</span>
                    </div>
                    <ul className="member-all">
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                      <li>
                        <img
                          src={require("../../Assets/images/ms.svg").default}
                        />
                        <p className="h-13">Manpreet Singh</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="header-dots-select">
                  <Dropdown className="cmn-dropdown">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <svg
                        width="4"
                        height="16"
                        viewBox="0 0 4 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="1.71403"
                          cy="1.70528"
                          rx="1.71403"
                          ry="1.70528"
                          fill="white"
                        />
                        <ellipse
                          cx="1.71403"
                          cy="7.99989"
                          rx="1.71403"
                          ry="1.70528"
                          fill="white"
                        />
                        <ellipse
                          cx="1.71403"
                          cy="14.2944"
                          rx="1.71403"
                          ry="1.70528"
                          fill="white"
                        />
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        <li>
                          {" "}
                          <img
                            src={setting}
                            alt="setting"
                            className="img-fluid"
                          />
                          <p className="h-12">Group Setting</p>
                        </li>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        <li>
                          {" "}
                          <img src={unpin} alt="unpin" className="img-fluid" />
                          <p className="h-12">Unpin from favorites</p>
                        </li>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        <li>
                          {" "}
                          <img src={media} alt="media" className="img-fluid" />
                          <p className="h-12">Media</p>
                        </li>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {/* typetawk right header end */}
    </>
  );
};

export default Header;
