import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import MainLayout from "../components/Layout/MainLayout";

export default function ChangePassword() {
  return (
    <>
      <MainLayout>
        <Container fluid className="px-0">
          {/* typetawk chat box start */}
          <div className="form-box-group-main">
            <Container>
              <Form className="group-form">
                <Row>
                  <Col
                    lg="3"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Current Password</Form.Label>
                  </Col>
                  <Col lg="9">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Enter Current Password"
                        name="CurrentPassword"
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="3"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>New Password</Form.Label>
                  </Col>
                  <Col lg="9">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Enter New Password"
                        name="New Password"
                      />
                    </Form.Group>
                  </Col>
                  <Col
                    lg="3"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                  </Col>
                  <Col lg="9">
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        name="password"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Container>
          </div>
          {/* typetawk chat box end */}

          {/* typetawk message bar start*/}

          <div className="group-bottom-btns">
            <div className="group-btns-inner">
              <button className="create-group">Change Password</button>
            </div>
          </div>
          {/* typetawk message bar end*/}
        </Container>
      </MainLayout>
    </>
  );
}
