import React from "react";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import AccountHeader from "./AccountHeader";
import { searchUserlist } from "../../redux/actions/authAction";
import { useDispatch } from "react-redux";
import NewgroupHeader from "../NewgroupHeader/NewgroupHeader";

export default function MainLayout({ children }) {

  const dispatch = useDispatch();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  const renderHeader = () => {
    if (location.pathname === "/account" || location.pathname === "/notification" || location.pathname === "/member-invite" ||
      location.pathname === "/tawk-settings" ||
      location.pathname === "/typetawk-settings") {
      return <AccountHeader type={location.pathname} />;
    } else if (location.pathname === "/new-tawk") {
      return <NewgroupHeader type={location.pathname} />;
    } else {
      return <Header isActive={isActive} />;
    }
  };

  useEffect(() => {
    const concernedElement = document.querySelector(".menu-click");
    const handleClick = (event) => {
      if (concernedElement && concernedElement.contains(event?.target)) {
        setIsActive((prev) => !prev);
        setIsActive2((prev) => !prev);
      } else {
        setIsActive(false);
        setIsActive2(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    dispatch(searchUserlist());
  }, [])

  return (
    <>
      <Container fluid className="px-0">
        <div className="collab-main">
          <div className={show ? "left-side hide" : "left-side "}>
            <Sidebar
              isActive={isActive}
              setIsActive={setIsActive}
              show={show}
              setShow={setShow}
            />
          </div>
          <div className={show ? "right-side hide" : "right-side "}>
            {/* typetawk right header start */}
            {renderHeader()}
            {children}
          </div>
        </div>
      </Container>
    </>
  );
}
