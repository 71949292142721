import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    authorization: localStorage.getItem("token"),
  },
});

API.interceptors.response.use(
  (response) => {
  if(response.data.status==401){
    localStorage.clear();
    window.location.href="/";
  }
    return response;
  },
  (error) => {
    return error.response;
  }
);

export default API;
