import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function AccountHeader({
    type
}) {
  return (

    <header>
    <Container fluid>
      <Row>
        <Col lg="12" className="px-0">
          <div className="chat-header-left">
            <h3 className="sub-16">{type.replace("/","",1).toUpperCase()}</h3>
          </div>
        </Col>
      </Row>
    </Container>
  </header>

  )
}
