import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Chat from "./pages/Chat";
import NewGroup from "./pages/NewGroup";
import Account from "./pages/Account";
import NotificationPage from "./pages/Notification";
import TypetawkGroup from "./pages/TypetawkGroup";
import TypetawkSettingMembers from "./pages/TypetawkSettingMembers";
import Login from "./pages/Authentication/Login";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import PrivateRoutes from "./HOC/PrivateRoutes";
import ChangePassword from "./pages/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile, usertokenupdateAction } from "./redux/actions/authAction";
import { toast } from "react-hot-toast";
import ChatTwo from "./pages/ChatTwo";
import Settingmembers from "./pages/Settingmembers";
import Updateprofile from "./pages/Updateprofile";
import Home from "./pages/Home";
import MediaView from "./pages/MediaView";
import { onMessage } from "firebase/messaging";
import { messaging, requestForToken } from "../src/Helpers/firebase";
import EditGroup from "./pages/EditGroup";
import sound from './sample-12s.mp3'
import { HelmetProvider } from 'react-helmet-async';

// import "react-notifications/lib/notifications.css";
import { selectUserDetail } from "./redux/reducers/authReducer";

function App() {
  const dispatch = useDispatch();
  const currentRoomId = useSelector((state) => state.auth.currentRoomId)
  const userDetail = useSelector(selectUserDetail);
  const [userData, setUserData] = useState(userDetail)
  // const userDetail = localStorage.getItem('userDetail') ? JSON.parse(localStorage.getItem('userDetail')) : null;


  onMessage(messaging, (payload) => {
    const audio = new Audio(sound);
    setTimeout(function () {
      audio.play();
      setTimeout(function () {
        audio.pause();
        audio.currentTime = 0;
      }, 2000);
    }, 1000);




    if (currentRoomId != payload?.data?.roomID) {
      toast(payload?.data?.title,
        {
          icon: '📩',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
    }



  });

  useEffect(() => {
    const isUserLoggedIn = localStorage.getItem("token");
    if (isUserLoggedIn) {
      dispatch(getMyProfile()).then((data) => {
        setUserData(data?.payload?.userdetails)
        data?.payload?.userdetails?.completeprofile &&
          localStorage.setItem(
            "completeprofile",
            data?.payload?.userdetails?.completeprofile
          );
      });
    }
  }, []);


  const isUserLoggedIn = localStorage.getItem("token");
  const completeprofile = localStorage.getItem("completeprofile");

  // useEffect(() => {
  //   const isUserLoggedIn = localStorage.getItem("token");
  //   if (isUserLoggedIn) {
  //     requestForToken().then(function (data) {
  //       dispatch(usertokenupdateAction({ device_token: data }))
  //     });
  //   }
  // }, []);

  // useEffect(() => {
  //   navigator.serviceWorker.addEventListener('message', (event) => {
  //     if (event.data.type === 'notificationClicked') {
  //     }
  //   });
  // }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            {!isUserLoggedIn ? (
              <>
                <Route path="/login" element={<Login />} />

                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<Home />} />
                <Route path="/passwordReset/:token" element={<ResetPassword />} />
              </>
            ) : completeprofile === "1" ? (
              <Route path="*" element={<Navigate to="/messages" replace />} />
            ) : (
              <Route
                path="*"
                element={<Navigate to="/complete-profile" replace />}
              />
            )}
            <Route path="/media-view" element={<MediaView />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/complete-profile" element={<Updateprofile />} />

              {
                (userDetail?.role == 2 || userDetail?.role == 3) && (

                  <>
                    <Route path="/tawk-settings" element={<TypetawkGroup />} />
                    <Route
                      path="/typetawk-settings"
                      element={<TypetawkSettingMembers />}
                    />
                  </>
                )
              }
              <Route path="/tawks" element={<Chat />} />
              <Route path="/new-tawk" element={<NewGroup />} />
              <Route path="/edit-tawk/:id" element={<EditGroup />} />
              <Route path="/account" element={<Account />} />

              <Route path="/notification" element={<NotificationPage />} />


              <Route path="/member-invite" element={<Settingmembers />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/messages" element={<ChatTwo />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
