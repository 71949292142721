import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { toast } from "react-hot-toast";
import RoleConfig from '../../Helpers/roles';
import { useDispatch } from 'react-redux';
import { logoutuserAction } from '../../redux/actions/authAction';



export default function SettingSidebar({ userDetail }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logoutuserAction()).then(function () {
      localStorage.clear();
      window.location.href = '/';
      toast.success('You are logged out!');
    })
  };

  return (
    <div className="full-sidebar-main account">
      <div className="Account-user-name">
        <h3 className="h-18">
          Hi,{" "}
          <span style={{ color: "#5948C8" }}>{userDetail?.name}!</span>
        </h3>
      </div>

      <div className="user-click-tab">
        <h4 className="h-12 fav-heading pt-2">Setting</h4>

        <div className="side-lists">
          <div className="collab-names">
            <Link
              to="/account"
              className={`group-name-click menu-click ${location.pathname === '/account' ? 'active' : ''}`}
            >
              <span>
                <img src={require("../../Assets/images/uservoilet.svg").default} />
                <h2 className="cmn-hdng-14">Account</h2>
              </span>
            </Link>
          </div>
          <div className="collab-names">
            <Link to="/notification" className={`group-name-click menu-click ${location.pathname === '/notification' ? 'active' : ''}`}
            >
              <span>
                <img src={require("../../Assets/images/notification.svg").default} />
                <h2 className="cmn-hdng-14">Notification</h2>
              </span>
            </Link>
          </div>
          <hr className="light-border" />
        </div>

        {(userDetail.role == RoleConfig.admin || userDetail.role == RoleConfig.manager) && (
          /* Manage Organization start */
          <>
            <h4 className="h-12 fav-heading pt-2">
              Manage Organization
            </h4>
            <div className="side-lists">
              <div className="collab-names">
                <Link to="/tawk-settings" className={`group-name-click menu-click ${location.pathname === '/tawk-settings' ? 'active' : ''}`}>
                  <span>
                    <img
                      src={
                        require("../../Assets/images/Groups.svg").default
                      }
                    />
                    <h2 className="cmn-hdng-14">Tawks</h2>
                  </span>
                </Link>
              </div>
              <div className="collab-names">
                <Link
                  to="/typetawk-settings"
                  className={`group-name-click menu-click ${location.pathname === '/typetawk-settings' ? 'active' : ''}`}
                >
                  <span>
                    <img
                      src={
                        require("../../Assets/images/Members.svg").default
                      }
                    />
                    <h2 className="cmn-hdng-14">Members</h2>
                  </span>
                </Link>
              </div>
              <hr className="light-border" />
            </div>
          </>
          /* Manage Organization end */
        )}
        <h4 className="h-12 fav-heading pt-2">Support</h4>
        <div className="side-lists">
          <div className="collab-names">
            <Link to="#" className="group-name-click menu-click">
              <span>
                <img
                  src={require("../../Assets/images/help.svg").default}
                />
                <h2 className="cmn-hdng-14">Help</h2>
              </span>
            </Link>
          </div>
          <div className="collab-names">
            <Link to="#" className="group-name-click">
              <span>
                <img
                  src={
                    require("../../Assets/images/Community.svg")
                      .default
                  }
                />
                <h2 className="cmn-hdng-14">Community</h2>
              </span>
            </Link>
          </div>
          <div className="collab-names">
            <Link to="#" className="group-name-click">
              <span>
                <img
                  src={
                    require("../../Assets/images/Feedback.svg").default
                  }
                />
                <h2 className="cmn-hdng-14">Feedback</h2>
              </span>
            </Link>
          </div>
          <div className="collab-names">
            <Link to="#" className="group-name-click">
              <span>
                <img
                  src={
                    require("../../Assets/images/Contactus.svg")
                      .default
                  }
                />
                <h2 className="cmn-hdng-14">Contact Us</h2>
              </span>
            </Link>
          </div>

          {/* Logout Menu */}
          <div className="collab-names">
            <div className="group-name-click" style={{ cursor: "pointer" }} onClick={handleLogout}>
              <span>
                <img
                  src={require("../../Assets/images/logout.svg").default}
                  alt="Logout"
                />
                <h2 className="cmn-hdng-14">Logout</h2>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
