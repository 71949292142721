import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Collbrylogo from "../Assets/images/logo-main.webp";
import OwlCarousel from "react-owl-carousel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SEO from "../components/SEO";
export default function Home() {
  const options = {
    loop: true,
    center: true,
    items: 1,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,

    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const navigate = useNavigate();
  return (
    <div>
      <SEO
        title="Team Communication Software - Typetawk"
        description="Boost Team engagement with Typetawk's powerful live chat and messaging platform."
      />
      <Navbar expand="lg" className="home_header">
        <Container>
          <Navbar.Brand href="#">
            {" "}
            <img className="white-logo" src={Collbrylogo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav "
          >
            <Nav className="home-head ">
              <button
                type="button"
                className="sub-16 "
                onClick={() => {
                  navigate("/login");
                }}
              >
                Log In
              </button>

              <button type="button" className="sub-16 contact ms-4">
                Contact Us
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="home-cmn-sec">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h2 className="h-65">Chat, Conquer, Collaborate</h2>
              <h4 className="sub-26 w-75">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </h4>
              <button
                type="button"
                className="cmn-inner-btn"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Log In
              </button>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                className="img-fluid"
                src={require("../Assets/images/banner_img.webp")}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="home-cmn-sec">
        <Container>
          <Row>
            <Col lg={12} className="d-flex justify-content-center text-center">
              <h2 className="h-55">
                A messaging tool for all <br />communication
              </h2>
            </Col>

            <Col lg={12} className="d-flex justify-content-center">
              <img
                className="img-fluid width-set"
                src={require("../Assets/images/all_in_one.webp")}
              />
            </Col>
            <Col lg={12} className="d-flex justify-content-center text-center">
              <h4 className="sub-26 w-75">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="purple-cmn-sec text-center margin-set">
        <Container>
          <div className="purple-sec-inner">
            <h2>Features</h2>
          </div>
          <div className="colllab-tabs">
            <Tabs
              defaultActiveKey="home"
              id="fill-tab-example"
              className="mb-3"
              fill
            >
              <Tab eventKey="home" title="Ongoing Conversations">
                <div className="tabs-sec">
                  <img
                    className="img-fluid"
                    src={require("../Assets/images/slide_img_one.svg").default}
                  />
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took .
                  </p>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Trackable Decision">
                <div className="tabs-sec">
                  <img
                    className="img-fluid"
                    src={require("../Assets/images/slide_img_two.svg").default}
                  />
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took .
                  </p>
                </div>
              </Tab>
              <Tab eventKey="longer-tab" title="Engaged Teamwork">
                <div className="tabs-sec">
                  <img
                    className="img-fluid"
                    src={
                      require("../Assets/images/slide_img_three.svg").default
                    }
                  />
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took .
                  </p>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>{" "}
      </div>
      <div className="home-cmn-sec">
        <Container>
          <Row>
            <Col lg={12} className="d-flex justify-content-center text-center">
              <h2 className="h-55">
                Visualize workflows seamlessly with
                <br /> Typetawk
              </h2>
            </Col>
            <Row className="cmn-space align-items-center">
              <Col lg={5}>
                <h2 className="h-55">Lorem Ipsum is simply dummy </h2>
                <h4 className="sub-26">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </h4>
              </Col>
              <Col lg={7}>
                <img
                  className="img-fluid chat-img"
                  src={require("../Assets/images/collabryimage.svg").default}
                />
              </Col>
            </Row>
          </Row>
        </Container>
      </div>

      <div className="testimonial_sec text-center">
        <Container>
          <h2 className="h-35">Customer Voices</h2>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            items={1}
            nav={false}
            dots={true}
            {...options}
          >
            <div className="item">
              <div className="client-testimonial">
                <h4>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </h4>
                <h3>John Smith </h3>
                <p>Web Designer</p>
              </div>
            </div>
            <div className="item">
              <div className="client-testimonial">
                <h4>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </h4>
                <h3>John Smith </h3>
                <p>Web Designer</p>
              </div>
            </div>
            <div className="item">
              <div className="client-testimonial">
                <h4>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </h4>
                <h3>John Smith </h3>
                <p>Web Designer</p>
              </div>
            </div>
            <div className="item">
              <div className="client-testimonial">
                <h4>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </h4>
                <h3>John Smith </h3>
                <p>Web Designer</p>
              </div>
            </div>
          </OwlCarousel>
        </Container>
      </div>

      <div className="purple-cmn-sec text-center">
        <Container>
          <div className="purple-sec-inner">
            <h2>A better way to work, together</h2>
            <p>
              Simple and intuitive project management software for moving real
              work forward
            </p>
            <button type="button ">Contact Us</button>
          </div>
        </Container>{" "}
      </div>

      <footer className="home_foot">
        <Container>
          <Row>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="foot_menu">
                <img src={require("../Assets/images/collabry.svg").default} />
              </div>
              <div className="social-media">
                <Link to="#">
                  <img src={require("../Assets/images/fb.svg").default} />{" "}
                </Link>
                <Link to="#">
                  <img src={require("../Assets/images/insta.svg").default} />{" "}
                </Link>
                <Link to="#">
                  <img src={require("../Assets/images/link.svg").default} />{" "}
                </Link>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="foot-links">
                <h2>Affiliates</h2>
                <ul>
                  <li>
                    <Link to="#">Codobux</Link>
                  </li>
                  <li>
                    <Link to="#">Foxbegin</Link>
                  </li>
                  <li>
                    <Link to="#">Imghippo</Link>
                  </li>
                  <li>
                    <Link to="#">Insiger</Link>
                  </li>
                  <li>
                    <Link to="#">Glamutsav</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="foot-links">
                <h2>About</h2>
                <ul>
                  <li>
                    <Link to="#">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="#">Blogs</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <div className="foot-links">
                <h2>Sign up to Our Newsletter</h2>
                <div className="form-input">
                  {" "}
                  <Form>
                    <Form.Group>
                      <Form.Control type="email" placeholder="Email" />
                    </Form.Group>
                    <button type="button">Send</button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <div className="foot-bar d-flex align-items-center">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="foot-bar-left">
                <h4>A joint creation by </h4>
                <Link to="#">
                  <img src={require("../Assets/images/codobux.svg").default} />
                </Link>{" "}
                /
                <Link to="#" className="ms-1">
                  <img src={require("../Assets/images/fox.svg").default} />
                </Link>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="foot-bar-right">
                <p>© 2024 Typetawk.com All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
